import React from 'react';
import WelcomeCard from '@/components/welcomeCard/index';
import useGetUserInfo from '@/hooks/useGetUserInfo';
import NoAuth from '@/components/noAuth/noAuth';

type Props = {};

const InternalService: React.FC<Props> = () => {
  const { nameEn } = useGetUserInfo();

  return <div>{nameEn ? <WelcomeCard type="INTERNAL" /> : <NoAuth />}</div>;
};

export default InternalService;
