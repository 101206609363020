import React from 'react';
import classNames from 'classnames';
import classnames from 'classnames/bind';
import styles from './recommendation.less';
import { Divider, Skeleton } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { ComponentWrapper } from '@digit-fe/digit-component';
import { ProductInformationItem } from '@/pages/productInformation/table';
import dayjs from 'dayjs';
import { addClickNum } from '@/utils/requests/requests_home';
import { useRequest } from 'umi';

const cx = classnames.bind(styles);

type TRProps = {
  item?: ProductInformationItem;
  labelCls?: string;
};

const Recommendation: React.FC<TRProps> = ({ item, labelCls }) => {
  const addClickNumRequest = useRequest(
    () => {
      return addClickNum({ id: item?.id });
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  return (
    <a
      href={item ? item.url : 'javascript:void(0);'}
      target={'_blank'}
      onClick={() => addClickNumRequest.run()}
    >
      <ComponentWrapper>
        <div
          className={classNames(
            styles.recommendation_item,
            styles.layout_base_flex,
            styles.mgb25,
          )}
        >
          {item?.pic ? (
            <img src={item?.pic} />
          ) : (
            <img src={item?.contentType?.pic} />
          )}
          {item ? (
            <div
              className={classNames(
                styles.right_content,
                styles.layout_vertical,
                styles.layout_center_fill,
              )}
            >
              <div
                className={classNames(
                  styles.color_333,
                  styles.f18,
                  styles.lh24,
                  styles.fw600,
                  styles.mgb17,
                )}
                style={{
                  width: '560px',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
              >
                {item?.title}
              </div>
              <div
                className={classNames(
                  styles.layout_vertical_center,
                  styles.f14,
                )}
                style={{ display: 'flex' }}
              >
                <div className={cx(styles.tag_wrapper, labelCls)}>
                  {item?.contentType?.name}
                </div>
                <Divider type={'vertical'} style={{ margin: '0 20px' }} />
                <span>
                  <ClockCircleOutlined
                    style={{
                      fontSize: 14,
                      color: '#8097ad',
                      marginRight: 8,
                    }}
                  />
                  {dayjs(item.lastModifiedTime).format('YYYY-MM-DD HH:mm:ss')}{' '}
                  发布
                </span>
                {/* <Divider type={'vertical'} style={{ margin: '0 20px' }} />
                <span>
                  <EyeOutlined
                    style={{
                      fontSize: 16,
                      color: '#8097ad',
                      marginRight: 8,
                    }}
                  />
                  {item?.clickNum} 人浏览
                </span> */}
              </div>
            </div>
          ) : (
            <Skeleton paragraph={{ rows: 1 }} />
          )}
        </div>
      </ComponentWrapper>
      <ComponentWrapper mode="notPC">
        <div style={{ display: 'flex', padding: '0px 0px 20px 0px' }}>
          {item?.pic ? (
            <img src={item?.pic} style={{ height: '80px', width: '129px' }} />
          ) : (
            <img
              src={item?.contentType?.pic}
              style={{ height: '80px', width: '129px' }}
            />
          )}
          {item ? (
            <div
              style={{
                marginLeft: '10px',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '4px 0px',
              }}
            >
              <div className={styles.recommendation_item_title_mobile}>
                {item?.title}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  className={cx(labelCls)}
                  style={{
                    padding: '0px 16px',
                    borderRadius: '4px 12px 4px 12px',
                    lineHeight: '20px',
                  }}
                >
                  {item?.contentType?.name}
                </div>
                <div style={{ color: '#8097AD' }}>
                  <ClockCircleOutlined
                    style={{
                      fontSize: 14,
                      color: '#8097ad',
                      marginRight: 8,
                    }}
                  />
                  {dayjs(item?.lastModifiedTime).format('YYYY-MM-DD')}
                </div>
              </div>
            </div>
          ) : (
            <Skeleton paragraph={{ rows: 1 }} />
          )}
        </div>
      </ComponentWrapper>
    </a>
  );
};

export default Recommendation;
