import React, { useEffect, useState } from 'react';
import { message, Switch } from 'antd';
import { enableContentType, enableLabel } from '@/utils/requests/requests_push';
import { useRequest } from 'umi';

type Props = {
  type: string;
  name: string;
  id: string;
  enabled: boolean;
};

const LabelSwitch: React.FC<Props> = ({ type, name, id, enabled }) => {
  const [value, setValue] = useState(enabled);

  useEffect(() => {
    setValue(enabled);
  }, [enabled]);

  const enableLabelRequest = useRequest(
    (value) => {
      return enableLabel({
        id,
        enabled: value,
      });
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  const enableContentTypeRequest = useRequest(
    (value) => {
      return enableContentType({
        id,
        enabled: value,
      });
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  return (
    <Switch
      checked={value}
      onChange={(checked: boolean) => {
        type == 'label' &&
          enableLabelRequest.run(checked).then(() => {
            setValue(checked);
            message.success(checked ? '启用成功' : '禁用成功');
          });
        type == 'contentType' &&
          enableContentTypeRequest.run(checked).then(() => {
            setValue(checked);
            message.success(checked ? '启用成功' : '禁用成功');
          });
      }}
    />
  );
};

export default LabelSwitch;
