import React, { useState } from 'react';
import styles from '../index.less';
import { Button, Col, Row, Input } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'umi';
import PermissionManegeTable from './table';
import PermissionManegeModal from './modal';

type Props = {};

const PermissionManege: React.FC<Props> = () => {
  const [permissionManegeModalOpen, setPermissionManegeModalOpen] =
    useState(false);
  const [keyword, setKeyword] = useState<string | undefined>();
  const [refresh, setRefresh] = useState(0);

  return (
    <div className={styles.configure_permissions_div}>
      <div className={styles.configure_permissions_title}>
        <Row>
          <Col span={12} style={{ fontSize: '18px' }}>
            <Link to="/news/add" style={{ color: '#333' }}>
              <LeftOutlined />
            </Link>{' '}
            投放权限管理
          </Col>
        </Row>
      </div>
      <div className={styles.configure_permissions_body}>
        <Row>
          <Col span={12}>
            <Input.Search
              placeholder="搜索权限组名称"
              style={{ width: 360 }}
              allowClear
              value={keyword}
              onSearch={(value) => setKeyword(value)}
              onPressEnter={(event: React.KeyboardEvent<HTMLInputElement>) => {
                setKeyword((event.target as HTMLInputElement).value);
              }}
            />
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              onClick={() => {
                setPermissionManegeModalOpen(true);
              }}
            >
              新增
            </Button>
            <PermissionManegeModal
              permissionManegeModalOpen={permissionManegeModalOpen}
              setPermissionManegeModalOpen={setPermissionManegeModalOpen}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Col>
        </Row>
        <PermissionManegeTable keyword={keyword} refresh={refresh} />
      </div>
    </div>
  );
};

export default PermissionManege;
