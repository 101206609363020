import React, { useState } from 'react';
import styles from '../index.less';
import { Button, Col, Row, Modal, Input, Form, Switch, message } from 'antd';
import { Link, useRequest } from 'umi';
import { LeftOutlined } from '@ant-design/icons';
import ProductLabelManageTable from './table';
import { addOrEditLabel } from '@/utils/requests/requests_push';

type Props = {};

const ProductLabelManage: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keyword, setKeyword] = useState<string | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [enabled, setEnabled] = useState(true);
  const [refresh, setRefresh] = useState(0);

  const addOrEditLabelRequest = useRequest(
    () => {
      return addOrEditLabel(
        JSON.stringify({
          name,
          enabled,
        }),
      );
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  return (
    <div className={styles.configure_permissions_div}>
      <div className={styles.configure_permissions_title}>
        <Row>
          <Col span={12} style={{ fontSize: '18px' }}>
            <Link to="/news/add" style={{ color: '#333' }}>
              <LeftOutlined />
            </Link>{' '}
            产品标签管理
          </Col>
        </Row>
      </div>
      <div className={styles.configure_permissions_body}>
        <Row>
          <Col span={12}>
            <Input.Search
              placeholder="产品标签名称"
              style={{ width: 360 }}
              value={keyword}
              allowClear
              onSearch={(value) => setKeyword(value)}
              onPressEnter={(event: React.KeyboardEvent<HTMLInputElement>) => {
                setKeyword((event.target as HTMLInputElement).value);
              }}
            />
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={() => setIsModalOpen(true)}>
              新增
            </Button>
            <Modal
              title="新增产品标签"
              open={isModalOpen}
              okText="确认"
              cancelText="取消"
              width={664}
              onOk={() => form.submit()}
              onCancel={() => setIsModalOpen(false)}
            >
              <Form
                name="basic"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                onFinish={() => {
                  addOrEditLabelRequest.run().then(() => {
                    setRefresh(refresh + 1);
                    setIsModalOpen(false);
                    message.success('新增成功');
                  });
                }}
                autoComplete="off"
              >
                <Form.Item
                  label="标签名称"
                  name="label"
                  rules={[{ required: true, message: '请输入标签名臣' }]}
                >
                  <Input
                    placeholder="请输入..."
                    onChange={(event) => setName(event.target.value)}
                  />
                </Form.Item>
                <Form.Item label="启用状态" valuePropName="checked">
                  <Switch
                    checked={enabled}
                    onChange={(checked: boolean) => setEnabled(checked)}
                  />
                </Form.Item>
              </Form>
            </Modal>
          </Col>
        </Row>
        <ProductLabelManageTable keyword={keyword} refresh={refresh} />
      </div>
    </div>
  );
};

export default ProductLabelManage;
