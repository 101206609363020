import { requestGet } from '@/utils/request';
import { v4 as uuidv4 } from 'uuid';

// 复制
export function copy(params: any) {
  // @ts-ignore
  if (window.clipboardData) {
    // @ts-ignore
    window.clipboardData.setData('text', params);
  } else {
    (function (params) {
      document.oncopy = function (e) {
        // @ts-ignore
        e.clipboardData.setData('text', params);
        e.preventDefault();
        document.oncopy = null;
      };
    })(params);
    document.execCommand('Copy');
  }
  return;
}

// 写入 Cookie
export function setCookie(name: string, value: string | number) {
  //设置名称为name,值为value的Cookie
  var expdate = new Date(); //初始化时间
  expdate.setTime(expdate.getTime() + 30 * 60 * 1000); //时间
  document.cookie =
    name +
    '=' +
    value +
    ';SameSite=None;Secure;expires=' +
    expdate.toUTCString() +
    ';path=/';
  //即document.cookie= name+"="+value+";path=/"; 时间可以不要，但路径(path)必须要填写，因为JS的默认路径是当前页，如果不填，此cookie只在当前页面生效！~
}

// 获取 Cookie
export function getCookie(c_name: string) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=');
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(';', c_start);
      if (c_end == -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return '';
}

// 清空 Cookie
export function clearCookie() {
  let keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
    for (let i = keys.length; i--; ) {
      document.cookie =
        keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();
    }
  }
}

export function delCookie(name: string) {
  const exp = new Date();
  exp.setTime(exp.getTime() - 1);
  const cval = getCookie(name);
  if (cval != null)
    document.cookie =
      name + '=' + cval + ';expires=' + exp.toUTCString() + ';path=/';
}

// 生成随机字符串
export function randomString(length: any) {
  let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-';
  let result = '';
  for (var i = length; i > 0; --i)
    result += str[Math.floor(Math.random() * str.length)];
  return result;
}

// 从链接下载文件到本地
export function openDownloadDialog(url: string, saveName: string) {
  // @ts-ignore
  if (typeof url == 'object' && url instanceof Blob) {
    url = URL.createObjectURL(url); // 创建blob地址
  }
  const aLink = document.createElement('a');
  aLink.href = url;
  aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
  aLink.target = '_blank';
  let event;
  if (window.MouseEvent) {
    event = new MouseEvent('click');
  } else {
    event = document.createEvent('MouseEvents');
    event.initMouseEvent(
      'click',
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null,
    );
  }
  aLink.dispatchEvent(event);
}

// 时间格式转化
export function convertUTCTimeToLocalTime(
  UTCDateString: string,
  showSeconds: boolean = true,
  showHours: boolean = true,
) {
  if (!UTCDateString) {
    return '-';
  }

  function formatFunc(str: number) {
    //格式化显示
    return str > 9 ? str : '0' + str;
  }

  let date2 = new Date(UTCDateString); //这步是关键
  let year = date2.getFullYear();
  let mon = formatFunc(date2.getMonth() + 1);
  let day = formatFunc(date2.getDate());
  let hour = date2.getHours();
  let new_hour = formatFunc(hour);
  let min = formatFunc(date2.getMinutes());
  let seconds = formatFunc(date2.getSeconds());
  let dateStr = year + '-' + mon + '-' + day + ' ' + new_hour + ':' + min;
  if (showSeconds) {
    dateStr =
      year + '-' + mon + '-' + day + ' ' + new_hour + ':' + min + ':' + seconds;
  }
  if (!showHours) {
    dateStr = year + '-' + mon + '-' + day;
  }
  return dateStr;
}

// 获取url中的参数
export function getQueryVariable(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return '';
}

// 获取最新的url中的参数
export function getLastQueryVariable(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = vars.length - 1; i >= 0; i--) {
    const pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return '';
}

// 时间判断
export function compareTime(cTime: string) {
  const currentTimestamp = Date.now();
  const compareTimestamp = new Date(cTime).getTime();
  if (currentTimestamp > compareTimestamp) {
    // 过期
    return false;
  } else {
    // 未过期
    return true;
  }
}

export enum navigationEnum {
  menu,
  back,
}

export async function apiGetSid(sid: string): Promise<{
  code: number;
  success: boolean;
  data: null;
  msg: '操作成功';
  status: null;
}> {
  return requestGet('/api/idapi/sid?sid=' + sid, {});
}

export function processTreeData(treeData: any[]) {
  let newTree = treeData;

  treeData.forEach((item) => {
    item.parentKeyList.forEach((key: any) => {
      if (newTree.find((a: any) => a.key === key)) {
        newTree = newTree.filter((b) => b.key != item.key);
      }
    });
  });
  return newTree;
}

export function treeDataToArray(tree: any[]) {
  let arr: any[] = [];
  const expanded = (data: any[]) => {
    if (data && data.length > 0) {
      data.forEach((e) => {
        arr.push(e);
        expanded(e.children);
      });
    }
  };
  expanded(tree);
  return arr;
}

export function deleteParentArray(arr: any[], item?: any) {
  let newArr: any = arr;

  [...arr.find((value) => value.key == item).parentKeyList, item].forEach(
    (element) => {
      newArr = newArr.filter((a: any) => a.key != element);
    },
  );
  return newArr;
}

export function handleTreeData(treeData: any[], name?: string, parent?: any) {
  return treeData.map((item) => {
    let treeObj: any = {};
    item.leaf
      ? (treeObj.title = item.user.nameEn + '-' + item.user.nameZh)
      : (treeObj.title = item.value);
    item.leaf
      ? (treeObj.key = item.user.nameEn)
      : (treeObj.key = name ? name + '-' + item.value : item.value);

    let a: any[] = [];

    if (parent) {
      parent.parentKeyList.forEach((b: any) => {
        a.push(b);
      });

      a.push(parent.key);
    }
    treeObj.parentKeyList = a;
    !item.leaf
      ? (treeObj.children = handleTreeData(
          item.children,
          name ? name + '-' + item.value : item.value,
          treeObj,
        ))
      : null;

    return treeObj;
  });
}

// 获取url中的参数  直接从账户中心 copy 的代码
export function getUrlParam(name: string) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`); // 构造一个含有目标参数的正则表达式对象
  const r = window.location.search.substr(1).match(reg); // 匹配目标参数
  if (r !== null) {
    return r[2];
  }

  return null; // 返回参数值
}

// 判断用户是否是超级管理员
export function isSuperAdmin(uid?: string | number) {
  if (!uid) {
    return false;
  }
  return ['55549', '672020', '770092'].includes(String(uid));
}

// 获取文件名和文件类型
export function getFileNameAndType(fileName: string) {
  const lastIndex = fileName.lastIndexOf('.');

  return {
    name: fileName.slice(0, lastIndex),
    type: fileName.slice(lastIndex + 1),
  };
}

// 上传文件参数计算
export function calFileUploadParams(fileType: string, OBSPath: string) {
  let uploadPath = `${OBSPath}`;
  let saveFileName = `${uuidv4().replace('-', '')}.${fileType}`;
  return {
    path: uploadPath,
    fileName: saveFileName,
    contentType: fileType,
  };
}

// 生成随机字符串
export function randomStr(length = 6) {
  let str = 'abcdefghijklmnopqrstuvwxyz';
  str += str.toUpperCase();
  str += '0123456789';
  let _str = '';
  for (let i = 0; i < length; i++) {
    let rand = Math.floor(Math.random() * str.length);
    _str += str[rand];
  }

  return _str;
}

export function getCoverBase64(img: any, callback: any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

// 下载文件
export const downloadFile = (path: string) => {
  const linkNode = document.createElement('a');
  linkNode.style.display = 'none';
  linkNode.href = path;
  document.body.appendChild(linkNode);
  linkNode.click(); //模拟在按钮上的一次鼠标单击
  document.body.removeChild(linkNode);
};

export function loginCas({
  loginUrl,
  env,
  testPassportUrl = 'https://passport.fanruan.com',
  prodPassportUrl = 'https://fanruanclub.com',
}: {
  loginUrl?: string;
  env?: 'test' | 'prod';
  prodPassportUrl?: string;
  testPassportUrl?: string;
}) {
  if (loginUrl) {
    window.location.href = loginUrl;
  } else {
    const location = window.location;
    let redirect = location.pathname;
    if (location.search) {
      redirect = `${redirect}${location.search}`;
    }
    const passportUrl = env === 'test' ? testPassportUrl : prodPassportUrl;
    window.location.href = `${passportUrl}/cas/login?service=${window.location.origin}/jdy/server/login/cas?redirect=${redirect}`;
  }
}

export function cul(value: string) {
  value = value + '';
  if (value.includes('.')) {
    return (
      value.slice(0, -3).replace(/(\d)(?=(\d{3})+$)/g, '$1,') + value.slice(-3)
    );
  }
  return value.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
}
