import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider, Divider, Space } from 'antd';
import { Table, Switch, Image, Tooltip, DatePicker } from 'antd';
import RecommendedContentModal from './pushModal';
import zhCN from 'antd/lib/locale/zh_CN';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useRequest, useModel } from 'umi';
import {
  getPushManageRecords,
  updatePush,
} from '@/utils/requests/requests_push';
import { LabelItem } from './productLabelManage/table';
import { ContentTypeItem } from './contentTypeManage/table';
import { FilterFilled } from '@ant-design/icons';
import styles from './index.less';

type Props = {
  createdByName?: string;
  keyword?: string;
  shelfStatus?: string;
  refresh: number;
  setRefresh: Function;
};

/**
 * 产品资讯
 */
export interface ProductInformationItem {
  clickNum?: number;
  label?: LabelItem;
  createdByName?: string;
  id?: string;
  contentType?: ContentTypeItem;
  shelfStatus?: string;
  pic?: string;
  title?: string;
  url?: string;
  lastModifiedTime?: string;
  offShelfTime?: string;
}

const ProductInformationTable: React.FC<Props> = ({
  keyword,
  createdByName,
  shelfStatus,
  refresh,
  setRefresh,
}) => {
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(10);
  const [pages, setPages] = useState(0);
  const [data, setData] = useState<any>([]);
  const [recommendedContentModalOpen, setRecommendedContentModalOpen] =
    useState(false);
  const [currentDetail, setCurrentDetail] = useState<ProductInformationItem>();
  const [createdTime, setCreatedTime] = useState<any>([]);
  const [createdTimeStart, setCreatedTimeStart] = useState<any>();
  const [createdTimeEnd, setCreatedTimeEnd] = useState<any>();
  const { initialState } = useModel('@@initialState');
  const { isFrAccount } = initialState;

  const getPushRecordsRequest = useRequest(
    (
      createdByName?: string,
      keyword?: string,
      shelfStatus?: string,
      current?: number,
      size?: number,
      createdTimeStart?: string,
      createdTimeEnd?: string,
    ) => {
      return getPushManageRecords({
        createdByName,
        keyword,
        shelfStatus,
        createdTimeStart,
        createdTimeEnd,
        current,
        size,
      });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setData(res.records);
        setTotal(res.total);
        setPages(res.pages);
        setLoading(false);
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    isFrAccount &&
      getPushRecordsRequest.run(
        createdByName,
        keyword,
        shelfStatus,
        current,
        size,
        createdTimeStart,
        createdTimeEnd,
      );
  }, [
    createdByName,
    keyword,
    shelfStatus,
    current,
    size,
    createdTimeStart,
    createdTimeEnd,
    refresh,
  ]);

  // const deletePushRequest = useRequest(
  //   (id) => {
  //     return deletePush({ id });
  //   },
  //   {
  //     manual: true,
  //     onSuccess: (res) => {},
  //     onError: (e) => {},
  //   },
  // );

  const updatePushRequest = useRequest(
    (id, shelfStatus) => {
      return updatePush({ id, shelfStatus });
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  const columns: ColumnsType<ProductInformationItem> = [
    {
      title: 'pic',
      dataIndex: 'pic',
      key: 'pic',
      width: 52,
      align: 'center',
      render: (value, record) =>
        record.pic ? (
          <Image width={44} height={44} src={value} />
        ) : (
          <Image width={44} height={44} src={record.contentType?.pic} />
        ),
    },
    {
      title: '标题',
      dataIndex: 'title',
      key: 'title',
      width: 352,
      render: (value, record) => (
        <Tooltip title={value}>
          <div
            style={{
              width: '360px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              cursor: 'pointer',
              color: '#1890FF',
            }}
            onClick={() => {
              setCurrentDetail(record);
              setRecommendedContentModalOpen(true);
            }}
          >
            {value}
          </div>
        </Tooltip>
      ),
    },
    {
      title: '产品标签',
      dataIndex: 'label',
      key: 'label',
      width: 92,
      render: (value) => `${value ? value.name : '--'}`,
    },
    {
      title: '内容类型',
      key: 'contentType',
      dataIndex: 'contentType',
      width: 92,
      render: (value) => `${value ? value.name : '--'}`,
    },
    {
      title: '点击数',
      key: 'clickNum',
      dataIndex: 'clickNum',
      width: 72,
      align: 'center',
      render: (value) => `${value ? value : '0'}`,
    },
    {
      title: '创建人',
      key: 'createdByName',
      dataIndex: 'createdByName',
      width: 132,
      render: (value) => `${value}`,
    },
    {
      title: '上架时间',
      key: 'lastModifiedTime',
      dataIndex: 'lastModifiedTime',
      filterIcon: () => <FilterFilled />,
      filterDropdown: () => (
        <div>
          <Space
            direction="vertical"
            size={12}
            style={{ padding: '8px', display: 'block' }}
          >
            <ConfigProvider locale={zhCN}>
              <DatePicker.RangePicker
                placeholder={['开始日期', '结束日期']}
                ranges={{
                  近一周: [dayjs().subtract(6, 'days'), dayjs()],
                  近一个月: [dayjs().subtract(30, 'days'), dayjs()],
                  近半年: [dayjs().subtract(6, 'month'), dayjs()],
                  近一年: [dayjs().subtract(1, 'year'), dayjs()],
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    dayjs('00:00:00', 'HH:mm:ss'),
                    dayjs('00:00:00', 'HH:mm:ss'),
                  ],
                }}
                format="YYYY-MM-DD HH:mm:ss"
                value={createdTime}
                onChange={(dates, dateStrings) => {
                  setCreatedTimeStart(
                    dayjs(dateStrings[0]).format('YYYY-MM-DDTHH:mm:[00][Z]'),
                  );
                  setCreatedTimeEnd(
                    dayjs(dateStrings[1]).format('YYYY-MM-DDTHH:mm:[00][Z]'),
                  );
                  setCreatedTime(dates);
                }}
              />
            </ConfigProvider>
          </Space>
          <Divider style={{ margin: '0px' }} />
          <Space style={{ padding: '8px' }} size={174}>
            <Button
              size={'small'}
              type={'link'}
              onClick={() => {
                setCreatedTime([]);
                setCreatedTimeStart(undefined);
                setCreatedTimeEnd(undefined);
              }}
            >
              重置
            </Button>
          </Space>
        </div>
      ),
      width: 112,
      render: (value) =>
        value ? `${dayjs(value).format('YYYY-MM-DD HH:mm:ss')}` : '--',
    },
    {
      title: '下架时间',
      key: 'offShelfTime',
      dataIndex: 'offShelfTime',
      filterIcon: () => <FilterFilled />,
      filterDropdown: () => (
        <div>
          <Space
            direction="vertical"
            size={12}
            style={{ padding: '8px', display: 'block' }}
          >
            <ConfigProvider locale={zhCN}>
              <DatePicker.RangePicker
                placeholder={['开始日期', '结束日期']}
                ranges={{
                  近一周: [dayjs().subtract(6, 'days'), dayjs()],
                  近一个月: [dayjs().subtract(30, 'days'), dayjs()],
                  近半年: [dayjs().subtract(6, 'month'), dayjs()],
                  近一年: [dayjs().subtract(1, 'year'), dayjs()],
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    dayjs('00:00:00', 'HH:mm:ss'),
                    dayjs('00:00:00', 'HH:mm:ss'),
                  ],
                }}
                format="YYYY-MM-DD HH:mm:ss"
                value={createdTime}
                onChange={(dates, dateStrings) => {
                  setCreatedTimeStart(
                    dayjs(dateStrings[0]).format('YYYY-MM-DDTHH:mm:[00][Z]'),
                  );
                  setCreatedTimeEnd(
                    dayjs(dateStrings[1]).format('YYYY-MM-DDTHH:mm:[00][Z]'),
                  );
                  setCreatedTime(dates);
                }}
              />
            </ConfigProvider>
          </Space>
          <Divider style={{ margin: '0px' }} />
          <Space style={{ padding: '8px' }} size={174}>
            <Button
              size={'small'}
              type={'link'}
              onClick={() => {
                setCreatedTime([]);
                setCreatedTimeStart(undefined);
                setCreatedTimeEnd(undefined);
              }}
            >
              重置
            </Button>
          </Space>
        </div>
      ),
      width: 112,
      render: (value) =>
        value ? `${dayjs(value).format('YYYY-MM-DD HH:mm:ss')}` : '--',
    },
    {
      title: '上架状态',
      key: 'shelfStatus',
      dataIndex: 'shelfStatus',
      width: 132,
      align: 'center',
      render: (value, record) => (
        <Switch
          checked={value === 1}
          onChange={(checked: boolean) => {
            console.log(`switch to ${checked}`);
            updatePushRequest.run(record.id, checked ? '1' : '-1').then(() => {
              setRefresh(refresh + 1);
            });
          }}
        />
      ),
    },
    // {
    //   title: '操作',
    //   key: 'id',
    //   dataIndex: 'id',
    //   width: 120,
    //   render: (value, record) => (
    //     <>
    //       <Space split={<Divider type="vertical" />}>
    //         <Button
    //           type="link"
    //           onClick={() => {
    //             setCurrentDetail(record);
    //             setRecommendedContentModalOpen(true);
    //           }}
    //         >
    //           编辑
    //         </Button>
    //         <Button
    //           type="link"
    //           onClick={() => {
    //             Modal.confirm({
    //               title: '确认删除所选数据？',
    //               icon: <QuestionCircleOutlined />,
    //               content: '此操作不可逆，请谨慎操作',
    //               okText: '删除',
    //               okButtonProps: { type: 'primary', danger: true },
    //               cancelText: '取消',
    //               cancelButtonProps: { danger: true },
    //               onOk() {
    //                 deletePushRequest.run(value).then(() => {
    //                   getPushRecordsRequest.run(
    //                     createdByName,
    //                     keyword,
    //                     shelfStatus,
    //                     current,
    //                     size,
    //                   );
    //                 });
    //               },
    //               onCancel() {},
    //             });
    //           }}
    //         >
    //           删除
    //         </Button>
    //       </Space>
    //     </>
    //   ),
    // },
  ];

  return (
    <>
      <ConfigProvider locale={zhCN}>
        <Table
          className={styles.news_table}
          columns={columns}
          dataSource={data}
          onChange={(pagination) => {
            pagination.current && setCurrent(pagination.current);
            pagination.pageSize && setSize(pagination.pageSize);
          }}
          loading={loading}
          pagination={{
            total: total,
            pageSize: size,
            current: current,
            hideOnSinglePage: true,
            showQuickJumper: true,
          }}
          style={{
            width: '1152px',
            marginTop: '16px',
            color: '#333333',
          }}
        />
      </ConfigProvider>
      <RecommendedContentModal
        modalTitle="编辑推荐内容"
        okText="保存"
        submitMessage="保存成功"
        recommendedContentModalOpen={recommendedContentModalOpen}
        setRecommendedContentModalOpen={setRecommendedContentModalOpen}
        record={currentDetail}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </>
  );
};

export default ProductInformationTable;
