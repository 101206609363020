import React, { useEffect } from 'react';
import WelcomeCard from '@/components/welcomeCard/index';

const BbsModule: React.FC = () => {
  // 接入广告
  useEffect(() => {
    let getAdEnv = process.env.UMI_ENV == 'product' ? false : true;
    window.fineAd.config({ debug: getAdEnv, dev: getAdEnv });
    window.fineAd.getAdvertisement({
      adId: 'fc40d3d7039622d437e0a8ea02094105',
      bottom: 24,
    });
  }, []);
  return (
    <React.Fragment>
      <WelcomeCard />
      <div
        id="AD-fc40d3d7039622d437e0a8ea02094105"
        className="ad-img-radius"
      ></div>
    </React.Fragment>
  );
};
export default BbsModule;
