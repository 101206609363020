import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './index.less';
import RGL, { Layout, WidthProvider } from 'react-grid-layout';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import useGetDragInfo from '@/hooks/useGetDragInfo';
import DragCardButtons from '@/components/dragCard/button';
import { DragCardContext } from '@/context/dragContext';
import { TDragCardItem } from '@/types/common/index';
import DragCardItem from '@/components/dragCard/dragCardItem';
import CardEditModal from '@/components/dragCard/cardEditModal';
import {
  apiChangeIndexCard,
  apiDeleteCardById,
} from '@/services/personalService/index';
import VisibleComponent from '@/components/visibleComponent/index';
import { isSuperAdmin, randomStr } from '@/utils/index';
import useGetUserInfo from '@/hooks/useGetUserInfo';
import { ECardType } from '@/enum/card';
import { useDispatch } from 'umi';
import { message } from 'antd';

const ReactGridLayout = WidthProvider(RGL);

type TDragCardProps = {
  type?: 'INTERNAL' | 'PERSONAL';
  outLayout: Layout[];
  outData: TDragCardItem[];
  onSaveLayout: (tempLayout: Layout[]) => void;
  onRefreshData: () => void; // 点击取消按钮或者保存按钮的时候，从后端更新最新的数据
};

const DragCard: React.FC<TDragCardProps> = ({
  outData,
  outLayout,
  type = 'PERSONAL',
  onSaveLayout,
  onRefreshData,
}) => {
  const dispatch = useDispatch();
  const { uid } = useGetUserInfo();
  const { isEdit } = useGetDragInfo();
  const [activeCardId, setActiveCardId] = useState<string>();
  const [cardEditModalVisible, setCardEditModalVisible] = useState(false);
  const [data, setData] = useState<TDragCardItem[]>(outData);
  const [tempData, setTempData] = useState<TDragCardItem[]>([]);
  const [tempLayout, setTempLayout] = useState<Layout[]>([]);
  const [layout, setLayout] = useState<Layout[]>(outLayout);

  const toggleEdit = async (isInEdit: boolean, layoutData?: Layout[]) => {
    if (layoutData) {
      setLayout(() => {
        return layoutData.map((item) => ({
          ...item,
          static: !isInEdit,
        }));
      });
    } else {
      setLayout((prevState) => {
        return prevState.map((item) => ({
          ...item,
          static: !isInEdit,
        }));
      });
    }
  };
  const createDragCard = () => {
    const dataSource = isEdit ? tempData : data;
    return dataSource
      .filter((item) => !item.delete)
      .map((item) => {
        return (
          <div key={item.id} className={styles.react_grid_item}>
            <DragCardItem item={item} type={type} />
          </div>
        );
      });
  };

  useEffect(() => {
    if (Array.isArray(outData)) {
      setData(outData);
    }
  }, [outData]);

  return (
    <DragCardContext.Provider
      value={{
        onDeleteCard: (id) => {
          const deleteTempItemIdx = tempData.findIndex(
            (item) => item.id === id,
          );
          const deleteItemIdx = data.findIndex((item) => item.id === id);
          const deleteItemLayoutIdx = tempLayout.findIndex(
            (item) => item.i === id,
          );
          if (deleteItemIdx > -1) {
            setData((prevState) => {
              prevState.splice(deleteItemIdx, 1, {
                ...data[deleteItemIdx],
                delete: true,
              });
              return [...prevState];
            });
          }
          if (deleteTempItemIdx > -1) {
            setTempData((prevState) => {
              prevState.splice(deleteTempItemIdx, 1, {
                ...tempData[deleteTempItemIdx],
                delete: true,
              });
              return [...prevState];
            });
          }
          if (deleteItemLayoutIdx && deleteItemLayoutIdx > -1) {
            const oldLayout = JSON.parse(JSON.stringify(tempLayout));
            oldLayout.splice(deleteItemLayoutIdx, 1);
            setTempLayout([...oldLayout]);
          }
        },
        onCardChange: (id) => {
          setActiveCardId(id);
          setCardEditModalVisible(true);
        },
        cardNumber: layout?.length,
      }}
    >
      <div className={styles.drag_card_wrapper}>
        <VisibleComponent visible={isSuperAdmin(uid)}>
          <DragCardButtons
            onSaveEdit={async () => {
              try {
              } catch (e) {
                message.warning('操作失败，请稍后重试');
              }
              // 执行新增操作
              let newCreateCardIdArray = [];
              let oldCreateCardIdArray = [];
              const createCardArray = tempData.filter((item) => item.isNew);
              for (let i = 0; i < createCardArray.length; i++) {
                // 去除新增卡片中自己加的id
                let newData: any = createCardArray[i];
                let oldId = newData.id;
                delete newData.id;
                const res = await apiChangeIndexCard(type, newData);
                console.log('新增成功');
                if (res?.data?.id) {
                  newCreateCardIdArray.push(res?.data?.id);
                  oldCreateCardIdArray.push(oldId);
                  newData.id = res?.data?.id as string;
                  // 向data里添加, 避免闪烁
                  setData((prevState) => {
                    prevState.push(newData);
                    return [...prevState];
                  });
                }
              }
              // 执行删除操作
              const deleteCardArray = data.filter((item) => item.delete);
              for (let i = 0; i < deleteCardArray.length; i++) {
                const deleteCard = deleteCardArray[i];
                const id = deleteCard.id;
                await apiDeleteCardById(id);
              }
              // 执行编辑操作
              const editCardArray = tempData.filter((item) => item.edit);
              for (let i = 0; i < editCardArray.length; i++) {
                const editCard = editCardArray[i];
                const id = editCard.id;
                const editCardData = tempData.find(
                  (tempItem) => tempItem.id === id,
                );
                if (editCardData) {
                  // 根据现在的cardItemList顺序 调整index
                  editCardData.cardItemList = editCardData.cardItemList.map(
                    (item, index) => {
                      return { ...item, index };
                    },
                  );
                  await apiChangeIndexCard(type, editCardData);
                }
              }

              // 更新tempLayout
              let depthLayout = JSON.parse(JSON.stringify(tempLayout));
              for (let i = 0; i < newCreateCardIdArray.length; i++) {
                const newId = newCreateCardIdArray[i];
                const oldId = oldCreateCardIdArray[i];
                const tempLayoutIdx = tempLayout.findIndex(
                  (item) => item.i === oldId,
                );
                if (tempLayoutIdx > -1) {
                  depthLayout.splice(tempLayoutIdx, 1, {
                    ...depthLayout[tempLayoutIdx],
                    i: newId,
                  });
                }
              }

              const newCardArray: TDragCardItem[] = [];
              // 从缓存数据中更新data
              const unDeleteCardArray = tempData.filter((item) => !item.delete);
              for (let i = 0; i < unDeleteCardArray.length; i++) {
                const card = unDeleteCardArray[i];
                if (card.edit) {
                  const editId = card.id;
                  const findItem = tempData?.find((temp) => temp.id === editId);
                  if (findItem) {
                    newCardArray.push(findItem);
                  }
                } else {
                  newCardArray.push(card);
                }
              }
              setData(newCardArray);

              //操作完后更新layout
              await toggleEdit(false, depthLayout);
              console.log('最后执行更新的tempLayout是');
              console.log(depthLayout);
              onSaveLayout(depthLayout);
              onRefreshData();
              dispatch({
                type: 'drag/save',
                payload: {
                  isEdit: false,
                },
              });
            }}
            onStartEdit={async () => {
              // 将数据填入缓存中
              setTempData(data);
              setTempLayout(() => {
                return layout.map((item) => ({
                  ...item,
                  static: false,
                }));
              });
              await toggleEdit(true);
            }}
            onCancel={() => {
              console.log('取消编辑');
              console.log(data);
              console.log(tempData);
              setTempLayout([]);
              setTempData([]);
              setLayout((prevState) => {
                return prevState.map((item) => ({
                  ...item,
                  static: true,
                }));
              });
              onRefreshData();
            }}
          />
        </VisibleComponent>
        <div>
          <ReactGridLayout
            className={cx(
              styles.react_grid_layout,
              isEdit && styles.react_grid_layout_edit,
            )}
            containerPadding={isEdit ? [10, 10] : [0, 0]}
            margin={[20, 20]}
            layout={isEdit ? [...tempLayout] : layout}
            cols={3}
            rowHeight={90}
            onLayoutChange={(dragLayout) => {
              setTempLayout(dragLayout);
            }}
          >
            {createDragCard()}
          </ReactGridLayout>
        </div>
      </div>
      <CardEditModal
        onCardCreate={async (cardData: TDragCardItem) => {
          console.log('有新的卡片了');
          console.log(cardData);
          // 向缓存中添加新卡片
          const newCardId = randomStr(7);
          let array = JSON.parse(JSON.stringify(tempData));
          let depthLayout = JSON.parse(JSON.stringify(tempLayout));
          cardData.isNew = true;
          cardData.id = newCardId;
          array.push(cardData);
          setTempData([...array]);

          // 计算新卡片的位置
          const h = cardData.type === ECardType.small ? 2 : 4;

          const addItem = (itemId: string, layout: Layout[]) => {
            // 初始化元素
            let newItem = {
              i: itemId,
              x: 0,
              y: 0,
              w: 1,
              h,
              static: false,
              isResizable: false,
            };
            // 确定边界
            let maxX = 0;
            let maxY = 0;
            let edgeX = 0;
            let edgeY = 0;
            const yArray = layout?.map((item) => Number(item.y));
            edgeX = 6;
            edgeY = Math.max.apply(null, yArray) + 1;
            // 使用二维数组生成地图
            let gridMap: any[][] = [];
            for (let x = 0; x < edgeX; x++) {
              gridMap[x] = [];
              for (let y = 0; y < edgeY; y++) {
                gridMap[x][y] = 0;
              }
            }
            console.log('gridMap是');
            console.log(gridMap);
            // 标记占位
            layout.forEach((item) => {
              // 将layout中卡片所占区域标记为1
              for (let x = item.x; x < item.x + item.w; x++) {
                for (let y = item.y; y < item.y + item.h; y++) {
                  gridMap[x][y] = 1;
                }
              }
            });
            // 遍历地图，申请位置
            for (let y = 0; y < edgeY; y++) {
              for (let x = 0; x < edgeX; x++) {
                // 申请所需空间
                if (edgeX - x >= newItem.w && edgeY - y >= newItem.h) {
                  let itemSignArr = [];
                  for (let a = x; a < x + newItem.w; a++) {
                    for (let b = y; b < y + newItem.h; b++) {
                      itemSignArr.push(gridMap[x][y]);
                    }
                  }
                  if (itemSignArr.indexOf(1) < 0) {
                    newItem.x = x;
                    newItem.y = y;
                    layout.push(newItem);
                    return;
                  }
                }
              }
            }
            // 无满足条件
            newItem.x = 0;
            newItem.y = edgeY + 1;
            layout.push(newItem);
          };
          addItem(newCardId, depthLayout);
          setTempLayout([...depthLayout]);
          setCardEditModalVisible(false);
        }}
        onCardEdit={async (cardData: TDragCardItem) => {
          let array = JSON.parse(JSON.stringify(tempData));

          let tempEditCardIdx = tempData.findIndex(
            (item) => item.id === cardData.id,
          );
          if (tempEditCardIdx > -1) {
            // 卡片种类改变了，要调整卡片的高度
            if (tempData?.[tempEditCardIdx].type !== cardData.type) {
              const h = Number(cardData.type) === ECardType.small ? 2 : 4;
              // 更新layout缓存
              const tempLayoutIdx = tempLayout?.findIndex(
                (tempItem) => tempItem.i === cardData.id,
              );
              if (tempLayoutIdx > -1) {
                setTempLayout((prevState) => {
                  prevState.splice(tempLayoutIdx, 1, {
                    ...tempLayout[tempLayoutIdx],
                    h,
                  });
                  return [...prevState];
                });
              }
            }
            cardData.edit = true;
            array.splice(tempEditCardIdx, 1, { ...cardData });
            console.log('看一下最后要更新的array');
            console.log(array);
            setTempData([...array]);
          }

          setCardEditModalVisible(false);
        }}
        activeCard={
          activeCardId
            ? tempData.find((item) => item.id === activeCardId)
            : undefined
        }
        visible={cardEditModalVisible}
        onCancel={() => {
          setCardEditModalVisible(false);
        }}
      />
    </DragCardContext.Provider>
  );
};
export default DragCard;
