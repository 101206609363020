interface String {
  // 👇️ function log - no parameters, returns object
  myReplace(f: any, e: any): string;
}

String.prototype.myReplace = function (f: any, e: any) {
  // 把f替换成e
  const reg = new RegExp(f, 'g'); // 创建正则RegExp对象

  return this.replace(reg, e);
};
