import React, { ReactNode } from 'react';
import styles from './index.less';
import LockTriangle from '@/components/lockTriangle/index';

export interface MiddleServiceCardProps {
  unlock: boolean;
  title: string | ReactNode;
  subTitle: string | ReactNode;
  icon?: string;
  onClick?: () => void;
  isSpecial?: boolean;
}

type TMSCProps = {
  item: MiddleServiceCardProps;
};

const MiddleServiceCard: React.FC<TMSCProps> = ({ item }) => {
  return (
    <div
      className={
        item.isSpecial ? styles.card_wrapper_special : styles.card_wrapper
      }
      onClick={item.isSpecial ? () => {} : item.onClick}
    >
      <div className={styles.card_icon}>
        <img src={item.icon} />
      </div>
      <div className={styles.card_title}>{item.title}</div>
      <div className={styles.card_subtitle}>{item.subTitle}</div>
      {!item.unlock && <LockTriangle />}
    </div>
  );
};

export default MiddleServiceCard;
