import styles from './index.less';
import classNames from 'classnames';
import LatestRecommendations from '@/pages/personalService/components/latestRecommendations';
import React, { useState } from 'react';
import DetailedRecommendation from '@/pages/personalService/components/detailedRecommendation';
import { ComponentWrapper } from '@digit-fe/digit-component';
import '@/utils/advertisementSDK.js';
import BBSCard from './components/bbsCard';

export default function IndexPage() {
  const [isWelComeFinished, setIsWelComeFinished] = useState(false);

  return (
    <>
      <div className={classNames(styles.layout_base_flex)}>
        <LatestRecommendations isWelComeFinished={isWelComeFinished} />
        <div className={classNames(styles.layout_vertical)}>
          <ComponentWrapper>
            <>
              <img
                style={{
                  width: '384px',
                  borderRadius: '12px',
                  marginBottom: '24px',
                  cursor: 'pointer',
                }}
                src={require('@/static/images/home_pic.png')}
                onClick={() => {
                  window.open('https://edu.fanruan.com/studypath/finebi');
                }}
              />
              <BBSCard />
              <DetailedRecommendation />
            </>
          </ComponentWrapper>
        </div>
      </div>
    </>
  );
}
