import React, { useEffect, useState } from 'react';
import {
  Button,
  ConfigProvider,
  Divider,
  Modal,
  Table,
  Tag,
  message,
} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import type { ColumnsType } from 'antd/es/table';
import { QuestionCircleOutlined } from '@ant-design/icons';
import PermissionManegeModal from './modal';
import dayjs from 'dayjs';
import { useRequest } from 'umi';
import {
  deleteAuthority,
  getAllAuthorityRecords,
} from '@/utils/requests/requests_push';
import { LabelItem } from '../productLabelManage/table';

type Props = { keyword?: string; refresh: number };

export interface PermissionRecordDataType {
  admin: boolean;
  authorizedDeptList: any;
  informationLabelList: LabelItem[];
  authorizedUserList: any;
  id: string;
  lastModifiedTime: string;
  name: string;
  authorizedStr: string;
  enabled: boolean;
}

const PermissionManegeTable: React.FC<Props> = ({ keyword, refresh }) => {
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(10);
  const [pages, setPages] = useState(0);
  const [data, setData] = useState<any>([]);
  const [record, setRecord] = useState<PermissionRecordDataType | undefined>();
  const [refreshOther, setRefreshOther] = useState(refresh);

  const [permissionManegeModalOpen, setPermissionManegeModalOpen] =
    useState(false);

  const getAllAuthorityRecordsRequest = useRequest(
    (keyword: string, current: number, size: number) => {
      return getAllAuthorityRecords({
        keyword,
        current,
        size,
        type: 'MY_FANRUAN',
      });
    },
    {
      manual: true,
      onSuccess: (res) => {
        let records = res.records;
        let recordsNew: any[] = [];
        records.map((item: any) => {
          let a = '';
          for (var x = 0; x < item.authorizedDeptList.length; x++) {
            if (x == 0) {
              a = item.authorizedDeptList[0].dept;
            } else if (x == item.authorizedDeptList.length - 1) {
              a = a + '、' + item.authorizedDeptList[x].dept;
            } else {
              a = a + '、' + item.authorizedDeptList[x].dept;
            }
          }
          for (var x = 0; x < item.authorizedUserList.length; x++) {
            if (x == 0 && item.authorizedDeptList.length < 1) {
              a =
                item.authorizedUserList[x].nameEn +
                '-' +
                item.authorizedUserList[x].nameZh;
            } else {
              a =
                a +
                '、' +
                item.authorizedUserList[x].nameEn +
                '-' +
                item.authorizedUserList[x].nameZh;
            }
          }
          recordsNew.push(Object.assign({}, item, { authorizedStr: a }));
        });

        setData(recordsNew);
        setPages(res.pages);
        setSize(res.size);
        setTotal(res.total);
        setLoading(false);
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    getAllAuthorityRecordsRequest.run(keyword, current, size);
  }, [keyword, current, size, refresh, refreshOther]);

  const deleteAuthorityRequest = useRequest(
    (id: string) => {
      return deleteAuthority({
        id: id,
      });
    },
    {
      manual: true,
      onSuccess: (res) => {
        message.success('删除授权成功');
      },
      onError: (e) => {
        message.error('删除授权失败');
      },
    },
  );

  const columns: ColumnsType<PermissionRecordDataType> = [
    {
      title: '序号',
      align: 'center',
      render: (text: any, record: any, index: any) => `${index + 1}`,
      width: '60px',
    },
    {
      title: '权限组',
      dataIndex: 'name',
      width: '140px',
      render: (value) => `${value}`,
    },
    {
      title: '授权产品标签',
      dataIndex: 'informationLabelList',
      width: '190px',
      render: (value) =>
        value?.map((item: LabelItem) => {
          return <Tag>{item.name}</Tag>;
        }),
    },
    {
      title: '授权用户',
      dataIndex: 'authorizedStr',
      width: '360px',
      render: (value) => `${value}`,
    },
    {
      title: '更新时间',
      dataIndex: 'lastModifiedTime',
      width: '180px',
      render: (value) => `${dayjs(value).format('YYYY-MM-DD HH:mm:ss')}`,
    },
    {
      title: '操作',
      width: '140px',
      dataIndex: 'id',
      key: 'id',
      render: (value, record: PermissionRecordDataType) => {
        return (
          <div className="fcoin-manage-button">
            <Button
              type="link"
              onClick={() => {
                setRecord(record);
                setPermissionManegeModalOpen(true);
              }}
            >
              编辑授权
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              style={{ color: 'red' }}
              onClick={() => {
                Modal.confirm({
                  icon: <QuestionCircleOutlined />,
                  title: '确定删除该权限组吗？',
                  content: '删除后无法恢复，请谨慎操作。',
                  okText: '删除',
                  onOk: () => {
                    deleteAuthorityRequest.run(value).then(() => {
                      setRefreshOther(refreshOther + 1);
                    });
                  },
                  cancelText: '取消',
                  okButtonProps: { danger: true },
                });
              }}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ConfigProvider locale={zhCN}>
        <Table
          columns={columns}
          dataSource={data}
          onChange={(pagination) => {
            pagination.current && setCurrent(pagination.current);
            pagination.pageSize && setSize(pagination.pageSize);
          }}
          loading={loading}
          pagination={{
            total: total,
            pageSize: size,
            current: current,
            hideOnSinglePage: true,
            showQuickJumper: true,
          }}
          style={{
            width: '1152px',
            marginTop: '16px',
            color: '#333333',
          }}
        />
      </ConfigProvider>
      <PermissionManegeModal
        permissionManegeModalOpen={permissionManegeModalOpen}
        setPermissionManegeModalOpen={setPermissionManegeModalOpen}
        modalTitle="编辑权限组"
        okText="保存"
        submitMessage="保存成功"
        record={record}
        refresh={refreshOther}
        setRefresh={setRefreshOther}
      />
    </>
  );
};

export default PermissionManegeTable;
