import React, { useEffect, useState } from 'react';
import { Modal, message, Switch, Input, Select, Upload } from 'antd';
import PermissionTab from '@/components/permissionTab/permissionTab';
import { PermissionRecordDataType } from './table';
import { useRequest } from 'umi';
import {
  addAuthority,
  getAllLabels,
  updateAuthority,
} from '@/utils/requests/requests_push';
import { LabelItem } from '../productLabelManage/table';

type Props = {
  permissionManegeModalOpen: boolean;
  setPermissionManegeModalOpen: Function;
  modalTitle?: string;
  okText?: string;
  submitMessage?: string;
  record?: PermissionRecordDataType;
  refresh: number;
  setRefresh: Function;
};

const PermissionManegeModal: React.FC<Props> = ({
  permissionManegeModalOpen,
  setPermissionManegeModalOpen,
  modalTitle = '新建权限组',
  okText = '确认',
  submitMessage = '新建成功',
  record = undefined,
  refresh,
  setRefresh,
}) => {
  const [name, setName] = useState('');
  const [enabled, setEnabled] = useState(true);
  const [authorizedTypeNameList, setAuthorizedTypeNameList] = useState<any[]>(
    [],
  );
  const [allLabelOptions, setAllLabelOptions] = useState<any>([]);
  const [authorizedUserList, setAuthorizedUserList] = useState<any>([]);
  const [authorizedDeptList, setAuthorizedDeptList] = useState<any>([]);
  const [deptList, setDeptList] = useState<any[]>([]);
  const [nameEnList, setNameEnList] = useState<any[]>([]);
  const [value, setValue] = useState<any>();

  useEffect(() => {
    record &&
      setAuthorizedTypeNameList(
        record?.informationLabelList.map((item) => item.id),
      );
    record && setAuthorizedDeptList(record?.authorizedDeptList);
    record && setAuthorizedUserList(record?.authorizedUserList);
    record && setName(record?.name);
    record && setEnabled(record?.enabled);
  }, [record]);

  useEffect(() => {
    console.log(name, enabled, authorizedTypeNameList, deptList, nameEnList);

    setValue(
      JSON.stringify({
        name,
        enabled,
        informationLabelList: authorizedTypeNameList,
        deptList,
        nameEnList,
      }),
    );
  }, [name, enabled, authorizedTypeNameList, deptList, nameEnList]);

  const getAllLabelsRequest = useRequest(
    () => {
      return getAllLabels();
    },
    {
      manual: true,
      onSuccess: (res) => {
        setAllLabelOptions(
          res.map((item: LabelItem) => ({
            label: item.name,
            value: item.id,
          })),
        );
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    getAllLabelsRequest.run();
  }, []);

  const addAuthorityRequest = useRequest(
    () => {
      return addAuthority(value);
    },
    {
      manual: true,
      onSuccess: (res) => {
        message.success('添加授权成功');
      },
      onError: (e) => {
        message.error('添加授权失败');
      },
    },
  );

  const updateAuthorityRequest = useRequest(
    () => {
      return updateAuthority(record?.id, value);
    },
    {
      manual: true,
      onSuccess: (res) => {
        message.success('编辑授权成功');
      },
      onError: (e) => {
        message.error('编辑授权失败');
      },
    },
  );

  return (
    <Modal
      open={permissionManegeModalOpen}
      title={modalTitle}
      width={694}
      destroyOnClose={true}
      okText={okText}
      onOk={() => {
        modalTitle === '新建权限组' &&
          addAuthorityRequest.run().then(() => {
            setRefresh(refresh + 1);
            setPermissionManegeModalOpen(false);
          });
        modalTitle === '编辑权限组' &&
          updateAuthorityRequest.run().then(() => {
            setRefresh(refresh + 1);
            setPermissionManegeModalOpen(false);
          });
      }}
      cancelText="取消"
      onCancel={() => {
        setPermissionManegeModalOpen(false);
      }}
    >
      <div style={{ lineHeight: '32px' }}>
        权限组名称
        <span style={{ color: 'red' }}>*</span>
        <Input
          placeholder="请输入"
          value={name}
          onChange={(event: any) => {
            setName(event.target.value);
          }}
        />
      </div>
      <div style={{ lineHeight: '32px', marginTop: '10px' }}>
        是否启用
        <Switch
          checked={enabled}
          onChange={(checked: boolean) => {
            setEnabled(checked);
          }}
          style={{ marginLeft: '10px', marginTop: '-4px' }}
        />
      </div>
      <div style={{ marginTop: '10px', lineHeight: '32px' }}>
        授权产品标签
        <span style={{ color: 'red' }}>*</span>
      </div>
      <div>
        <Select
          mode="multiple"
          showArrow
          placeholder="请选择要授权的产品标签，可多选"
          maxTagCount="responsive"
          showSearch={false}
          value={authorizedTypeNameList}
          options={allLabelOptions}
          onChange={(value: any[]) => {
            setAuthorizedTypeNameList(value);
          }}
          style={{ width: '100%' }}
        />
      </div>
      <PermissionTab
        authorizedUserList={authorizedUserList}
        setDeptListValue={setDeptList}
        authorizedDeptList={authorizedDeptList}
        setNameEnListValue={setNameEnList}
        authorityType="MY_FANRUAN"
      />
    </Modal>
  );
};

export default PermissionManegeModal;
