import React, { CSSProperties } from 'react';
import { ReactComponent as LockIcon } from '@/static/images/enterpriseService/lock.svg';
import styles from '@/components/lockTriangle/index.less';

type LTProps = {
  style?: CSSProperties;
};

const LockTriangle: React.FC<LTProps> = ({ style }) => {
  return (
    <div className={styles.triangle} style={style}>
      <LockIcon style={{ marginLeft: 18, marginTop: 6 }} />
    </div>
  );
};

export default LockTriangle;
