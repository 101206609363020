import React from 'react';
import { Button } from 'antd';
import { ReactComponent as CustomServiceIcon } from '@/static/images/enterpriseService/customer_service.svg';
import { activateQidianDom } from '@/utils/qidian';
import { useRequest } from '@@/plugin-request/request';
import { postBuried } from '@/utils/requests/requests_utils';

type TCSProps = {
  text?: string;
  iconShow?: boolean;
  buriedPortal?: string;
  buriedClassification?: string;
};

const CustomServiceButton: React.FC<TCSProps> = ({
  text = '点我咨询',
  iconShow = true,
  buriedPortal,
  buriedClassification,
}) => {
  const { run: postBuriedRun } = useRequest(
    () => {
      return postBuried({
        portal: buriedPortal,
        menu: text,
        classification: buriedClassification,
      });
    },
    {
      manual: true,
    },
  );

  return (
    <Button
      onClick={() => {
        activateQidianDom();
        if (buriedPortal && buriedClassification) {
          postBuriedRun();
        }
      }}
      icon={iconShow ? <CustomServiceIcon /> : ''}
      style={{ padding: 0, display: 'flex', alignItems: 'center' }}
      type={'link'}
    >
      {text}
    </Button>
  );
};

export default CustomServiceButton;
