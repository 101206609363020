import type { Reducer } from 'umi';
import { TDragCard } from '@/types/common/index';

export interface UserModelType {
  namespace: 'drag';
  state: TDragCard;
  reducers: {
    save: Reducer<TDragCard>;
  };
}

const dragModal: UserModelType = {
  namespace: 'drag',
  state: {
    isEdit: false,
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
export default dragModal;
