import OSS from 'ali-oss';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

// 获取文件名和文件类型
export function getFileNameAndType(fileName: string) {
  const lastIndex = fileName.lastIndexOf('.');

  return {
    name: fileName.slice(0, lastIndex),
    type: fileName.slice(lastIndex + 1),
  };
}

// 上传至阿里云
export async function putObject(clientData: any, file: any, ossPath = '') {
  try {
    // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
    // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
    // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
    const fileType = getFileNameAndType(file.name);
    const client = new OSS(clientData);
    // @ts-ignore
    const uploadPath = `${ossPath}${uuidv4().myReplace('-', '')}.${
      fileType.type
    }`;
    const headers = {
      // 指定该Object被下载时网页的缓存行为。
      'Cache-Control': 'no-cache',
      // 指定Object的存储类型。
      'x-oss-storage-class': 'Standard',
      // 指定该Object被下载时的名称。
      'Content-Disposition': `attachment; filename=${encodeURIComponent(
        file.name,
      )}`,
      // 指定CopyObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
      'x-oss-forbid-overwrite': 'true',
    };
    const result = await client.put(uploadPath, file, { headers });

    return {
      name: file.name,
      path: uploadPath,
    };
  } catch (e) {
    console.log(e);

    return {
      name: null,
      path: null,
    };
  }
}

// @ts-ignore
String.prototype.myReplace = function (f: any, e: any) {
  // 把f替换成e
  const reg = new RegExp(f, 'g'); // 创建正则RegExp对象

  return this.replace(reg, e);
};

export function getFilePath(path: string | null | undefined) {
  if (path && path !== '') {
    let arr1 = path.split('?');
    if (arr1.length >= 1 && arr1[0] != '') {
      let arr2 = arr1[0].split(
        'https://finepassport.oss-cn-hangzhou.aliyuncs.com/',
      );
      if (arr2.length >= 2) {
        return arr2[1];
      }
    }
  }
  return path;
}
