import React, { useEffect, useRef, useState } from 'react';
import { useRequest } from '@@/plugin-request/request';
import Recommendation from '@/pages/personalService/components/recommendation';
import classNames from 'classnames';
import styles from './latestRecommendations.less';
import { Button, InfiniteScroll, Picker } from 'antd-mobile';
import { Space, Tag } from 'antd';
import { ComponentWrapper } from '@digit-fe/digit-component';
import { DownOutline } from 'antd-mobile-icons';
import {
  getAllContentTypes,
  getAllLabels,
  getPushRecords,
} from '@/utils/requests/requests_push';
import { ProductInformationItem } from '@/pages/productInformation/table';
import { ContentTypeItem } from '@/pages/productInformation/contentTypeManage/table';
import { LabelItem } from '@/pages/productInformation/productLabelManage/table';
import useGetUserInfo from '@/hooks/useGetUserInfo';

type TLRProps = { isWelComeFinished?: boolean };

const { CheckableTag } = Tag;

const LatestRecommendations: React.FC<TLRProps> = ({ isWelComeFinished }) => {
  const modalRef = useRef<any>();
  const newsRef = useRef<any>();
  // 标签选项卡相关
  const [recommendationsListTags, setRecommendationsListTags] = useState<
    string[]
  >(['全部推荐']);
  const [selectedTags, setSelectedTags] = useState<string[]>(['全部推荐']);
  const [tagsOptions, setTagsOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [labelId, setLabelId] = useState<string | undefined>(
    window.location.hash.split('tag=')[1],
  );
  const [mobilePickerVisible, setMobilePickerVisible] = useState(false);

  // 推荐列表相关
  const [recommendationsList, setRecommendationsList] =
    useState<Array<ProductInformationItem>>();
  const [current, setCurrent] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(1);
  const [labelClsOptions, setLabelClsOptions] = useState<
    { value: string; cls: string }[]
  >([]);
  const [currentUserInfo, setCurrentUserInfo] = useState<any>();
  const { nameEn } = useGetUserInfo();

  const getRecommendationsListRun = () =>
    getPushRecords({
      labelId,
      current: current,
      size: 10,
    }).then((res: any) => {
      setTotal(res.data.total);
      if (recommendationsList) {
        setRecommendationsList([...recommendationsList, ...res.data.records]);
      } else {
        setRecommendationsList([...res.data.records]);
      }
      setCurrent(current + 1);
      setHasMore(res.data.current < res.data.pages);
    });

  // 获取资讯所有标签
  const getAllLabel = useRequest(
    () => {
      return getAllLabels();
    },
    {
      onSuccess: (res) => {
        let arr = recommendationsListTags;
        let tags: any = [{ label: '全部推荐', value: 'all' }];
        res.forEach((element: ContentTypeItem) => {
          tags.push({ label: element.name, value: element.id });
          arr.push(element.name);
        });
        tags.find(
          (item: any) => item.value === window.location.hash.split('tag=')[1],
        )?.label
          ? setSelectedTags([
              tags.find(
                (item: any) =>
                  item.value === window.location.hash.split('tag=')[1],
              )?.label,
            ])
          : setSelectedTags(['全部推荐']);
        setRecommendationsListTags(arr);
        setTagsOptions(tags);
      },
    },
  );

  useEffect(() => {
    getAllLabel.run();
  }, []);

  const getAllContentType = useRequest(
    () => {
      return getAllContentTypes();
    },
    {
      onSuccess: (res) => {
        const cls = [
          'light_purple_tag',
          'light_green_tag',
          'blue_tag',
          'green_tag',
          'orange_tag',
          'purple_tag',
          'yellow_tag',
          'red_tag',
        ];

        setLabelClsOptions(
          res.map((item: LabelItem, index: number) => ({
            value: item.id,
            cls: cls[index % 8],
          })),
        );
      },
    },
  );

  useEffect(() => {
    getAllContentType.run();
  }, []);

  useEffect(() => {
    getRecommendationsListRun().then(() => {
      if (window.location.hash) {
        const newsScrollTop = document.getElementById('news')?.offsetTop;
        document.documentElement.scrollTop = Number(newsScrollTop) - 58;
      }
    });
  }, [labelId]);

  useEffect(() => {
    if (window.location.hash) {
      const newsScrollTop = document.getElementById('news')?.offsetTop;
      document.documentElement.scrollTop = Number(newsScrollTop) - 58;
    }
  }, [isWelComeFinished]);

  //const getCurrentUserInfoRequest = useRequest(
  //  () => {
  //    return getCurrentUserInfo({ type: 'MY_FANRUAN' });
  //  },
  //  {
  //    manual: true,
  //    onSuccess: (res) => {
  //      setCurrentUserInfo(res);
  //    },
  //    onError: (e) => {},
  //  },
  //);

  //useEffect(() => {
  //  nameEn && getCurrentUserInfoRequest.run();
  //}, [nameEn]);

  // 标签选项卡切换;
  const handleChange = (tag: string, checked: boolean) => {
    let preSelectedTags = selectedTags[0];
    let nextSelectedTags = checked
      ? [tag]
      : selectedTags.filter((t) => t !== tag);
    if (nextSelectedTags.length <= 0) {
      nextSelectedTags = [tag];
    }

    setSelectedTags(nextSelectedTags);
    if (nextSelectedTags[0] !== preSelectedTags[0]) {
      setRecommendationsList([]);
      setCurrent(1);
    }

    if (tag == '全部推荐') {
      setLabelId(undefined);
      window.location.hash = 'news';
    } else {
      const tagId = tagsOptions?.find((item) => item.label == tag)?.value;
      setLabelId(tagId);
      window.location.hash = `news?tag=${tagId}`;
    }
  };

  const scrollChange = () => {
    const newsScrollTop = document.getElementById('news')?.offsetTop;
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;

    if (newsScrollTop && scrollTop > newsScrollTop) {
      window.location.hash = labelId ? `news?tag=${labelId}` : 'news';
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollChange, true);
    scrollChange();
    return () => {
      window.removeEventListener('scroll', scrollChange, false);
    };
  }, []);

  return (
    <div
      className={classNames(styles.layout_vertical, styles.list_wrapper)}
      style={{ flex: 1, width: 0 }}
      ref={newsRef}
      id="news"
    >
      <ComponentWrapper>
        <div className={classNames(styles.layout_base_flex)}>
          {/*<div className={styles.module_title}>最新资讯</div>*/}
          {/*{(currentUserInfo?.admin || currentUserInfo?.authorized) && (*/}
          {/*  <div*/}
          {/*    className={styles.layout_vertical_center}*/}
          {/*    style={{ cursor: 'pointer' }}*/}
          {/*    onClick={() => {*/}
          {/*      history.push('/news/add');*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <SendOutlined style={{ fontSize: 16, color: '#0082fc' }} />*/}
          {/*    <span*/}
          {/*      className={classNames(*/}
          {/*        styles.color_0082fc,*/}
          {/*        styles.f14,*/}
          {/*        styles.lh24,*/}
          {/*        styles.mgl4,*/}
          {/*      )}*/}
          {/*    >*/}
          {/*      发布*/}
          {/*    </span>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </ComponentWrapper>
      <ComponentWrapper mode="notPC">
        <div className={styles.title_wrapper} style={{ marginBottom: '20px' }}>
          <div className={styles.module_title}>最新资讯</div>
          <Button
            color="primary"
            fill="none"
            onClick={() => {
              setMobilePickerVisible(true);
            }}
          >
            {selectedTags[0]} <DownOutline />
          </Button>
          <Picker
            columns={[tagsOptions]}
            visible={mobilePickerVisible}
            onClose={() => {
              setMobilePickerVisible(false);
            }}
            value={labelId ? [labelId] : ['all']}
            onConfirm={(v) => {
              setRecommendationsList([]);
              setCurrent(1);
              if (v[0] && v[0] !== 'all') {
                setLabelId(v[0]);
                setSelectedTags([
                  tagsOptions?.find((item) => item.value === v[0])
                    ?.label as string,
                ]);
                window.location.hash = `news?tag=${v[0]}`;
              } else {
                setLabelId(undefined);
                setSelectedTags(['全部推荐']);
                window.location.hash = 'news';
              }
            }}
          />
        </div>
      </ComponentWrapper>
      <ComponentWrapper>
        <div className={styles.mgb21}>
          <Space size={[0, 8]} wrap>
            {recommendationsListTags.map((tag) => (
              <CheckableTag
                key={tag}
                checked={selectedTags.includes(tag)}
                onChange={(checked) => handleChange(tag, checked)}
                style={{ fontSize: '14px' }}
              >
                {tag}
              </CheckableTag>
            ))}
          </Space>
        </div>
      </ComponentWrapper>
      {recommendationsList && recommendationsList.length > 0
        ? recommendationsList.map((item, index) => (
            <Recommendation
              item={item}
              key={index}
              labelCls={
                labelClsOptions?.find(
                  (elem) => elem.value == item?.contentType?.id,
                )?.cls
              }
            />
          ))
        : null}
      <InfiniteScroll loadMore={getRecommendationsListRun} hasMore={hasMore}>
        <InfiniteScrollContent hasMore={hasMore} />
      </InfiniteScroll>
    </div>
  );
};

const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
  return (
    <>
      {hasMore ? (
        <>
          <Recommendation />
        </>
      ) : (
        <span>--- 没有更多了 ---</span>
      )}
    </>
  );
};

export default LatestRecommendations;
