import React, { useEffect, useState } from 'react';
import { ConfigProvider, Table, Upload, message, Tooltip } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import type { ColumnsType } from 'antd/es/table';
import LabelInput from '../components/labelInput';
import LabelSwitch from '../components/labelSwitch';
import {
  addOrEditContentType,
  getContentTypeRecords,
} from '@/utils/requests/requests_push';
import { useRequest } from 'umi';
import dayjs from 'dayjs';
import {
  calFileUploadParams,
  getCoverBase64,
  getFileNameAndType,
  randomStr,
} from '@/utils';
import { RcFile } from 'antd/es/upload';
import axios from 'axios';
import styles from './table.less';
import { submitFile } from '@/utils/requests/requests_utils';

type Props = { keyword?: string; refresh: number };

export interface ContentTypeItem {
  enabled: boolean;
  id: string;
  lastModifiedTime: string;
  name: string;
  pic: string;
}

const ContentTypeManageTable: React.FC<Props> = ({ keyword, refresh }) => {
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(10);
  const [pages, setPages] = useState(0);
  const [data, setData] = useState<any>([]);
  const [inputOpen, setInputOpen] = useState(false);
  const [coverImage, setCoverImage] = useState<any>();
  const [pic, setPic] = useState<string | undefined>();

  const getContentTypeRecordsRequest = useRequest(
    (keyword, current, size) => {
      return getContentTypeRecords({
        keyword,
        current,
        size,
      });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setData(res.records);
        setTotal(res.total);
        setPages(res.pages);
        setLoading(false);
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    getContentTypeRecordsRequest.run(keyword, current, size);
  }, [keyword, current, size, refresh]);

  const submitFileRequest = useRequest(
    (fileName, contentType) => {
      return submitFile({ path: 'myfr', fileName, contentType });
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  const addOrEditContentTypeRequest = useRequest(
    (value) => {
      return addOrEditContentType(JSON.stringify(value));
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  const columns: ColumnsType<ContentTypeItem> = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 70,
      render: (_value, _record, index) => `${index + 1}`,
    },
    {
      title: '图片',
      dataIndex: 'pic',
      key: 'pic',
      width: 65,
      render: (value, record) => (
        <div className={styles.content_type_pic}>
          <Upload
            name="avatar"
            listType="picture-card"
            accept={'.png'}
            fileList={[
              {
                uid: randomStr(7),
                name: value,
                url: value,
                status: 'done',
              },
            ]}
            showUploadList={false}
            customRequest={(info) => {
              let fileInfo = getFileNameAndType((info.file as RcFile).name);
              let uploadParams = calFileUploadParams(fileInfo.type, 'myfr');
              submitFileRequest
                .run(uploadParams.fileName, (info.file as RcFile).type)
                .then((res) => {
                  axios.request({
                    method: 'PUT',
                    url: res.signedUrl,
                    withCredentials: false,
                    headers:
                      Object.assign(res.actualSignedRequestHeaders, {
                        'Content-Disposition': `attachment; filename=${encodeURIComponent(
                          (info.file as RcFile).name,
                        )}`,
                      }) || {},
                    data: info.file,
                  });
                })
                .then(() => {
                  addOrEditContentTypeRequest
                    .run({
                      id: record.id,
                      pic: `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`,
                      name: record.name,
                      enabled: record.enabled,
                    })
                    .then(() => {
                      getContentTypeRecordsRequest.run(keyword, current, size);
                    });
                });
            }}
            beforeUpload={(file: RcFile) => {
              const isJpgOrPng = file.type === 'image/png';
              if (!isJpgOrPng) {
                message.error('只支持.png格式!');
              }
              const isLt2M = file.size / 1024 / 1024 < 1;
              if (!isLt2M) {
                message.error('大小不超过1MB');
              }
              return isJpgOrPng && isLt2M;
            }}
          >
            <Tooltip title="重新上传">
              <img
                src={value}
                style={{ width: '44px', height: '44px', borderRadius: '4px' }}
              />
            </Tooltip>
          </Upload>
        </div>
      ),
    },
    {
      title: '内容类型',
      dataIndex: 'name',
      key: 'name ',
      width: 710,
      render: (value, record) => (
        <LabelInput
          type="contentType"
          name={value}
          id={record.id}
          enabled={record.enabled}
          pic={record.pic}
        />
      ),
    },
    {
      title: '状态',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 160,
      render: (value, record) => (
        <LabelSwitch
          type="contentType"
          name={record.name}
          id={record.id}
          enabled={value}
        />
      ),
    },
    {
      title: '更新时间',
      key: 'lastModifiedTime',
      dataIndex: 'lastModifiedTime',
      width: 200,
      render: (value) =>
        value ? `${dayjs(value).format('YYYY-MM-DD HH:mm:ss')}` : '--',
    },
  ];

  return (
    <>
      <ConfigProvider locale={zhCN}>
        <Table
          columns={columns}
          dataSource={data}
          onChange={(pagination) => {
            pagination.current && setCurrent(pagination.current);
            pagination.pageSize && setSize(pagination.pageSize);
          }}
          loading={loading}
          pagination={{
            total: total,
            pageSize: size,
            current: current,
            hideOnSinglePage: true,
            showQuickJumper: true,
          }}
          style={{
            width: '1152px',
            marginTop: '16px',
            color: '#333333',
          }}
        />
      </ConfigProvider>
    </>
  );
};

export default ContentTypeManageTable;
