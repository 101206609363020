import React, { useEffect, useState } from 'react';
import { Modal, message, Form, Input, Button, Upload, Switch } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import SelectIcon from '@/components/selectIcon/selectIcon';
import { ProductItem } from './detailsTable';
import { useRequest } from 'umi';
import { addOrEditProductContent } from '@/utils/requests/requests_resource';
import { submitFile } from '@/utils/requests/requests_utils';
import { RcFile, UploadFile } from 'antd/es/upload';
import { calFileUploadParams, getFileNameAndType, randomStr } from '@/utils';
import axios from 'axios';

type Props = {
  fileModalOpen: boolean;
  setFileModalOpen: Function;
  modeTitle?: string;
  okText?: string;
  messageTip?: string;
  details?: ProductItem;
  id: string;
  num: number;
  setRefresh: Function;
  refresh: number;
};

const AddOrEditFileModal: React.FC<Props> = ({
  fileModalOpen,
  setFileModalOpen,
  modeTitle = '添加文件',
  okText = '确认',
  messageTip = '添加成功',
  details = undefined,
  id,
  num,
  setRefresh,
  refresh,
}) => {
  const [form] = Form.useForm();
  const [icon, setIcon] = useState(
    'https://hwobs.fanruan.com/ui/home/icons/logo1.png',
  );
  const [contentId, setContentId] = useState<string | undefined>();
  const [title, setTitle] = useState<string | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [url, setUrl] = useState<string | undefined>();
  const [index, setIndex] = useState<number | undefined>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    form.setFieldsValue({
      url: details?.url,
      title: details?.title,
      icon: details?.icon
        ? details?.icon
        : 'https://hwobs.fanruan.com/ui/home/icons/logo1.png',
    });
    details &&
      setFileList([
        {
          uid: randomStr(7),
          name: details?.filename ? details?.filename : 'undefined',
          url: details?.url,
          status: 'done',
        },
      ]);
    setContentId(details?.id);
    setTitle(details?.title);
    setIcon(
      details?.icon
        ? details?.icon
        : 'https://hwobs.fanruan.com/ui/home/icons/logo1.png',
    );
    setIndex(details?.index ? details?.index : num);
  }, [details]);

  const addOrEditProductContentRequest = useRequest(
    () => {
      return addOrEditProductContent(
        id,
        JSON.stringify({
          id: contentId,
          title,
          icon,
          type: 1,
          index,
          url,
          filename: name,
        }),
      );
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  const submitFileRequest = useRequest(
    (fileName, contentType) => {
      return submitFile({ path: 'myfr', fileName, contentType });
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  return (
    <Modal
      open={fileModalOpen}
      title={modeTitle}
      width={664}
      destroyOnClose={true}
      okText={okText}
      onOk={() => {
        form.submit();
      }}
      cancelText="取消"
      onCancel={() => {
        setFileModalOpen(false);
      }}
    >
      <Form
        name="file"
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        initialValues={{}}
        onFinish={() => {
          addOrEditProductContentRequest.run().then(() => {
            setFileModalOpen(false);
            setRefresh(refresh + 1);
            message.success(messageTip);
          });
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <Form.Item
          label="标题"
          name="title"
          rules={[{ required: true, message: '请输入标题' }]}
        >
          <Input
            placeholder="请输入..."
            showCount
            maxLength={20}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="url"
          label="上传文件"
          extra="支持上传文件大小不超过 100 MB 的文件，支持格式：doc/docx/xls/xlsx/ppt/pptx/pdf/jpg/png/gif/zip/rar"
          rules={[{ required: true, message: '请上传文件' }]}
        >
          <Upload
            name="file"
            fileList={fileList}
            maxCount={1}
            customRequest={(info) => {
              let fileInfo = getFileNameAndType((info.file as RcFile).name);
              let uploadParams = calFileUploadParams(fileInfo.type, 'myfr');
              submitFileRequest
                .run(uploadParams.fileName, (info.file as RcFile).type)
                .then((res) => {
                  axios
                    .request({
                      method: 'PUT',
                      url: res.signedUrl,
                      withCredentials: false,
                      headers:
                        Object.assign(res.actualSignedRequestHeaders, {
                          'Content-Disposition': `attachment; filename=${encodeURIComponent(
                            (info.file as RcFile).name,
                          )}`,
                        }) || {},
                      data: info.file,
                    })
                    .then(() => {
                      setName((info.file as RcFile).name);
                      setUrl(
                        `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`,
                      );
                      setFileList([
                        {
                          uid: randomStr(7),
                          name: (info.file as RcFile)?.name,
                          url: `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`,
                          status: 'done',
                        },
                      ]);
                    });
                });
            }}
            beforeUpload={(file: RcFile) => {
              const isLt2M = file.size / 1024 / 1024 < 100;
              if (!isLt2M) {
                message.error('大小不超过100MB');
              }
              return isLt2M;
            }}
            onRemove={() => {
              setFileList([]);
            }}
          >
            <Button icon={<UploadOutlined />}>点击上传</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="icon"
          label=" 设置图标"
          rules={[{ required: true, message: '请选择图标' }]}
        >
          <img src={icon} style={{ width: '50px', height: '50px' }} />
          <div style={{ color: '#C5C7CE', marginBottom: '5px' }}>
            预制图标：
          </div>
          <SelectIcon iconSrc={icon} setIconSrc={setIcon} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOrEditFileModal;
