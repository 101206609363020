import React, { useEffect, useState } from 'react';
import styles from './item.less';
import { Col, Row, Tooltip } from 'antd';
import { productManageItem } from './pageConfiguration';
import { Link, useModel, useRequest } from 'umi';
import {
  getProductDetails,
  getProductDetailsList,
} from '@/utils/requests/requests_resource';
import { ProductItem } from './pageConfiguration/detailsTable';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ComponentWrapper } from '@digit-fe/digit-component';
import { isSuperAdmin } from '@/utils/index';
import { postBuried } from '@/utils/requests/requests_utils';

type Props = {
  id: string;
  name: string;
  index: number;
  details: productManageItem;
};

const ResourceItem: React.FC<Props> = ({ id, name, index, details }) => {
  const [dataSource, setDataSource] = useState<ProductItem[]>([]);
  const [isManager, setIsManager] = useState(false);
  const { initialState } = useModel('@@initialState');

  const getProductDetailsListRequest = useRequest(
    () => {
      return getProductDetailsList({ id });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setDataSource(
          res.map((item: ProductItem) => Object.assign(item, { key: item.id })),
        );
      },
      onError: (e) => {},
    },
  );

  const getProductDetailsRequest = useRequest(
    () => {
      return getProductDetails({ id });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setIsManager(
          isSuperAdmin(initialState?.uid) ||
            res.managerList.findIndex(
              (item: { nameEn: string }) => item.nameEn === initialState.nameEn,
            ) > -1,
        );
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    id && getProductDetailsListRequest.run();
  }, [id]);

  useEffect(() => {
    id && getProductDetailsRequest.run();
  }, [id]);

  const postBuriedRequest = useRequest(
    (menu: string) => {
      return postBuried({ portal: '产品资料', menu, classification: name });
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  return (
    <>
      <ComponentWrapper>
        <div
          className={styles.product_resource_item}
          style={{ marginTop: index > 0 ? 24 : 0 }}
          id={id}
        >
          <Row>
            <Col span={22} style={{ display: 'flex' }}>
              <img className={styles.product_logo} src={details?.logo} />
              <div style={{ flex: 1 }}>
                <div className={styles.product_title}>{details.title}</div>
                <div className={styles.product_description}>
                  {details.brief}
                </div>
              </div>
            </Col>
            <Col
              span={2}
              style={{
                textAlign: 'right',
              }}
            >
              {isManager && (
                <Link
                  to={`/resource/manage?id=${id}`}
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    color: '#0082FC',
                    cursor: 'pointer',
                  }}
                >
                  配置
                  <img
                    className={styles.setting_icon}
                    src={require('@/static/images/setting_outlined.png')}
                  />
                </Link>
              )}
            </Col>
          </Row>
          <Row
            gutter={[0, 8]}
            style={{ marginTop: dataSource.length > 0 ? 26 : 0 }}
          >
            {dataSource.map((item) => (
              <Col span={6} key={item.id}>
                <a
                  className={styles.product_resource_content_item}
                  href={item.url}
                  target={item.type === 1 ? '_self' : '_blank'}
                  onClick={() => {
                    postBuriedRequest.run(item.title);
                  }}
                >
                  <img
                    src={item.icon}
                    style={{ width: 28, height: 28, marginRight: 8 }}
                  />
                  <div style={{ flex: 1, lineHeight: '28px' }}>
                    {item.title}
                    <ArrowRightOutlined style={{ marginLeft: '8px' }} />
                  </div>
                </a>
              </Col>
            ))}
          </Row>
        </div>
      </ComponentWrapper>
      <ComponentWrapper mode="notPC">
        <div
          className={styles.nopc_product_resource_item}
          style={{ marginTop: index > 0 ? 24 : 0 }}
          id={id}
        >
          <div style={{ display: 'flex' }}>
            <img className={styles.product_logo} src={details?.logo} />
            <div style={{ flex: 1 }}>
              <div className={styles.mobile_product_title}>{details.title}</div>
              <div className={styles.mobile_product_description}>
                <Tooltip title={details.brief}>{details.brief}</Tooltip>
              </div>
            </div>
          </div>
          <Row
            gutter={[0, 8]}
            style={{ marginTop: dataSource.length > 0 ? 26 : 0 }}
          >
            {dataSource.map((item) => (
              <Col span={24} key={item.id}>
                <a
                  className={styles.mobile_product_resource_content_item}
                  href={item.url}
                  target={item.type === 1 ? '_self' : '_blank'}
                  onClick={() => {
                    postBuriedRequest.run(item.title);
                  }}
                >
                  <img
                    src={item.icon}
                    style={{ width: 28, height: 28, marginRight: 8 }}
                  />
                  <div style={{ flex: 1, lineHeight: '28px' }}>
                    {item.title}
                    <ArrowRightOutlined style={{ marginLeft: '8px' }} />
                  </div>
                </a>
              </Col>
            ))}
          </Row>
        </div>
      </ComponentWrapper>
    </>
  );
};

export default ResourceItem;
