import React from 'react';
import styles from './index.less';
import { useModel } from '@@/plugin-model/useModel';
import { EEnterpriseAuth } from '@/types/enterpriseService';
import moment from 'moment';
import { Col, Modal, Row, Space } from 'antd';
import MiddleServiceCard, {
  MiddleServiceCardProps,
} from '@/components/middleServiceCard';
import { ArrowRightOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import InfoHeader from '@/pages/enterpriseService/components/serviceInfoModule/infoHeader';
import { ComponentWrapper } from '@digit-fe/digit-component';
import { useMediaQuery } from 'react-responsive';
import { Dialog } from 'antd-mobile';
import { useRequest } from '@@/plugin-request/request';
import { postBuried } from '@/utils/requests/requests_utils';

type TSIMProps = {};

const availableAuthIcon = [EEnterpriseAuth.AVAILABLE, EEnterpriseAuth.LIMITED];

const ServiceInfoModule: React.FC<TSIMProps> = () => {
  const iconPath = process.env.ICON_PATH;
  const isNotPC = useMediaQuery({ query: '(max-width: 1199.99px)' });

  const { enterpriseAuth, ticketAuth } = useModel(
    'useEnterpriseModel',
    (model) => ({
      enterpriseAuth: model.enterpriseAuth,
      ticketAuth: model.ticketAuth,
    }),
  );

  // 埋点
  const { run: postBuriedRun } = useRequest(
    (classification: string, menu: string) => {
      return postBuried({
        portal: '服务平台',
        classification,
        menu,
      });
    },
    {
      manual: true,
    },
  );

  const noAuthWarning = () => {
    if (isNotPC) {
      Dialog.alert({
        title: '提示',
        content: (
          <div style={{ textAlign: 'center' }}>
            此服务仅对帆软帆软合作/试用中客户开放，请参照页面上方的引导来获取服务
          </div>
        ),
        confirmText: '我知道了',
        onClose: () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
      });
    } else {
      const { info } = Modal;
      info({
        title: '此服务仅对帆软帆软合作/试用中客户开放',
        icon: <ExclamationCircleFilled />,
        content: '请参照页面上方的引导来获取服务',
        okText: '我知道了',
        onOk: () => {
          scrollTo({ top: 0, behavior: 'smooth' });
        },
      });
    }
  };

  // 服务报告描述
  const serviceReportSubTitleAuth: {
    [key in EEnterpriseAuth]: () => void;
  } = {
    [EEnterpriseAuth.UNAVAILABLE]: () => '服务报告查询与服务结果评价',
    [EEnterpriseAuth.AVAILABLE]: () => (
      <span style={{ color: '#f7ba1e' }}>{`${moment()
        .subtract(1, 'months')
        .endOf('month')
        .format('YYYY年MM月')}服务月报已更新`}</span>
    ),
    [EEnterpriseAuth.LIMITED]: () => '服务报告查询与服务结果评价',
  };

  // 服务模块卡片
  const serviceModules: MiddleServiceCardProps[] = [
    {
      title: <CardTitle title={'在线支持'} />,
      subTitle: '工作日9:00-12:00，13:30-17:30在线',
      icon: `${iconPath}/online_support.png`,
      unlock: true,
      onClick: () => {
        window.location.href = `${process.env.SERVICE_URL}/support`;
        postBuriedRun('技术支持服务', '在线支持');
      },
    },
    {
      title: <CardTitle title={'进度查询'} />,
      subTitle: '查询提交的工单、需求、问题处理进度',
      icon: availableAuthIcon.includes(enterpriseAuth)
        ? `${iconPath}/progress_query.png`
        : `${iconPath}/progress_query_no_auth.png`,
      unlock: availableAuthIcon.includes(enterpriseAuth),
      onClick: () => {
        availableAuthIcon.includes(enterpriseAuth)
          ? (window.location.href = `${process.env.SERVICE_URL}/ticket`)
          : noAuthWarning();
        postBuriedRun('技术支持服务', '进度查询');
      },
    },
    {
      title: <CardTitle title={'服务报告'} />,
      subTitle: serviceReportSubTitleAuth[enterpriseAuth](),
      icon: availableAuthIcon.includes(enterpriseAuth)
        ? `${iconPath}/service_report.png`
        : `${iconPath}/service_report_no_auth.png`,
      unlock: availableAuthIcon.includes(enterpriseAuth),
      onClick: () => {
        availableAuthIcon.includes(enterpriseAuth)
          ? (window.location.href = `${process.env.SERVICE_URL}/evaluation`)
          : noAuthWarning();
        postBuriedRun('技术支持服务', '服务报告');
      },
    },
    {
      title: <CardTitle title={'提交工单'} arrowShow={ticketAuth} />,
      subTitle: ticketAuth
        ? '提交产品问题、需求、BUG'
        : '功能正在建设中，暂不开放',
      icon: ticketAuth
        ? `${iconPath}/submit_ticket.png`
        : `${iconPath}/submit_ticket_no_auth.png`,
      unlock: ticketAuth,
      onClick: () => {
        ticketAuth
          ? (window.location.href = `${process.env.SERVICE_URL}/ticket_submit`)
          : noAuthWarning();
        postBuriedRun('技术支持服务', '提交工单');
      },
      isSpecial: !ticketAuth,
    },
  ];

  return (
    <div className={styles.module_wrapper}>
      <InfoHeader />
      <Row gutter={[12, 10]}>
        {serviceModules.map((item, index) => (
          <Col key={index} span={12}>
            <MiddleServiceCard item={item} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ServiceInfoModule;

const CardTitle: React.FC<{ title: string; arrowShow?: boolean }> = ({
  title,
  arrowShow = true,
}) => {
  return (
    <>
      <ComponentWrapper mode={'pc'}>
        <Space size={12} className={styles.f16}>
          {title}
          {arrowShow ? <ArrowRightOutlined /> : null}
        </Space>
      </ComponentWrapper>
      <ComponentWrapper mode={'notPC'}>
        <div>{title}</div>
      </ComponentWrapper>
    </>
  );
};
