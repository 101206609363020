import React, { useEffect, useRef, useState } from 'react';
import { ComponentWrapper } from '@digit-fe/digit-component';
import styles from './index.less';
import { ArrowRightOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import LockTriangle from '@/components/lockTriangle';
import { Modal } from 'antd';
import { Dialog, Popup } from 'antd-mobile';
import { useModel, useRequest } from 'umi';
import { getExclusiveCustomerService } from '@/utils/requests/requests_enterprise';
import { EEnterpriseStatus } from '@/types/enterpriseService';
import ContactModal, {
  IContactPersonItem,
} from '@/pages/enterpriseService/components/contactFR/contactModal';
import { useMediaQuery } from 'react-responsive';
import { postBuried } from '@/utils/requests/requests_utils';

export interface IServiceCardItem {
  title?: string;
  icon?: string;
  description?: string;
  href?: string;
  isLock?: boolean;
  isVVip?: boolean;
}

type Props = {
  item: IServiceCardItem;
};

const vvip_service = [
  {
    text: (
      <div>
        7*24小时
        <br />
        企业群支持
      </div>
    ),
    icon: require('@/static/images/enterpriseService/enterprise_group_support.png'),
  },
  {
    text: (
      <div>
        专属
        <br />
        客户服务经理
      </div>
    ),
    icon: require('@/static/images/enterpriseService/dedicated_customer_service_manager.png'),
  },
  {
    text: (
      <div>
        ≤10分钟
        <br />
        严重故障响应备份
      </div>
    ),
    icon: require('@/static/images/enterpriseService/critical_failure_response_backup.png'),
  },
  {
    text: (
      <div>
        SLA
        <br />
        协议承诺备份
      </div>
    ),
    icon: require('@/static/images/enterpriseService/SLA_agreement_commitment.png'),
  },
  {
    text: (
      <div>
        资深技术专家 <br /> 处理故障
      </div>
    ),
    icon: require('@/static/images/enterpriseService/handle_failure.png'),
  },
  {
    text: (
      <div>
        每年4次
        <br />
        巡检服务
      </div>
    ),
    icon: require('@/static/images/enterpriseService/inspection_service.png'),
  },
  {
    text: (
      <div>
        定期
        <br />
        服务报告
      </div>
    ),
    icon: require('@/static/images/enterpriseService/service_report.png'),
  },
  {
    text: (
      <div>
        主动 <br /> 安全支持
      </div>
    ),
    icon: require('@/static/images/enterpriseService/security_support.png'),
  },
];

const vvip_service_mobile = [
  {
    text: '7*24小时企业群支持',
    icon: require('@/static/images/enterpriseService/enterprise_group_support.png'),
  },
  {
    text: '专属客户服务经理',
    icon: require('@/static/images/enterpriseService/dedicated_customer_service_manager.png'),
  },
  {
    text: '≤10分钟严重故障响应备份',
    icon: require('@/static/images/enterpriseService/critical_failure_response_backup.png'),
  },
  {
    text: ' SLA协议承诺备份',
    icon: require('@/static/images/enterpriseService/SLA_agreement_commitment.png'),
  },
  {
    text: ' 资深技术专家处理故障',
    icon: require('@/static/images/enterpriseService/handle_failure.png'),
  },
  {
    text: '每年4次巡检服务',
    icon: require('@/static/images/enterpriseService/inspection_service.png'),
  },
  {
    text: '定期服务报告',
    icon: require('@/static/images/enterpriseService/service_report.png'),
  },
  {
    text: '主动安全支持',
    icon: require('@/static/images/enterpriseService/security_support.png'),
  },
];

const ServiceCard: React.FC<Props> = ({ item }) => {
  const { enterpriseStatus, enterpriseInfo } = useModel(
    'useEnterpriseModel',
    (model) => ({
      enterpriseStatus: model.enterpriseStatus,
      enterpriseInfo: model.enterpriseInfo,
    }),
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [specialCustomer, setSpecialCustomer] = useState<IContactPersonItem[]>(
    [],
  );

  const [isPermiumServiceInEffect, setIsPermiumServiceInEffect] =
    useState(false);

  const contactRef = useRef(null);

  const isNotPC = useMediaQuery({ query: '(max-width: 1199.99px)' });

  // 埋点
  const { run: postBuriedRun } = useRequest(
    (menu?: string) => {
      return postBuried({
        portal: '服务平台',
        classification: '更多服务',
        menu,
      });
    },
    {
      manual: true,
    },
  );

  const noAuthWarning = () => {
    if (isNotPC) {
      Dialog.alert({
        title: '提示',
        content: (
          <div style={{ textAlign: 'center' }}>
            此服务仅对帆软帆软合作/试用中客户开放，请参照页面上方的引导来获取服务
          </div>
        ),
        confirmText: '我知道了',
        onClose: () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
      });
    } else {
      const { info } = Modal;
      info({
        title: '此服务仅对帆软帆软合作/试用中客户开放',
        icon: <ExclamationCircleFilled />,
        content: '请参照页面上方的引导来获取服务',
        okText: '我知道了',
        onOk: () => {
          scrollTo({ top: 0, behavior: 'smooth' });
        },
      });
    }
  };

  useEffect(() => {
    setIsPermiumServiceInEffect(
      enterpriseStatus === EEnterpriseStatus.PREMIUM_SERVICE_IN_EFFECT,
    );
  }, [enterpriseStatus]);

  const getExclusiveCustomerServiceRequest = useRequest(
    () => {
      return getExclusiveCustomerService();
    },
    {
      manual: true,
      onSuccess: (res) => {
        setSpecialCustomer(
          res.map((item: SpecialCustomer) => ({
            icon: `${process.env.ICON_PATH}/vvip_logo.png`,
            tag: item.productLine,
            person: [{ phone: item.mobile, name: item.specialist }],
          })),
        );
      },
      onError: (e) => {},
    },
  );

  // useEffect(() => {
  //   enterpriseStatus === EEnterpriseStatus.PREMIUM_SERVICE_IN_EFFECT &&
  //     getExclusiveCustomerServiceRequest.run();
  // }, []);

  return (
    <>
      <ComponentWrapper mode={'pc'}>
        <>
          {item.title ? (
            <div
              onClick={() => {
                if (item.isVVip) {
                  !isPermiumServiceInEffect && setIsModalOpen(true);
                  if (
                    enterpriseStatus ===
                    EEnterpriseStatus.PREMIUM_SERVICE_IN_EFFECT
                  ) {
                    getExclusiveCustomerServiceRequest.run().then(() => {
                      if (isPermiumServiceInEffect) {
                        // @ts-ignore
                        contactRef.current.openModal();
                      }
                    });
                  }
                } else {
                  item.isLock && noAuthWarning();
                  if (!item.isLock && item.href) {
                    window.location.href = item.href;
                  }
                }
                postBuriedRun(item.title);
              }}
            >
              <div className={styles.service_card}>
                <img className={styles.icon} src={item.icon} />
                <div className={styles.service_card_content}>
                  <div className={styles.service_card_title}>
                    {item.title}
                    {!item.isVVip && (
                      <ArrowRightOutlined style={{ marginLeft: '10px' }} />
                    )}
                  </div>
                  <div className={styles.service_card_description}>
                    {item.description}
                  </div>
                </div>
                {item.isLock && (
                  <LockTriangle style={{ marginTop: 1, marginRight: 1 }} />
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className={styles.service_card_more}>
                <div>更多服务敬请期待</div>
                <div
                  style={{ marginTop: '8px', color: 'rgba(0,130,252,0.20)' }}
                >
                  Coming soon
                </div>
              </div>
            </div>
          )}
          <Modal
            title="开通企业高级售后服务"
            open={isModalOpen}
            onOk={() => {
              setIsModalOpen(false);
            }}
            onCancel={() => {
              setIsModalOpen(false);
            }}
            wrapClassName={styles.vvip_modal}
            footer={
              <a
                href={`tel:${
                  enterpriseInfo?.successMan?.[0]
                    ? `${enterpriseInfo.successMan[0].name} ${enterpriseInfo.successMan[0].phone}`
                    : '17766340506'
                }`}
              >
                <div className={styles.vvip_modal_footer}>
                  立即咨询{' '}
                  {enterpriseInfo?.successMan?.[0]
                    ? `${enterpriseInfo.successMan[0].name} ${enterpriseInfo.successMan[0].phone}`
                    : '许亦圻 17766340506'}
                </div>
              </a>
            }
          >
            <div className={styles.vvip_tip}>
              <div>高级售后服务专属权益</div>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  window.open(
                    'https://bbs.fanruan.com/forum.php?mod=viewthread&tid=143251&page=&extra=#pid751558',
                  )
                }
              >
                详细了解
              </div>
            </div>
            <div className={styles.vvip_services} style={{ marginTop: '-4px' }}>
              {vvip_service.map((item) => (
                <div className={styles.vvip_item} style={{ marginTop: '16px' }}>
                  <img
                    src={item.icon}
                    style={{ height: '48px', width: '48px' }}
                  />
                  <div>{item.text}</div>
                </div>
              ))}
            </div>
          </Modal>
          <ContactModal ref={contactRef} list={specialCustomer} />
        </>
      </ComponentWrapper>
      <ComponentWrapper mode="notPC">
        {item.title ? (
          <>
            <div
              style={{ position: 'relative' }}
              onClick={() => {
                if (item.isVVip) {
                  !isPermiumServiceInEffect && setIsPopupOpen(true);
                  // @ts-ignore
                  isPermiumServiceInEffect && contactRef.current.openModal();
                } else {
                  item.isLock && noAuthWarning();
                  if (!item.isLock && item.href) {
                    window.location.href = item.href;
                  }
                }
                postBuriedRun(item.title);
              }}
            >
              <div className={styles.service_card_mobile}>
                <img className={styles.icon_mobile} src={item.icon} />
                <div className={styles.service_card_title_mobile}>
                  {item.title}
                </div>
              </div>
            </div>
            <Popup
              visible={isPopupOpen}
              showCloseButton
              onMaskClick={() => {
                setIsPopupOpen(false);
              }}
              onClose={() => {
                setIsPopupOpen(false);
              }}
              bodyStyle={{
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                minHeight: '420px',
                maxHeight: '696px',
              }}
              className={styles.vvip_popup}
            >
              <div className={styles.vvip_popup_title}>
                开通企业高级售后服务
              </div>
              <div className={styles.vvip_popup_tip}>
                <div>高级售后服务专属权益</div>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(
                      'https://bbs.fanruan.com/forum.php?mod=viewthread&tid=143251&page=&extra=#pid751558',
                    )
                  }
                >
                  详细了解
                </div>
              </div>
              <div
                className={styles.vvip_popup_services}
                style={{ marginTop: '-4px' }}
              >
                {vvip_service_mobile.map((item) => (
                  <div
                    className={styles.vvip_popup_item}
                    style={{ marginTop: '16px' }}
                  >
                    <img
                      src={item.icon}
                      style={{ height: '48px', width: '48px' }}
                    />
                    <div style={{ marginTop: '8px' }}>{item.text}</div>
                  </div>
                ))}
              </div>

              <a
                href={`tel:${
                  enterpriseInfo?.successMan?.[0]
                    ? `${enterpriseInfo.successMan[0].name} ${enterpriseInfo.successMan[0].phone}`
                    : '17766340506'
                }`}
              >
                <div className={styles.vvip_popup_footer}>
                  立即咨询{' '}
                  {enterpriseInfo?.successMan?.[0]
                    ? `${enterpriseInfo.successMan[0].name} ${enterpriseInfo.successMan[0].phone}`
                    : '许亦圻 17766340506'}
                </div>
              </a>
            </Popup>
          </>
        ) : (
          <div>
            <div className={styles.service_card_more_mobile}>
              <div>更多服务敬请期待</div>
            </div>
          </div>
        )}
      </ComponentWrapper>
    </>
  );
};

export default ServiceCard;

export interface SpecialCustomer {
  productLine: string;
  specialist: string;
  mobile: string;
}
