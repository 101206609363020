import { request } from '@@/plugin-request/request';
import { loginCas } from '@/utils/index';
import { message } from 'antd';

// 异常处理
const errorHandler = (error: any) => {
  const { request, response, data } = error;
  const currentLocation = window.location.href;
  // 因为允许用户不登录也能查看，所以获取基础信息的接口不应该有报错提示
  if (
    request.url.indexOf('/apply/user/info') === -1 ||
    currentLocation.indexOf('/internal') > -1 ||
    currentLocation.indexOf('/addMembers') > -1 ||
    currentLocation.indexOf('/news') > -1 ||
    currentLocation.indexOf('/resource/manage') > -1
  ) {
    if (response.status === 401) {
      loginCas({
        env: process.env.UMI_ENV === 'product' ? 'prod' : 'test',
      });
    } else if (data) {
      message.error(data.message);
    } else if (!data) {
      message.error('未获取到响应内容');
    }

    throw error;
  }

  return data;
};

// GET 请求 数据格式：json
export const requestGet = async (api: string, params: object) => {
  return request(api, {
    method: 'get',
    params: params,
    timeoutMessage: '请求超时',
    errorHandler,
  });
};

// POST 请求 数据格式：json
export const requestPost = async (api: string, params: object) => {
  return request(api, {
    method: 'post',
    data: params,
    timeoutMessage: '请求超时',
    errorHandler,
  });
};

// POST 请求 数据格式：json
export const requestPostJson = async (api: string, params: object) => {
  return request(api, {
    headers: {
      'content-type': 'application/json',
    },
    method: 'post',
    data: params,
    timeoutMessage: '请求超时',
    errorHandler,
  });
};

// POST 请求 不含错误处理
export const requestPostWithOutErrorHandler = async (
  api: string,
  params: object,
) => {
  return request(api, {
    method: 'post',
    data: params,
    timeoutMessage: '请求超时',
  });
};

// POST 请求 数据格式：表单
export const requestPostFormData = async (api: string, params: object) => {
  return request(api, {
    method: 'post',
    data: params,
    timeoutMessage: '请求超时',
    requestType: 'form',
    errorHandler,
  });
};

export const requestPut = async (api: string, params: any) => {
  return request(api, {
    method: 'put',
    data: params,
    timeoutMessage: '请求超时',
    errorHandler,
  });
};

export const requestPutJson = async (api: string, params: any) => {
  return request(api, {
    headers: {
      'content-type': 'application/json',
    },
    method: 'put',
    data: params,
    timeoutMessage: '请求超时',
    errorHandler,
  });
};

export const requestDelete = async (api: string, params: any) => {
  return request(api, {
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    method: 'delete',
    data: params,
    timeoutMessage: '请求超时',
    errorHandler,
  });
};
