import React, { useContext } from 'react';
import styles from '@/components/dragCard/index.less';
import { Button, Modal, Space, Tooltip } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import useGetDragInfo from '@/hooks/useGetDragInfo';
import { useDispatch } from 'umi';
import { DragCardContext } from '@/context/dragContext';

type TDragCardButtons = {
  onCancel: () => void;
  onStartEdit: () => void; // 进入编辑模式
  onSaveEdit: () => void; // 保存
};

const MAX_CARD_NUMBER = 100; // 最多允许100个卡片

const DragCardButtons: React.FC<TDragCardButtons> = ({
  onCancel,
  onStartEdit,
  onSaveEdit,
}) => {
  const { isEdit } = useGetDragInfo();
  const dispatch = useDispatch();
  const DragCardContextProps = useContext(DragCardContext);
  const { cardNumber, onCardChange } = DragCardContextProps;
  return (
    <div className={styles.button}>
      {isEdit ? (
        <Space>
          {cardNumber >= MAX_CARD_NUMBER ? (
            <Tooltip title="卡片数量已达上限无法新增">
              <Button disabled type={'link'} onClick={() => {}}>
                <PlusCircleOutlined style={{ fontSize: '16px' }} />
                <span>新增卡片</span>
              </Button>
            </Tooltip>
          ) : (
            <Button
              type={'link'}
              onClick={() => {
                onCardChange();
              }}
            >
              <PlusCircleOutlined style={{ fontSize: '16px' }} />
              <span>新增卡片</span>
            </Button>
          )}
          <Button
            type={'link'}
            onClick={() => {
              Modal.confirm({
                icon: <QuestionCircleOutlined />,
                title: '退出导航卡片配置？',
                content: '将恢复到修改前的状态，是否继续？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                  dispatch({
                    type: 'drag/save',
                    payload: {
                      isEdit: false,
                    },
                  });
                  onCancel();
                },
              });
            }}
          >
            <CloseCircleOutlined style={{ fontSize: '16px' }} />
            <span>取消</span>
          </Button>
          <Button
            type={'link'}
            onClick={() => {
              Modal.confirm({
                icon: <QuestionCircleOutlined />,
                title: '确认保存导航修改？',
                content: '所做的修改将保存并生效，是否继续？',
                okText: '保存',
                cancelText: '取消',
                onOk: () => {
                  onSaveEdit();
                },
              });
            }}
          >
            <CheckCircleOutlined style={{ fontSize: '16px' }} />
            <span>保存</span>
          </Button>
        </Space>
      ) : (
        <Button
          type={'link'}
          onClick={() => {
            dispatch({
              type: 'drag/save',
              payload: {
                isEdit: true,
              },
            });
            onStartEdit();
          }}
        >
          <SettingOutlined style={{ fontSize: '16px' }} />
          <span>导航配置</span>
        </Button>
      )}
    </div>
  );
};
export default DragCardButtons;
