import { requestGet, requestPutJson } from '@/utils/request';

// 鉴权接口?
export function getAuth() {
  return requestGet('/api/auth', {});
}

// 下载阿里云
export function downloadFromALi(params: {
  filePath: string;
  fileName: string;
}) {
  return requestGet('/api/file/download/aliyun', params);
}

// 获取公司信息
export function getComInfo() {
  return requestGet('/api/company/info', {});
}

// 埋点接口
export function point(params: { page: string; urlFrom: string }) {
  return requestGet('/api/point', params);
}

export function editorAuthority(id: any, params: any) {
  return requestPutJson(`/fcoin/api/fcoin/apply/authority/${id}`, params);
}

export function getOrganization(params: { authorityType?: string }) {
  return requestGet(`/fcoin/api/fcoin/apply/authority/organization`, params);
}

export function getOrganizationByName(params: {
  name: string;
  authorityType?: string;
}) {
  return requestGet(`/fcoin/api/fcoin/apply/authority/organization`, params);
}

export function submitFile(params: {
  path: string;
  fileName: string;
  contentType: string;
}) {
  return requestGet(`/fcoin/api/file/upload/temporary/url`, params);
}

export function getCurrentUserInfo(params: { type: string }) {
  return requestGet(`/fcoin/api/fcoin/apply/authority/get/sync/user`, params);
}

export function postBuried(params: {
  portal?: string;
  classification?: string;
  menu?: string;
}) {
  return requestGet(`/fcoin/api/navigation/record`, params);
}

export function submitFileALi() {
  return requestGet(`/service/api/file/oss/sts`, {});
}
