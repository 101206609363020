import React, { ReactNode, useEffect, useState } from 'react';
import styles from './index.less';
import type { TabsProps } from 'antd';
import { Button, Space, Tabs } from 'antd';
import { ReactComponent as ArrowRight } from '@/static/images/enterpriseService/arrow-right.svg';
import BootAuthCard from '@/pages/enterpriseService/components/bootAuthModule/bootAuthCard';
import { IBootAuthStep } from '@/types/enterpriseService/bootAuth';
import { useModel } from '@@/plugin-model/useModel';
import { EEnterpriseStatus } from '@/types/enterpriseService';
import { calEnterpriseServiceTabsUnderLine } from '@/utils/tabs';
import BootAuthButton from '@/pages/enterpriseService/components/bootAuthModule/bootAuthButton';
import { ComponentWrapper } from '@digit-fe/digit-component';
import { Swiper } from 'antd-mobile';
import CustomServiceButton from '@/components/customServiceButton';
import { hasManagePermission } from '@/utils/enterprise';
import { useRequest } from '@@/plugin-request/request';
import { postBuried } from '@/utils/requests/requests_utils';

type TBAMProps = {
  enterpriseStatus: EEnterpriseStatus;
};

const BootAuthModule: React.FC<TBAMProps> = ({ enterpriseStatus }) => {
  const [tabValue, setTabValue] = useState<'developer' | 'leader'>('developer');

  const { role } = useModel('useEnterpriseModel', (model) => ({
    role: model.role,
  }));

  // 埋点
  const { run: postBuriedRun } = useRequest(
    (classification: string, menu: string) => {
      return postBuried({
        portal: '服务平台',
        classification,
        menu,
      });
    },
    {
      manual: true,
    },
  );

  // 开发人员第三步
  const developerStep3: {
    [key in EEnterpriseStatus]?: {
      title: string;
      subTitle: string | ReactNode;
      stepStatus: EEnterpriseStatus;
    };
  } = {
    [EEnterpriseStatus.NOT_JOIN]: {
      title: '03.加入合作企业，获取服务',
      subTitle: '访问邀请链接，即可加入合作企业',
      stepStatus: EEnterpriseStatus.NOT_JOIN,
    },
    [EEnterpriseStatus.NOT_CERT]: {
      title: '03.企业未合作认证，需管理员去认证',
      subTitle: (
        <div className={styles.layout_horizontal_center}>
          <span>或</span>
          <Button
            type={'link'}
            href={`${process.env.ID_URL}/#/enterprise?eop=exit`}
            target={'_blank'}
            style={{ padding: 0, display: 'flex', alignItems: 'center' }}
            onClick={() => {
              postBuriedRun('合作认证引导-成员', '退出企业');
            }}
          >
            退出企业
          </Button>
          <span>后自行认证</span>
        </div>
      ),
      stepStatus: EEnterpriseStatus.NOT_CERT,
    },
    [EEnterpriseStatus.UNDER_REVIEW]: {
      title: '03.您加入的企业合作认证审核中',
      subTitle: <BootAuthButton />,
      stepStatus: EEnterpriseStatus.UNDER_REVIEW,
    },
    [EEnterpriseStatus.NOT_PASS]: {
      title: '03.企业未合作认证，需管理员去认证',
      subTitle: (
        <div className={styles.layout_horizontal_center}>
          <span>或</span>
          <Button
            type={'link'}
            href={`${process.env.ID_URL}/#/enterprise?eop=exit`}
            target={'_blank'}
            style={{ padding: 0, display: 'flex', alignItems: 'center' }}
            onClick={() => {
              postBuriedRun('合作认证引导-成员', '退出企业');
            }}
          >
            退出企业
          </Button>
          <span>后自行认证</span>
        </div>
      ),
      stepStatus: EEnterpriseStatus.NOT_PASS,
    },
  };

  // 开发人员认证步骤
  const developerBootAuthSteps: IBootAuthStep[] = [
    {
      bootImage: require('@/static/images/enterpriseService/developer-step1.png'),
      title: '01.联系自己的企业管理员',
      stepStatus: EEnterpriseStatus.NOT_JOIN,
      subTitle: (
        <div className={styles.layout_vertical_center}>
          <span>不知道企业管理员是谁？</span>
          <CustomServiceButton
            buriedPortal={'服务平台'}
            buriedClassification={'合作认证引导-成员'}
          />
        </div>
      ),
    },
    {
      bootImage: require('@/static/images/enterpriseService/developer-step2.png'),
      title: '02.获取邀请链接',
      stepStatus: EEnterpriseStatus.NOT_JOIN,
      subTitle: (
        <Space size={4}>
          <span>由管理员生成邀请链接</span>
          <Button
            type={'link'}
            style={{ padding: 0, display: 'flex', alignItems: 'center' }}
            href={`${process.env.HELP_URL}/finereport/doc-view-4951.html`}
            target={'_blank'}
            onClick={() => {
              postBuriedRun('合作认证引导-成员', '邀请链接生成方法');
            }}
          >
            邀请链接生成方法
          </Button>
        </Space>
      ),
    },
    {
      bootImage: require('@/static/images/enterpriseService/developer-step3.png'),
      title:
        developerStep3[enterpriseStatus]?.title || '03.加入合作企业，获取服务',
      stepStatus:
        developerStep3[enterpriseStatus]?.stepStatus ||
        EEnterpriseStatus.NOT_JOIN,
      subTitle:
        developerStep3[enterpriseStatus]?.subTitle ||
        '访问邀请链接，即可加入合作企业',
      extraButton: enterpriseStatus === EEnterpriseStatus.NOT_JOIN,
    },
  ];

  // 负责人认证步骤
  const leaderBootAuthSteps: IBootAuthStep[] = [
    {
      bootImage: require('@/static/images/enterpriseService/leader-step1.png'),
      title: '01.提交合作认证申请',
      stepStatus: EEnterpriseStatus.NOT_CERT,
      extraButton:
        enterpriseStatus === EEnterpriseStatus.NOT_JOIN ||
        enterpriseStatus === EEnterpriseStatus.NOT_CERT,
      subTitle: (
        <div className={styles.layout_vertical_center}>
          <span>认证有困难？</span>
          <CustomServiceButton
            buriedPortal={'服务平台'}
            buriedClassification={'合作认证引导-管理员'}
          />
        </div>
      ),
    },
    {
      bootImage: require('@/static/images/enterpriseService/leader-step2.png'),
      title: '02.等待帆软审核',
      stepStatus: EEnterpriseStatus.UNDER_REVIEW,
      subTitle:
        enterpriseStatus === EEnterpriseStatus.UNDER_REVIEW ? (
          <BootAuthButton />
        ) : enterpriseStatus === EEnterpriseStatus.NOT_PASS ? (
          <ComponentWrapper mode={'pc'}>
            <BootAuthButton />
          </ComponentWrapper>
        ) : (
          '信息准确可自动通过审核'
        ),
    },
    {
      bootImage: require('@/static/images/enterpriseService/leader-step3.png'),
      title: '03.自动开通服务',
      stepStatus: EEnterpriseStatus.BASE_SERVICE_IN_EFFECT,
      subTitle: '审核通过后可立即开通服务',
    },
    {
      bootImage: require('@/static/images/enterpriseService/leader-step4.png'),
      title: '04.邀请成员加入',
      stepStatus: EEnterpriseStatus.BASE_SERVICE_IN_EFFECT,
      subTitle: '导入成员或链接邀请，让企业成员共享服务',
    },
  ];

  // 身份选择
  const identifyItems: TabsProps['items'] = [
    {
      key: 'developer',
      label: '我是开发/业务人员',
      children: (
        <BootAuthSteps
          steps={developerBootAuthSteps}
          tips={`以“企业成员”身份加入已合作企业后获取服务`}
          identify={tabValue}
        />
      ),
    },
    {
      key: 'leader',
      label: '我是项目/团队负责人',
      children: (
        <BootAuthSteps
          steps={leaderBootAuthSteps}
          tips={`认证成为“企业管理员”，邀请成员加入共享服务`}
          identify={tabValue}
        />
      ),
    },
  ];

  const handleTabChange = (value: any) => {
    setTabValue(value);
  };

  useEffect(() => {
    calEnterpriseServiceTabsUnderLine();
  }, [tabValue]);

  return (
    <div className={styles.boot_auth_wrapper}>
      <div className={styles.wrapper_header}>
        帆软合作/试用中客户如何获取服务？
      </div>
      {enterpriseStatus === EEnterpriseStatus.NOT_JOIN ? (
        <Tabs
          defaultActiveKey={'developer'}
          activeKey={tabValue}
          items={identifyItems}
          onChange={handleTabChange}
          id={'enterpriseTabs'}
        />
      ) : hasManagePermission(role) ? (
        <BootAuthSteps
          steps={leaderBootAuthSteps}
          tips={`认证成为“企业管理员”，邀请成员加入共享服务`}
        />
      ) : (
        <BootAuthSteps
          steps={developerBootAuthSteps}
          tips={`以“企业成员”身份加入已合作企业后获取服务`}
        />
      )}
    </div>
  );
};

export default BootAuthModule;

const BootAuthSteps: React.FC<{
  steps: IBootAuthStep[];
  tips: string;
  identify?: 'developer' | 'leader';
}> = ({ steps, tips, identify }) => {
  const { enterpriseStatus, role } = useModel(
    'useEnterpriseModel',
    (model) => ({
      enterpriseStatus: model.enterpriseStatus,
      role: model.role,
    }),
  );

  return (
    <div
      className={styles.wrapper_body}
      style={{
        borderTop:
          enterpriseStatus === EEnterpriseStatus.NOT_JOIN
            ? '0'
            : '1px solid #e4e9ed',
      }}
    >
      <div className={styles.top_tips}>{tips}</div>
      <ComponentWrapper mode={'pc'}>
        <div className={styles.cards_wrapper}>
          {steps.map((item, index) => (
            <React.Fragment key={index}>
              <BootAuthCard item={item} identify={identify} />
              {index < steps.length - 1 ? (
                <ArrowRight className={styles.mgt64} />
              ) : null}
            </React.Fragment>
          ))}
        </div>
      </ComponentWrapper>
      <ComponentWrapper mode={'notPC'}>
        <div className={styles.cards_wrapper}>
          <Swiper
            style={{
              '--track-padding': ' 0 0 26px',
            }}
            defaultIndex={0}
            indicatorProps={{
              style: {
                '--dot-size': '6px',
                '--dot-spacing': '4px',
                '--active-dot-size': '6px',
                '--active-dot-color': 'rgba(51,51,51,0.4)',
                '--dot-color': 'rgba(51,51,51,0.1)',
                '--dot-border-radius': '50%',
              },
            }}
          >
            {steps.map((item, index) => (
              <Swiper.Item key={index}>
                <BootAuthCard item={item} />
              </Swiper.Item>
            ))}
          </Swiper>
          {enterpriseStatus !== EEnterpriseStatus.UNDER_REVIEW &&
            hasManagePermission(role) && (
              <div className={styles.mgt24}>
                <BootAuthButton identify={identify} />
              </div>
            )}
        </div>
      </ComponentWrapper>
    </div>
  );
};
