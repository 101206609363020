import React from 'react';
import { Form, Input, Select, Switch } from 'antd';
import { ECardItemType } from '@/enum/card';
import MaxLengthInput from '@/components/maxLengthInput/index';
import UploadFile from '@/components/dragCard/uploadFile';
import { Item } from '@/components/editDragTable/editDragTable';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  onChange?: (res: any) => void;
  editing: boolean;
  dataIndex: keyof Item;
  title: any;
  inputType: 'input' | 'select' | 'file' | 'url' | 'fileAndUrl' | 'switch';
  record: Item;
  index: number;
  value: string;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = (props) => {
  const {
    onChange,
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    value,
    ...restProps
  } = props;
  const createInputNode = () => {
    switch (inputType) {
      case 'fileAndUrl':
        return record.type == ECardItemType.file ? <UploadFile /> : <Input />;
      case 'url':
        return <Input />;
      case 'file':
        return <UploadFile />;
      case 'input':
        return <MaxLengthInput />;
      case 'switch':
        return <Switch defaultChecked={record.mobileShow} />;
      case 'select':
        return (
          <Select
            onChange={onChange}
            options={[
              { value: ECardItemType.file, label: '文件' },
              { value: ECardItemType.url, label: '链接' },
            ]}
          />
        );
    }
  };
  const calcRequiredMsg = () => {
    switch (inputType) {
      case 'fileAndUrl':
        return record.type == ECardItemType.file ? '请上传文件' : '请填写链接';
      case 'url':
        return '请填写链接';
      case 'input':
        return '请填写标题';
      case 'switch':
        return '请选择是否在移动端展示';
      case 'select':
        return '请选择文件类型';
    }
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          initialValue={record[dataIndex]}
          rules={[
            {
              required: true,
              message: calcRequiredMsg(),
            },
          ]}
        >
          {createInputNode()}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
export default EditableCell;
