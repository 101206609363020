import React, { useEffect, useState } from 'react';
import styles from './detailsTable.less';
import { Button, message, Select, Tooltip, Modal } from 'antd';
import { DeleteOutlined, FormOutlined, MenuOutlined } from '@ant-design/icons';
import { QuestionCircleOutlined } from '@ant-design/icons';
import NotAdded from '@/components/notAdded/notAdded';
import AddOrEditFileModal from './addOrEditeFileModal';
import AddOrEditUrlModal from './addOrEditeUrlModal';
import { useRequest, useModel } from 'umi';
import {
  deleteProduct,
  deleteProductList,
  getProductDetails,
  getProductDetailsList,
  sortProductDetailsList,
} from '@/utils/requests/requests_resource';
import { productManageItem } from '.';
import dayjs from 'dayjs';
import AddOrEditProductModal from './addOrEditProductModal';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table } from 'antd';
import { isSuperAdmin } from '@/utils';

type Props = { id: string; setRefresh: Function; refresh: number };

export interface ProductItem {
  key: string;
  icon: string;
  id: string;
  index: number;
  lastModifiedTime: string;
  title: string;
  type: number;
  url?: string;
  file?: File;
  filename?: string;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && { ...transform, scaleY: 1 },
    )?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const ProductResourceDetails: React.FC<Props> = ({
  id,
  setRefresh,
  refresh,
}) => {
  const [dataSource, setDataSource] = useState<ProductItem[]>([]);
  const [addFileModalOpen, setAddFileModalOpen] = useState(false);
  const [addUrlModalOpen, setAddUrlModalOpen] = useState(false);
  const [details, setDetails] = useState<productManageItem | undefined>();
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ProductItem | undefined>();
  const [refreshTable, setRefreshTable] = useState(0);
  const { initialState } = useModel('@@initialState');

  const getProductDetailsRequest = useRequest(
    () => {
      return getProductDetails({ id });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setDetails(res);
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    id && getProductDetailsRequest.run();
  }, [id, refresh]);

  const getProductDetailsListRequest = useRequest(
    () => {
      return getProductDetailsList({ id });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setDataSource(
          res.map((item: ProductItem) => Object.assign(item, { key: item.id })),
        );
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    id && getProductDetailsListRequest.run();
  }, [id, refreshTable]);

  const sortProductDetailsListRequest = useRequest(
    (value) => {
      return sortProductDetailsList(id, JSON.stringify(value));
    },
    {
      manual: true,
      onSuccess: (res) => {
        message.success('修改产品内容列表排序成功');
      },
      onError: (e) => {},
    },
  );

  const deleteProductRequest = useRequest(
    () => {
      return deleteProduct({ id });
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  const deleteProductListRequest = useRequest(
    (id) => {
      return deleteProductList({ id });
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 48,
    },
    {
      title: 'icon',
      dataIndex: 'icon',
      align: 'center',
      width: 48,
      className: 'icon-drag-visible',
      render: (value: string) => (
        <img src={value} style={{ width: 32, height: 32, borderRadius: 4 }} />
      ),
    },
    {
      title: '标题',
      dataIndex: 'title',
      width: 220,
      className: 'name-drag-visible',
    },
    {
      title: '类型',
      dataIndex: 'type',
      width: 60,
      className: 'type-drag-visible',
      align: 'center',
      render: (value: number) => <div>{value == 1 ? '文件' : '链接'}</div>,
    },
    {
      title: 'URL',
      dataIndex: 'url',
      width: 220,
      className: 'url-drag-visible',
      render: (value: string) => (
        <a href={value} target={'_blank'}>
          {value}
        </a>
      ),
    },
    {
      title: '最近更新时间',
      dataIndex: 'lastModifiedTime',
      width: 160,
      className: 'lastModifiedTime-drag-visible',
      render: (value: string) =>
        `${dayjs(value).format('YYYY-MM-DD HH:mm:ss')}`,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 94,
      align: 'center',
      className: 'operate-drag-visible',
      render: (_value: any, record: any) => (
        <>
          <span className={styles.editor_icon_button}>
            <Button
              type="text"
              icon={<FormOutlined />}
              style={{ color: '#808696' }}
              onClick={() => {
                setSelectedItem(record);
                record.type == 1 && setAddFileModalOpen(true);
                record.type == 2 && setAddUrlModalOpen(true);
              }}
            />
          </span>
          <span className={styles.delete_icon_button}>
            <Button
              type="text"
              icon={<DeleteOutlined />}
              style={{ color: '#808696' }}
              onClick={() =>
                Modal.confirm({
                  title: '确认删除所选内容？',
                  icon: <QuestionCircleOutlined />,
                  content: '此操作不可逆，请谨慎操作',
                  okText: '删除',
                  okButtonProps: { type: 'primary', danger: true },
                  cancelText: '取消',
                  cancelButtonProps: { danger: true },
                  onOk() {
                    deleteProductListRequest
                      .run(record.id)
                      .then(() => getProductDetailsListRequest.run());
                  },
                  onCancel() {},
                })
              }
            />
          </span>
        </>
      ),
    },
  ];

  return (
    <div className={styles.product_resource_details}>
      <div className={styles.product_resource_details_toolbar}>
        <div className={styles.product_resource_details_title}>
          <img className={styles.icon_logo} src={details?.logo} />
          <div className={styles.product_resource_details_description}>
            <div className={styles.product_resource_details_description_title}>
              {details?.title}
              <span className={styles.editor_icon_button}>
                <Button
                  type="text"
                  icon={<FormOutlined />}
                  style={{ marginLeft: '7px', color: '#808696' }}
                  onClick={() => setAddProductModalOpen(true)}
                />
                <AddOrEditProductModal
                  addProductModalOpen={addProductModalOpen}
                  setAddProductModalOpen={setAddProductModalOpen}
                  id={details?.id}
                  modalTitle="编辑产品"
                  submitMessage="修改产品成功"
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              </span>
              {isSuperAdmin(initialState?.uid) && (
                <span className={styles.delete_icon_button}>
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    style={{ marginLeft: '10px', color: '#808696' }}
                    onClick={() => {
                      Modal.confirm({
                        title: '确认删除所选内容？',
                        icon: <QuestionCircleOutlined />,
                        content: '此操作不可逆，请谨慎操作',
                        okText: '删除',
                        okButtonProps: { type: 'primary', danger: true },
                        cancelText: '取消',
                        cancelButtonProps: { danger: true },
                        onOk() {
                          deleteProductRequest
                            .run()
                            .then(() => setRefresh(refresh + 1));
                        },
                        onCancel() {},
                      });
                    }}
                  />
                </span>
              )}
            </div>
            <div className={styles.product_resource_details_description_tip}>
              <Tooltip title={details?.brief}>{details?.brief}</Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.product_resource_details_add}>
          <Select
            value="添加内容"
            style={{ width: 100 }}
            onChange={(value: string) => {
              setSelectedItem(undefined);
              if (value == '添加链接') {
                setAddUrlModalOpen(true);
              } else if (value == '添加文件') {
                setAddFileModalOpen(true);
              }
            }}
            options={[
              { value: '添加链接', label: '添加链接' },
              { value: '添加文件', label: '添加文件' },
            ]}
          />
          <span style={{ color: '#808696', marginLeft: '10px' }}>
            鼠标拖拽可以直接对内容进行排序，内容支持跨分组拖动
          </span>
          <AddOrEditFileModal
            fileModalOpen={addFileModalOpen}
            setFileModalOpen={setAddFileModalOpen}
            details={selectedItem}
            id={id}
            num={dataSource.length}
            refresh={refreshTable}
            setRefresh={setRefreshTable}
          />
          <AddOrEditUrlModal
            urlModalOpen={addUrlModalOpen}
            setUrlModalOpen={setAddUrlModalOpen}
            details={selectedItem}
            id={id}
            num={dataSource.length}
            refresh={refreshTable}
            setRefresh={setRefreshTable}
          />
        </div>
      </div>
      <div className={styles.product_resource_details_table}>
        {dataSource.length > 0 ? (
          <DndContext
            onDragEnd={({ active, over }: DragEndEvent) => {
              let newData: ProductItem[] = [];
              setDataSource((previous) => {
                const activeIndex = previous.findIndex(
                  (i) => i.key === active.id,
                );
                const overIndex = previous.findIndex((i) => i.key === over?.id);
                newData = arrayMove(previous, activeIndex, overIndex);
                return newData;
              });
              sortProductDetailsListRequest.run(
                newData.map((item: ProductItem, index: number) => ({
                  id: item.id,
                  index: index,
                })),
              );
            }}
          >
            <SortableContext
              items={dataSource.map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                components={{
                  body: {
                    row: Row,
                  },
                }}
                showHeader={false}
                rowKey="key"
                pagination={false}
                columns={columns}
                dataSource={dataSource}
              />
            </SortableContext>
          </DndContext>
        ) : (
          <NotAdded />
        )}
      </div>
    </div>
  );
};

export default ProductResourceDetails;
