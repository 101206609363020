import React, { useEffect, useState } from 'react';
import './teamButton.less';
import { Button, Row, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

type Props = {
  name: string;
  value: string;

  setItemValue: Function;
};

const TeamButton: React.FC<Props> = ({ name, value, setItemValue }) => {
  return (
    <div
      className="select-user-button"
      style={{
        width: '270px',
        height: '32px',
        fontSize: '16px',
        lineHeight: '32px',
      }}
    >
      <Row>
        <Col span={1}></Col>
        <Col span={20}>{name}</Col>
        <Col span={2}>
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={() => {
              setItemValue(value);
            }}
          />
        </Col>
        <Col span={1}></Col>
      </Row>
    </div>
  );
};

export default TeamButton;
