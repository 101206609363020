import React, { useEffect, useState } from 'react';
import styles from './index.less';
import { ComponentWrapper } from '@digit-fe/digit-component';
import { useModel, useRequest } from 'umi';
import { getNotice } from '@/utils/requests/requests_enterprise';
import dayjs from 'dayjs';
import {
  getCurrentUserInfo,
  postBuried,
} from '@/utils/requests/requests_utils';

type Props = {};

const NoticeCard: React.FC<Props> = ({}) => {
  const [noticeList, setNoticeList] = useState<NoticeItem[]>([]);
  const [isAuth, setIsAuth] = useState(false);
  const { initialState } = useModel('@@initialState');

  const getNoticeRequest = useRequest(
    () => {
      return getNotice({ limit: 7 });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setNoticeList(res);
      },
      onError: (e) => {},
    },
  );

  // 埋点
  const { run: postBuriedRun } = useRequest(
    (menu: string) => {
      return postBuried({
        portal: '服务平台',
        classification: '通知公告',
        menu,
      });
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    getNoticeRequest.run();
  }, []);

  const getIsAuthRequest = useRequest(
    () => {
      return getCurrentUserInfo({ type: 'ANNOUNCEMENT' });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setIsAuth(res.admin || res.authorized);
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    initialState.nameEn && getIsAuthRequest.run();
  }, []);

  return (
    <ComponentWrapper>
      <div className={styles.notice_card}>
        <div className={styles.notice_card_head}>
          <div className={styles.notice_card_head_title}>通知公告</div>
          {isAuth && (
            <a
              className={styles.notice_card_setting}
              href={`${process.env.HOME_URL}/service_advisories`}
              target={'_blank'}
            >
              <img
                className={styles.setting_icon}
                src={require('@/static/images/setting_outlined.png')}
              />
              公告管理
            </a>
          )}
        </div>
        <div className={styles.notice_card_list}>
          {noticeList?.map((item, index) => (
            <a
              className={styles.notice_card_list_item}
              href={item.url}
              target="_blank"
              key={index}
              onClick={() => {
                postBuriedRun(`notice_${index + 1}`);
              }}
            >
              {item.importance === '重要' && (
                <div className={styles.notice_card_list_item_importance}>
                  {item.importance}
                </div>
              )}
              <div className={styles.notice_card_list_item_title}>
                {item.title}
              </div>
              <div className={styles.notice_card_list_item_date}>
                {dayjs(item.putOnTime).format('YYYY-MM-DD')}
              </div>
            </a>
          ))}
        </div>
      </div>
    </ComponentWrapper>
  );
};

export default NoticeCard;

export interface NoticeItem {
  importance: string;
  title: string;
  url: string;
  putOnTime: string;
  shelfStatus: number;
}
