// 企业信息
export interface IEnterpriseInfo {
  // 企业状态
  enterpriseName?: string;
  // 服务有效期截止日期
  expiredTime?: string;
  // 企业 logo
  logo?: string;
  // 销售顾问
  saler?: { name: string; phone: string }[];
  // 客户成功
  successMan?: { name: string; phone: string }[];
}

// 企业权限
export enum EEnterpriseAuth {
  /**
   * 不可使用技术支持服务
   */
  UNAVAILABLE,

  /**
   * 可使用技术支持服务
   */
  AVAILABLE,

  /**
   * 有限使用技术支持服务
   */
  LIMITED,
}

// 企业用户身份
export enum EEnterpriseUserIdentify {
  /**
   * 企业普通成员
   */
  MEMBER,

  /**
   * 企业管理员
   */
  SUPER_ADMIN,

  /**
   * 企业超级管理员
   */
  ADMIN,
}

// 企业状态
export enum EEnterpriseStatus {
  /**
   * 未加入企业
   */
  NOT_JOIN,

  /**
   * 未提交合作认证
   */
  NOT_CERT,

  /**
   * 合作认证审核中
   */
  UNDER_REVIEW,

  /**
   * 未通过合作认证
   */
  NOT_PASS,

  /**
   * 无法获取有效期
   */
  CANNOT_GET_VALIDITY_PERIOD,

  /**
   * 正式服务生效中
   */
  FORMAL_SERVICE_IN_EFFECT,

  /**
   * 基础服务生效中
   */
  BASE_SERVICE_IN_EFFECT,

  /**
   * 高级服务生效中
   */
  PREMIUM_SERVICE_IN_EFFECT,

  /**
   * 临时服务生效中
   */
  TEMPORARY_SERVICE_IN_EFFECT,

  /**
   * 非合作企业
   */
  NOT_COOPERATE,

  /**
   * 无法获取服务状态
   */
  CANNOT_GET_SERVICE_STATUS,

  /**
   * 正式服务已过期
   */
  FORMAL_SERVICE_EXPIRED,

  /**
   * 基础服务已过期
   */
  BASE_SERVICE_EXPIRED,

  /**
   * 高级服务已过期
   */
  PREMIUM_SERVICE_EXPIRED,

  /**
   * 临时服务已过期
   */
  TEMPORARY_SERVICE_EXPIRED,
}

// 催办结果
export enum EUrgeResult {
  /**
   * 不能催办
   */
  DISABLED = -1,

  /**
   * 短时间内不可重复点击
   */
  WAIT,

  /**
   * 催办成功
   */
  SUCCESS,
}
