import React from 'react';
import styles from './index.less';
import { Button } from 'antd';

const ServiceAbnormal: React.FC<{}> = () => {
  return (
    <div className={styles.abnormal_wrapper}>
      <img src={'https://hwobs.fanruan.com/ui/color-waiting.png'} />
      <div className={styles.abnormal_title}>抱歉，服务维护中</div>
      <div className={styles.abnormal_tip1}>
        如需紧急技术支持服务，请拨打400-811-8890 转 2
      </div>
      <Button
        type={'link'}
        target={'_blank'}
        href={`${process.env.SERVICE_URL}/intel-service`}
      >
        备用在线支持通道
      </Button>
    </div>
  );
};

export default ServiceAbnormal;
