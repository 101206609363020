import React, { useEffect, useState } from 'react';
import { Modal, message } from 'antd';
import PermissionTab from '@/components/permissionTab/permissionTab';
import { useRequest } from 'umi';
import {
  editorAuthority,
  getAuthority,
} from '@/utils/requests/requests_addMembers';
import styles from './index.less';

type Props = {
  permissionManegeModalOpen: boolean;
  setPermissionManegeModalOpen: Function;
  refresh: number;
  setRefresh: Function;
};

const AddMembersAuthorityModal: React.FC<Props> = ({
  permissionManegeModalOpen,
  setPermissionManegeModalOpen,
  refresh,
  setRefresh,
}) => {
  const [name, setName] = useState('');
  const [enabled, setEnabled] = useState(true);
  const [authorizedTypeNameList, setAuthorizedTypeNameList] = useState<any[]>(
    [],
  );
  const [allLabelOptions, setAllLabelOptions] = useState<any>([]);
  const [authorizedUserList, setAuthorizedUserList] = useState<any>([]);
  const [authorizedDeptList, setAuthorizedDeptList] = useState<any>([]);
  const [deptList, setDeptList] = useState<any[]>([]);
  const [nameEnList, setNameEnList] = useState<any[]>([]);
  const [value, setValue] = useState<any>();

  useEffect(() => {
    setValue(
      JSON.stringify({
        name,
        enabled,
        informationLabelList: authorizedTypeNameList,
        deptList,
        nameEnList,
      }),
    );
  }, [name, enabled, authorizedTypeNameList, deptList, nameEnList]);

  const editorAuthorityRun = useRequest(
    (value) => {
      return editorAuthority('310cdd2ae5f56cbcfa816f77882fe5ba', value);
    },
    {
      manual: true,
      onSuccess: (res) => {
        message.success('编辑授权成功');
      },
      onError: (e) => {
        message.error('编辑授权失败');
      },
    },
  );

  const getAuthorityRun = useRequest(
    (id: string) => {
      return getAuthority({ id });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setName(res.name);
        setAuthorizedUserList(res.authorizedUserList);
        setAuthorizedDeptList(res.authorizedDeptList);
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    getAuthorityRun.run('310cdd2ae5f56cbcfa816f77882fe5ba');
  }, [refresh]);

  return (
    <Modal
      wrapClassName={styles.add_member_authority_modal}
      open={permissionManegeModalOpen}
      title="权限管理"
      width={694}
      destroyOnClose={true}
      okText="保存"
      onOk={() => {
        editorAuthorityRun.run(value).then(() => {
          setRefresh(refresh + 1);
          setPermissionManegeModalOpen(false);
        });
      }}
      cancelText="取消"
      onCancel={() => {
        setPermissionManegeModalOpen(false);
      }}
    >
      <PermissionTab
        authorizedUserList={authorizedUserList}
        setDeptListValue={setDeptList}
        authorizedDeptList={authorizedDeptList}
        setNameEnListValue={setNameEnList}
        authorityType="COMPANY_MEMBER"
      />
    </Modal>
  );
};

export default AddMembersAuthorityModal;
