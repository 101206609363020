import styles from './index.less';
import AddMembersForm from './form';
import { useModel, useRequest } from 'umi';
import { getAddMemberAuthority } from '@/utils/requests/requests_addMembers';
import { useEffect, useState } from 'react';
import { NoAccessPage } from '@digit-fe/digit-component';

export default function IndexPage() {
  const { initialState } = useModel('@@initialState');
  const { isFrAccount } = initialState;
  const [isHasAccess, setIsHasAccess] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  const getAddMemberAuthorityRun = useRequest(
    () => {
      return getAddMemberAuthority({ type: 'COMPANY_MEMBER' });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setIsHasAccess(res.admin || res.authorized);
        setIsAdmin(res.admin);
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    getAddMemberAuthorityRun.run();
  }, []);

  return (
    <div className={styles.add_members}>
      {isFrAccount && isHasAccess ? (
        <div
          className={styles.add_members_content}
          style={{ position: 'relative' }}
        >
          <AddMembersForm isAdmin={isAdmin} />
        </div>
      ) : (
        <NoAccessPage
          switchAccountHref={`${process.env.ID_URL}/login/token/logout?referrer=${window.location.href}`}
          isFrAccount={isFrAccount}
        />
      )}
    </div>
  );
}
