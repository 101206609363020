import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import styles from './index.less';

type TMaxLengthInput = {
  max?: number;
  onChange?: (text: string) => void;
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
};

const MaxLengthInput: React.FC<TMaxLengthInput> = (props) => {
  const { placeholder, value, onChange, autoFocus, max = 20 } = props;
  const [text, setText] = useState('');
  useEffect(() => {
    if (value) {
      setText(value);
    }
  }, [value]);

  return (
    <div className={styles.max_length_editor}>
      <Input
        autoFocus={autoFocus}
        placeholder={placeholder}
        value={text}
        maxLength={max}
        onChange={(e) => {
          const inputText = e.target.value.slice(0, max);
          setText(inputText);
          onChange?.(inputText);
        }}
      />
      <span className={styles.count_wrapper}>{`${text.length} / ${max}`}</span>
    </div>
  );
};
export default MaxLengthInput;
