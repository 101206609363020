import React, { useEffect, useState } from 'react';
import { useRequest } from 'umi';
import styles from './permissionTab.less';
import { Input, Button, Row, Col, Radio, Tree, Checkbox, message } from 'antd';
import img_no_selected from '@/static/images/selectUser.png';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  deleteParentArray,
  handleTreeData,
  processTreeData,
  treeDataToArray,
} from '@/utils';
import {
  getOrganization,
  getOrganizationByName,
} from '@/utils/requests/requests_utils';
import TeamButton from '../teamButton/teamButton';

type Props = {
  authorizedDeptList: any;
  setDeptListValue: Function;
  authorizedUserList: any;
  setNameEnListValue: Function;
  authorityType?: string;
};

const PermissionTab: React.FC<Props> = ({
  authorizedDeptList,
  setDeptListValue,
  authorizedUserList,
  setNameEnListValue,
  authorityType,
}) => {
  const [tabHead, setTabHead] = useState(true);
  const [tabHeadValue, setTabHeadValue] = useState('department');
  const [treeDataValue, setTreeDataValue] = useState<any>();
  const [treeToArrayValue, setTreeToArrayValue] = useState<any>();
  const [deptList, setDeptList] = useState<any[]>([]);
  const [nameEnList, setNameEnList] = useState<any[]>([]);
  const [nameEnDeptList, setNameEnDeptList] = useState<any[]>([]);
  const [itemValue, setItemValue] = useState<any>();
  const [checkedKeys, setCheckedKeys] = useState<any>([]);
  const [searchName, setSearchName] = useState('');
  const [searchAllUser, setSearchAllUser] = useState<any[]>([]);
  const [searchAllUserOptions, setSearchAllUserOptions] = useState<any[]>([]);
  const [searchOneCheckedValues, setSearchOneCheckedValues] = useState<any[]>(
    [],
  );
  const [searchCheckedValues, setSearchCheckedValues] = useState<any[]>([]);
  const [searchNameEnDeptList, setSearchNameEnDeptList] = useState<any[]>([]);
  const [deduplicateNameEnDeptList, setDeduplicateNameEnDeptList] = useState<
    any[]
  >([]);

  const getOrganizationRequest = useRequest(
    () => {
      return getOrganization({ authorityType });
    },
    {
      manual: true,
      onSuccess: (res) => {
        let originTreeData = [];
        originTreeData.push(res);
        const treeData = handleTreeData(originTreeData);
        setTreeDataValue(treeData);
        setTreeToArrayValue(treeDataToArray(treeData));

        setTabHeadValue('department');
        let checkedKeysArr: any[] = [];
        authorizedDeptList.forEach((item: any) => {
          checkedKeysArr.push(item.dept);
        });
        authorizedUserList.forEach((item: any) => {
          checkedKeysArr.push(item.nameEn);
        });

        setCheckedKeys(checkedKeysArr);
        let treeToArrayValueNew: any[] = [];
        checkedKeysArr.forEach((value) => {
          treeToArrayValueNew = [
            ...treeToArrayValueNew,
            ...treeDataToArray(treeData).filter(
              (item: any) => item.key == value,
            ),
          ];
        });

        setNameEnDeptList(treeToArrayValueNew);
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    getOrganizationRequest.run();
  }, []);

  const getOrganizationByNameRequest = useRequest(
    (value) => {
      return getOrganizationByName({ name: value, authorityType });
    },
    {
      manual: true,
      onSuccess: (res) => {
        let allUsers = JSON.parse(
          JSON.stringify(treeDataToArray(deduplicateNameEnDeptList)),
        );
        setSearchAllUser(res);
        let searchAllUserOptionsNew: any[] = [];
        let searchAllUserOptionsValue: any[] = [];
        let searchAllUserOptionsValueChecked: any[] = [];
        res.map((item: any) => {
          searchAllUserOptionsValue.push(item.nameEn);
          searchAllUserOptionsNew.push({
            label: `${item.nameEn}-${item.nameZh}`,
            value: item.nameEn,
          });
        });
        setSearchAllUserOptions(searchAllUserOptionsNew);

        searchAllUserOptionsValue.forEach((item) => {
          if (allUsers.findIndex((value: any) => value.key == item) > -1) {
            searchAllUserOptionsValueChecked.push(item);
          }
        });

        setSearchOneCheckedValues(searchAllUserOptionsValueChecked);
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    const a = nameEnDeptList.filter((item) => item.key != itemValue);
    const c = searchNameEnDeptList.filter((item) => item.key != itemValue);
    const e = deduplicateNameEnDeptList.filter((item) => item.key != itemValue);
    setNameEnDeptList(a);
    setSearchNameEnDeptList(c);
    setDeduplicateNameEnDeptList(e);
    let b: any[] = [];
    a.forEach((item) => {
      b.push(item.key);
    });
    setCheckedKeys(b);
    let d: any[] = [];
    c.forEach((item) => {
      d.push(item.key);
    });
    setSearchCheckedValues(d);
    setSearchOneCheckedValues(
      searchOneCheckedValues.filter((value) => value != itemValue),
    );
  }, [itemValue]);

  useEffect(() => {
    let deptListNew: any[] = [];
    let nameEnListNew: any[] = [];
    [...nameEnDeptList, ...searchNameEnDeptList].forEach((item) => {
      item.children && deptListNew.push(item.key);
      !item.children && nameEnListNew.push(item.key);
    });
    setDeptList(deptListNew);
    setNameEnList(nameEnListNew);
  }, [nameEnDeptList, searchNameEnDeptList]);

  useEffect(() => {
    let nameEnDeptListNew = JSON.parse(JSON.stringify(nameEnDeptList));
    searchNameEnDeptList.forEach((item) => {
      if (
        nameEnDeptListNew.findIndex((value: any) => value.key == item.key) < 0
      ) {
        nameEnDeptListNew.push(item);
      }
    });
    let newCheckedKeys: any = [];
    [...nameEnDeptList, ...searchNameEnDeptList].forEach((item) => {
      newCheckedKeys.push(item.key);
    });
    setCheckedKeys([...newCheckedKeys]);
    setDeduplicateNameEnDeptList(nameEnDeptListNew);
  }, [nameEnDeptList, searchNameEnDeptList]);

  useEffect(() => {
    setDeptListValue(deptList);
    setNameEnListValue(nameEnList);
  }, [deptList, nameEnList]);

  return (
    <>
      <div style={{ marginTop: '10px', lineHeight: '32px' }}>
        授权用户
        <span style={{ color: 'red' }}>*</span>
      </div>
      <div className={styles.permission_tab}>
        <div className={styles.permission_manage_tab}>
          <div className={styles.permission_manage_tab_head}>
            {tabHead && (
              <Radio.Group
                defaultValue={tabHeadValue}
                buttonStyle="solid"
                style={{ marginLeft: '12px' }}
                onChange={(e: any) => {
                  setTabHeadValue(e.target.value);
                  if (e.target.value == 'department') {
                    setSearchAllUserOptions([]);
                    setSearchName('');
                  }
                }}
              >
                <Radio.Button value="department">部门</Radio.Button>
              </Radio.Group>
            )}
            <Input.Search
              className={styles.permission_manage_tab_head_search_input}
              placeholder="通过中/英文名搜索用户"
              value={searchName}
              allowClear
              onChange={(event: any) => {
                setSearchName(event.target.value);
              }}
              onSearch={(value: string) => {
                getOrganizationByNameRequest.run(value);
              }}
              onFocus={() => {
                setTabHead(false);
                setTabHeadValue('search');
              }}
              onBlur={() => {
                setTabHead(true);
              }}
            />
          </div>
          {tabHeadValue === 'department' && (
            <div className={styles.permission_manage_tab_body}>
              <Tree
                checkable
                checkedKeys={checkedKeys}
                onCheck={(checkedKeys, info) => {
                  const newCheckedNodes = JSON.parse(
                    JSON.stringify(info.checkedNodes),
                  );
                  const newTreeData = processTreeData(newCheckedNodes);
                  setNameEnDeptList(newTreeData);

                  let a: any[] = [];
                  newTreeData.forEach((item) => {
                    a.push(item.key);
                  });
                  setCheckedKeys(a);
                  setItemValue(undefined);
                  if (!info.checked) {
                    setSearchCheckedValues(
                      searchCheckedValues.filter(
                        (value) => value != info.node.key,
                      ),
                    );

                    setSearchNameEnDeptList(
                      searchNameEnDeptList.filter(
                        (item) => item.key != info.node.key,
                      ),
                    );
                  }
                }}
                treeData={treeDataValue}
                height={350}
              />
            </div>
          )}
          {tabHeadValue === 'search' && (
            <div className={styles.permission_manage_tab_body_search}>
              <Checkbox.Group
                value={searchOneCheckedValues}
                onChange={(checkedValues: CheckboxValueType[]) => {
                  setSearchOneCheckedValues(checkedValues);
                }}
              >
                <Row>
                  {searchAllUserOptions.map((item: any) => {
                    return (
                      <Col span={24}>
                        <Checkbox
                          value={item.value}
                          onChange={(e: CheckboxChangeEvent) => {
                            if (e.target.checked) {
                              let newSearchCheckedValue = [
                                ...searchCheckedValues,
                                e.target.value,
                              ];
                              setSearchCheckedValues(newSearchCheckedValue);

                              let treeToArrayValueNew: any[] = [];
                              newSearchCheckedValue.forEach((value) => {
                                treeToArrayValueNew = [
                                  ...treeToArrayValueNew,
                                  ...treeToArrayValue.filter(
                                    (item: any) => item.key == value,
                                  ),
                                ];
                              });
                              setSearchNameEnDeptList(treeToArrayValueNew);
                              let newCheckedKeys = [
                                ...checkedKeys,
                                e.target.value,
                              ];

                              setCheckedKeys(newCheckedKeys);
                            } else {
                              let newSearchCheckedValue =
                                searchCheckedValues.filter(
                                  (value: any) => value != e.target.value,
                                );
                              setSearchCheckedValues(newSearchCheckedValue);

                              let treeToArrayValueNew: any[] = [];
                              newSearchCheckedValue.forEach((value: any) => {
                                treeToArrayValueNew = [
                                  ...treeToArrayValueNew,
                                  ...treeToArrayValue.filter(
                                    (item: any) => item.key == value,
                                  ),
                                ];
                              });
                              setSearchNameEnDeptList(treeToArrayValueNew);
                              setNameEnDeptList(
                                processTreeData(
                                  deleteParentArray(
                                    treeDataToArray(deduplicateNameEnDeptList),
                                    e.target.value,
                                  ),
                                ),
                              );
                            }
                          }}
                        >
                          {item.label}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </div>
          )}
        </div>
        <div className={styles.permission_selected}>
          <div className={styles.permission_manage_tab_right}>
            <Row>
              <Col span={1}></Col>
              <Col span={15}>已选 {deduplicateNameEnDeptList.length} 项</Col>
              <Col span={7} style={{ textAlign: 'right' }}>
                <Button
                  type="link"
                  style={{
                    height: '48px',
                  }}
                  onClick={() => {
                    setNameEnDeptList([]);
                    setCheckedKeys([]);
                    setSearchNameEnDeptList([]);
                    setSearchCheckedValues([]);
                    setSearchOneCheckedValues([]);
                  }}
                >
                  清空
                </Button>
              </Col>
              <Col span={1}></Col>
            </Row>
          </div>
          <div
            className={styles.permission_manage_tab_body}
            style={{
              padding: '12px',
              overflow: ' auto',
            }}
          >
            {nameEnDeptList.length == 0 && searchNameEnDeptList.length == 0 && (
              <div
                style={{
                  textAlign: 'center',
                  color: '#c5c7ce',
                  paddingTop: '80px',
                }}
              >
                <img src={img_no_selected} />
                <div
                  style={{
                    color: '#c5c7ce',
                    marginTop: '24px',
                  }}
                >
                  请在左侧选择授权用户
                </div>
              </div>
            )}
            {deduplicateNameEnDeptList.map((item: any) => {
              return (
                <TeamButton
                  name={item.title}
                  value={item.key}
                  setItemValue={setItemValue}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PermissionTab;
