import React, { useEffect } from 'react';
import Welcome from '@/components/welcome';
import { useModel } from '@@/plugin-model/useModel';
import { Col, Row, Space, Spin } from 'antd';
import { EEnterpriseStatus } from '@/types/enterpriseService';
import BootAuthModule from '@/pages/enterpriseService/components/bootAuthModule';
import ServiceInfoModule from '@/pages/enterpriseService/components/serviceInfoModule';
import EnterpriseHead from '@/pages/enterpriseService/components/enterpriseHead';
import { noCertStatus } from '@/constatnt/enterprise';
import NavigateModule from '@/pages/enterpriseService/components/navigateModule';
import ContactFR from '@/pages/enterpriseService/components/contactFR';
import NoticeCard from '@/pages/enterpriseService/components/noticeCard';
import NoticeCardMobile from './components/noticeCard/mobile';
import { useMediaQuery } from 'react-responsive';
import { importQidian } from '@/utils/qidian';
import ServiceAbnormal from '@/pages/enterpriseService/components/serviceAbnormal';

const env = process.env.UMI_ENV;

export default function EnterpriseServicePage() {
  const initialData = useModel('@@initialState');
  const notPC = useMediaQuery({ query: '(max-width:  991.98px)' });

  const {
    enterpriseStatus,
    getEnterpriseInfoRun,
    getEnterpriseInfoRunning,
    getEnterpriseServiceStateRun,
    getEnterpriseServiceStateRunning,
    getServiceAuthInterfaceRun,
  } = useModel('useEnterpriseModel', (model) => ({
    enterpriseStatus: model.enterpriseStatus,
    getEnterpriseInfoRun: model.getEnterpriseInfoRun,
    getEnterpriseInfoRunning: model.getEnterpriseInfoRunning,
    getEnterpriseServiceStateRun: model.getEnterpriseServiceStateRun,
    getEnterpriseServiceStateRunning: model.getEnterpriseServiceStateRunning,
    getServiceAuthInterfaceRun: model.getServiceAuthInterfaceRun,
  }));

  useEffect(() => {
    if (env !== 'dev') {
      if (initialData.initialState?.uid) {
        importQidian(initialData.initialState.uid);
      }
    }

    getEnterpriseInfoRun();
    getEnterpriseServiceStateRun();
    getServiceAuthInterfaceRun();
  }, []);

  return (
    <Spin
      spinning={getEnterpriseInfoRunning || getEnterpriseServiceStateRunning}
      style={{ flex: 1 }}
    >
      {notPC && <NoticeCardMobile />}
      {enterpriseStatus !== undefined &&
      !getEnterpriseInfoRunning &&
      !getEnterpriseServiceStateRunning ? (
        <Space size={24} direction={'vertical'} style={{ display: 'flex' }}>
          {enterpriseStatus === EEnterpriseStatus.NOT_JOIN ? (
            <div style={{ width: '100%' }}>
              <Welcome
                type={'ENTERPRISE'}
                welcomeContent={'加入合作企业可解锁全部服务'}
                welcomeExtra={'您还未加入企业'}
              />
              <BootAuthModule enterpriseStatus={enterpriseStatus} />
            </div>
          ) : (
            <>
              <EnterpriseHead />
              {noCertStatus.includes(enterpriseStatus) ? (
                <BootAuthModule enterpriseStatus={enterpriseStatus} />
              ) : null}
            </>
          )}
          <div style={{ width: '100%' }}>
            <Row gutter={[24, 10]}>
              <Col md={24} xs={24} sm={24} lg={12} xl={12}>
                <ServiceInfoModule />
              </Col>
              <Col md={24} xs={24} sm={24} lg={12} xl={12}>
                <NoticeCard />
              </Col>
            </Row>
          </div>
          <NavigateModule />
          <ContactFR />
        </Space>
      ) : getEnterpriseInfoRunning ||
        getEnterpriseServiceStateRunning ||
        !initialData.initialState?.uid ? (
        <div style={{ height: 400 }}></div>
      ) : (
        <ServiceAbnormal />
      )}
    </Spin>
  );
}
