import React, { useEffect, useState } from 'react';
import styles from './index.less';
import { history, useModel, useRequest } from 'umi';
import { Button, Col, Input, Row, Space, Select } from 'antd';
import RecommendedContentModal from './pushModal';
import ProductInformationTable from './table';
import { getAllPushCreators } from '@/utils/requests/requests_push';

type Props = {};

const ProductInformation: React.FC<Props> = () => {
  const { initialState } = useModel('@@initialState');
  const [recommendedContentModalOpen, setRecommendedContentModalOpen] =
    useState(false);
  const [keyword, setKeyword] = useState<string | undefined>();
  const [createdByName, setCreatedByName] = useState<string | undefined>();
  const [shelfStatus, setShelfStatus] = useState<string | undefined>();
  const [refresh, setRefresh] = useState(0);
  const [creatorsOptions, setCreatorsOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const getAllPushCreatorsRequest = useRequest(
    () => {
      return getAllPushCreators();
    },
    {
      manual: true,
      onSuccess: (res) => {
        setCreatorsOptions(
          res.map((item: string) => ({
            value: item,
            label: item,
          })),
        );
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    getAllPushCreatorsRequest.run();
  }, []);

  return (
    <div className={styles.configure_permissions_div}>
      <div className={styles.configure_permissions_title}>
        <Row>
          <Col span={12} style={{ fontSize: '18px' }}>
            最新资讯发布
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            {(initialState.uid == '55549' ||
              initialState.uid == '672020' ||
              initialState.uid == '770092') && (
              <Space>
                <Button
                  type="link"
                  onClick={() => {
                    history.push('/news/manage_access');
                  }}
                >
                  权限管理
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    history.push('/news/manage_product');
                  }}
                >
                  产品标签管理
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    history.push('/news/manage_type');
                  }}
                >
                  内容类型管理
                </Button>
              </Space>
            )}
          </Col>
        </Row>
      </div>
      <div className={styles.configure_permissions_body}>
        <Row>
          <Col span={16}>
            <Input.Search
              placeholder="内容标题"
              style={{ width: 360 }}
              value={keyword}
              allowClear
              onSearch={(value) => setKeyword(value)}
              onPressEnter={(event: React.KeyboardEvent<HTMLInputElement>) => {
                setKeyword((event.target as HTMLInputElement).value);
              }}
            />
            <Select
              placeholder="全部创建人"
              mode="multiple"
              showSearch
              showArrow
              style={{ width: 140, marginLeft: '10px' }}
              options={creatorsOptions}
              maxTagCount="responsive"
              onChange={(value: any[]) => {
                setCreatedByName(value.toString());
              }}
            />
            <Select
              placeholder="请选择资讯状态"
              mode="multiple"
              showSearch
              showArrow
              style={{ width: 140, marginLeft: '10px' }}
              options={[
                { value: 1, label: '全部上架状态' },
                { value: -1, label: '全部下架状态' },
              ]}
              maxTagCount="responsive"
              onChange={(value: any[]) => {
                setShelfStatus(value.toString());
              }}
            />
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button
              className={styles.add_button}
              type="primary"
              onClick={() => {
                setRecommendedContentModalOpen(true);
              }}
            >
              新增
            </Button>
            <RecommendedContentModal
              recommendedContentModalOpen={recommendedContentModalOpen}
              setRecommendedContentModalOpen={setRecommendedContentModalOpen}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </Col>
        </Row>
        <ProductInformationTable
          keyword={keyword}
          createdByName={createdByName}
          shelfStatus={shelfStatus}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    </div>
  );
};

export default ProductInformation;
