import { requestGet } from './utils/request';
import './string.extensions';
import _ from 'lodash';

export function render(oldRender: () => {}) {
  oldRender();
}

// 初始化全局数据
export async function getInitialState() {
  // 初始化请求全局数据
  // 获取用户信息

  const getUserData = () => {
    return requestGet('/fcoin/api/fcoin/apply/user/info', {});
  };
  let userData = await getUserData();

  return Object.assign(
    userData.data,
    {},
    { isFrAccount: !_.isUndefined(userData.data.nameEn) },
  );
}

// 全局请求拦截
const requestInterceptor = (url: string, options: any) => {
  return {
    url: `${process.env.API_PREFIX}${url}`, // 此处可以添加域名前缀
    options: {
      ...options,
    },
  };
};

// 全局响应拦截
const responseInterceptor = (response: any, options: any) => {
  return response;
};

// 请求封装
export const request = {
  timeout: 10000,
  credentials: 'include', // 请求头携带 cookie
  errorConfig: {
    adaptor: (resData: { msg: any }) => {
      return {
        ...resData,
        errorMessage: resData.msg,
      };
    },
  },
  middlewares: [],
  requestInterceptors: [requestInterceptor],
  responseInterceptors: [responseInterceptor],
};
