import { requestGet, requestPut, requestPutJson } from '@/utils/request';

export function getEnterprises(params: { key: string }) {
  return requestGet(`/fcoin/api/company/query/option`, params);
}

export function getUserInfo(params: {
  key: string;
  type: string;
  limit: number;
}) {
  return requestGet(
    `/fcoin/api/fcoin/apply/authority/user/query/option`,
    params,
  );
}
export function addMember(params: any) {
  return requestPutJson(`/fcoin/api/company/user/move`, params);
}

export function getAddMemberAuthority(params: { type?: string }) {
  return requestGet('/fcoin/api/fcoin/apply/authority/get/sync/user', params);
}

export function getAuthority(params: any) {
  return requestGet(`/fcoin/api/fcoin/apply/authority/${params.id}`, params);
}

export function editorAuthority(id: any, params: any) {
  return requestPutJson(`/fcoin/api/fcoin/apply/authority/${id}`, params);
}
