import React, { useEffect, useState } from 'react';
import styles from './detailedRecommendation.less';
import { useRequest } from '@@/plugin-request/request';
import { getRecommendationCardsList } from '@/services/personalService';
import { IAdCard } from '@/types/personalService';
import { Carousel } from 'antd';
import LessonCard from './lessonCard';

type TDRProps = {};

const DetailedRecommendation: React.FC<TDRProps> = () => {
  const [adCardsList, setAdCardsList] = useState<IAdCard[]>([]);
  const [selectedKey, setSelectedKey] = useState<number>(0);

  const getCards = useRequest(
    () => {
      return getRecommendationCardsList();
    },
    {
      onSuccess: (res) => {
        setAdCardsList(res);
      },
    },
  );

  useEffect(() => {
    getCards.run();
  }, []);

  return (
    <>
      {adCardsList?.length > 0 ? (
        <div className={styles.card_wrapper}>
          <div>
            <Carousel
              autoplay
              afterChange={(currentSlide) => {
                setSelectedKey(currentSlide);
              }}
            >
              {adCardsList.map((item, index) => (
                <img key={index} src={item.cover_img} />
              ))}
            </Carousel>
          </div>
          <LessonCard details={adCardsList[selectedKey]} />
        </div>
      ) : null}
    </>
  );
};

export default DetailedRecommendation;
