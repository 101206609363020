import {
  history,
  IRoute,
  IRouteComponentProps,
  useDispatch,
  useModel,
} from 'umi';
import {
  ComponentWrapper,
  Feedback,
  MediaContextProvider,
  NavigationFooter,
  NavigationHeader,
} from '@digit-fe/digit-component';
import { useEffect, useRef, useState } from 'react';
import { message } from 'antd';
import cx from 'classnames';
import { delCookie, loginCas } from '@/utils/index';
import styles from './index.less';
import _ from 'lodash';

export default function BaseLayout(props: IRouteComponentProps) {
  const { children } = props;
  const pathName = window.location.pathname;
  const dispatch = useDispatch();
  const { initialState } = useModel('@@initialState');
  const ref = useRef<any>();
  const [activeType, setActiveType] = useState<
    'firstActive' | 'serviceActive' | undefined
  >();
  const initialStateRef = useRef(initialState);

  const createMenu = () => {
    if (
      _.isUndefined(initialState) ||
      (!_.isUndefined(initialState) && initialState.isFrAccount)
    ) {
      const navMenu: IRoute[] = [];
      const routes = props.route.routes;
      routes?.forEach((route) => {
        if (route.nav) {
          if (route.path === '/news') {
            navMenu.push({ ...route, path: '/news/add' });
          } else {
            navMenu.push(route);
          }
        } else {
          route.routes?.forEach((item) => {
            if (item.nav) {
              navMenu.push(item);
            }
          });
        }
      });
      return navMenu.map((item, index) => ({
        id: String(index),
        title: item.navTitle,
        path: item.path as string,
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (initialState) {
      dispatch({
        type: 'userInfo/save',
        payload: initialState,
      });
      initialStateRef.current = initialState;
    }
  }, [initialState]);

  useEffect(() => {
    if (['/', '/module', '/internal', '/resource'].includes(pathName)) {
      setActiveType('firstActive');
    } else if (['/service'].includes(pathName)) {
      setActiveType('serviceActive');
    }
  }, [pathName]);

  return (
    <MediaContextProvider>
      <div
        style={{ position: 'sticky', top: '0px', height: '48px', zIndex: '1' }}
      >
        <NavigationHeader
          navItem={[
            {
              label: '首页',
              href: 'https://home.fanruan.com/',
              isActive: false,
            },
            {
              label: '服务平台',
              href: 'https://service.fanruan.com/',
              isActive: false,
            },
            {
              label: '文档',
              href: 'https://help.fanruan.com/',
              isActive: false,
            },
            {
              label: '学院',
              href: 'https://edu.fanruan.com/',
              isActive: false,
            },
            {
              label: '问答',
              href: 'https://bbs.fanruan.com/wenda',
              isActive: false,
            },
            {
              label: '论坛',
              href: 'https://bbs.fanruan.com/topic',
              isActive: false,
            },
            {
              label: '认证',
              href: 'https://cert.fanruan.com/',
              isActive: false,
            },
            {
              label: '招聘',
              href: 'https://bbs.fanruan.com/jobs',
              isActive: false,
            },
            {
              label: '任务',
              href: 'https://bbs.fanruan.com/task',
              isActive: false,
            },
            {
              label: '市场',
              href: 'https://market.fanruan.com/',
              isActive: false,
            },
          ]}
          className={cx(
            styles.layout,
            activeType === 'serviceActive' && styles.service_active,
            activeType === 'firstActive' && styles.index_active,
          )}
          ref={ref}
          env={process.env.UMI_ENV === 'product' ? 'prod' : 'test'}
          menu={createMenu()}
          uid={initialState?.uid}
          username={initialState?.username}
          onMonitorChange={(data) => {
            console.log('监听到了通行证数据');
            console.log(data);
            if (!data?.uid) {
              delCookie('fr_token');
              message.success('您已成功退出登录');
              window.location.href = `${process.env.ID_URL}/login/token/logout?referrer=${window.location.href}`;
            } else {
              if (process.env.UMI_ENV !== 'dev') {
                if (!initialStateRef?.current?.uid) {
                  loginCas({
                    env: process.env.UMI_ENV === 'product' ? 'prod' : 'test',
                  });
                }
              }
            }
          }}
          onLogoClick={() => {
            history.push('/');
          }}
          onLogin={() => {
            loginCas({
              env: process.env.UMI_ENV === 'product' ? 'prod' : 'test',
            });
          }}
          onLogout={() => {
            delCookie('fr_token');
            message.success('您已成功退出登录');
            window.location.href = `${process.env.ID_URL}/login/token/logout?referrer=${window.location.href}`;
          }}
          onMenuClick={(data) => {
            console.log(data);
            history.push(data.path);
            ref.current?.closeModal();
          }}
        />
      </div>

      <div className={styles.page}>
        {children}
        <ComponentWrapper>
          <Feedback
            onDataChange={(isFiners, uid, token, mobile) => {
              const tempMobile = mobile || '';
              if (isFiners) {
                return `https://pe9qbh7c.shuzhiniao.com/web/#/h5/business/brd?embedded=true&modal=true&hideRootMenu=true&spaceId=11093&%E7%B1%BB%E5%9E%8B=37047&%E5%8F%8D%E9%A6%88%E4%BA%BAUID=${uid}&%E9%A1%B5%E9%9D%A2URL=${encodeURIComponent(
                  window.location.href,
                )}&%E5%8F%8D%E9%A6%88%E4%BA%BA%E6%89%8B%E6%9C%BA%E5%8F%B7=${tempMobile}&hiddenFields=%E9%A1%B5%E9%9D%A2URL%2C%E5%8F%8D%E9%A6%88%E4%BA%BAUID%2C%E7%B1%BB%E5%9E%8B%2C%E5%A4%84%E7%90%86%E4%BA%BA%E5%9B%9E%E5%A4%8D`;
              }
              return `https://pe9qbh7c.shuzhiniao.com/web/#/ticket/a2555f84970f418a98960adb4029aed1/form?embedded=true&hiddenFields=%E9%A1%B5%E9%9D%A2URL%2C%E5%8F%8D%E9%A6%88%E4%BA%BAUID%2C%E5%A4%84%E7%90%86%E4%BA%BA%E5%9B%9E%E5%A4%8D&ticketToken=${token}`;
            }}
            uid={initialState?.uid}
            env={process.env.UMI_ENV === 'product' ? 'prod' : 'test'}
          />
        </ComponentWrapper>
        <NavigationFooter />
      </div>
    </MediaContextProvider>
  );
}
