import React, { useEffect, useRef, useState } from 'react';
import styles from './index.less';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Upload,
  UploadFile,
} from 'antd';
import {
  calFileUploadParams,
  getCoverBase64,
  getFileNameAndType,
  randomStr,
} from '@/utils';
import { useModel, useRequest } from 'umi';
import {
  postBuried,
  submitFile,
  submitFileALi,
} from '@/utils/requests/requests_utils';
import { RcFile } from 'antd/es/upload';
import axios from 'axios';
import { EditOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { ComponentWrapper } from '@digit-fe/digit-component';
import { Dialog, Popover } from 'antd-mobile';
import { Action } from 'antd-mobile/es/components/popover';
import {
  EEnterpriseStatus,
  EEnterpriseUserIdentify,
} from '@/types/enterpriseService';
import {
  hasManagePermission,
  hasSuperManagePermission,
  isOrdinaryMember,
} from '@/utils/enterprise';
import { updateEnterpriseName } from '@/services/enterpriseService';
import { putObject } from '@/utils/fileOSS';
import dayjs from 'dayjs';
import { updateAvatar } from '@/utils/requests/requests_enterprise';

type Props = {};

// 未开通服务的状态
const notActivatedStatus = [
  EEnterpriseStatus.NOT_CERT,
  EEnterpriseStatus.UNDER_REVIEW,
  EEnterpriseStatus.NOT_PASS,
];

const EnterpriseHead: React.FC<Props> = ({}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false);
  const [coverImage, setCoverImage] = useState(
    'https://hwobs.fanruan.com/ui/home/icons/enterprise_avatar.png',
  );

  const {
    enterpriseStatus,
    role,
    enterpriseInfo,
    updateEnterpriseInfo,
    enterpriseRealNameAuth,
  } = useModel('useEnterpriseModel', (model) => ({
    enterpriseStatus: model.enterpriseStatus,
    role: model.role,
    enterpriseInfo: model.enterpriseInfo,
    updateEnterpriseInfo: model.updateEnterpriseInfo,
    enterpriseRealNameAuth: model.enterpriseRealNameAuth,
  }));

  const [form] = Form.useForm();

  // 企业名称长度限制
  const enterpriseNameMaxLength = 100;

  // 修改企业名称
  const { run: updateEnterpriseNameRun } = useRequest(
    (newName: string) => {
      return updateEnterpriseName(newName);
    },
    {
      manual: true,
      onSuccess: (res, params: any) => {
        updateEnterpriseInfo({ enterpriseName: params[0] });
        message.success('修改成功');
        setIsEditNameModalOpen(false);
      },
    },
  );
  const saveEnterpriseName = () => {
    form
      .validateFields()
      .then((values) => {
        updateEnterpriseNameRun(values.name);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  // 企业服务头部埋点
  const { run: postBuriedRun } = useRequest(
    (menu: string) => {
      return postBuried({
        portal: '服务平台',
        classification: '企业操作',
        menu,
      });
    },
    {
      manual: true,
    },
  );

  // 上传头像
  const submitFileRequest = useRequest(
    (fileName: string, contentType: string) => {
      return submitFile({ path: 'myfr', fileName, contentType });
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  useEffect(() => {
    if (enterpriseInfo?.logo) {
      setCoverImage(enterpriseInfo.logo);
    }
  }, [enterpriseInfo]);

  // 提示语1
  const tips: { [key in EEnterpriseStatus]?: string } = {
    [EEnterpriseStatus.NOT_CERT]:
      '您管理的企业未完成合作认证，进行合作认证后解锁全部企业服务',
    [EEnterpriseStatus.UNDER_REVIEW]: '您管理的企业合作认证还在审核中',
    [EEnterpriseStatus.NOT_PASS]: '您管理的企业合作认证审核不通过',
  };

  // 企业操作相关链接
  const opProps = {
    invite: {
      link: `${process.env.ID_URL}/#/enterprise?eop=invite`,
      text: '邀请加入',
    },
    import: {
      link: `${process.env.ID_URL}/#/enterprise?eop=import`,
      text: '导入成员',
    },
    transfer: {
      link: `${process.env.ID_URL}/#/enterprise?eop=transfer`,
      text: '移交企业',
    },
    dissolve: {
      link: `${process.env.ID_URL}/#/enterprise?eop=dissolve`,
      text: '解散企业',
    },
    enterprise: {
      link: `${process.env.ID_URL}/#/enterprise`,
      text: '我的企业',
    },
    exit: {
      link: `${process.env.ID_URL}/#/enterprise?eop=exit`,
      text: '退出企业',
    },
  };

  const NotPCButton: React.FC<{
    op: 'invite' | 'transfer' | 'dissolve' | 'enterprise' | 'exit' | 'import';
    isDanger?: boolean;
  }> = ({ op, isDanger = false }) => (
    <Button
      style={{
        height: '40px',
        width: '132px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      type="link"
      danger={isDanger}
      href={opProps[op].link}
      target={'_blank'}
      onClick={() => {
        postBuriedRun(opProps[op].text);
      }}
    >
      {opProps[op].text}
    </Button>
  );

  const opActions: { [key in EEnterpriseUserIdentify]: Action[] } = {
    [EEnterpriseUserIdentify.SUPER_ADMIN]: enterpriseRealNameAuth
      ? [
          {
            key: '1',
            text: <NotPCButton op={'invite'} />,
          },
          {
            key: '2',
            text: <NotPCButton op={'import'} />,
          },
          {
            key: '3',
            text: <NotPCButton op={'transfer'} />,
          },
          {
            key: '4',
            text: <NotPCButton op={'dissolve'} isDanger={true} />,
          },
        ]
      : [
          {
            key: '0',
            text: (
              <Button style={{ height: '40px', width: '132px' }} type="link">
                修改企业名称
              </Button>
            ),
            onClick: () => {
              Dialog.show({
                title: '修改企业名称',
                content: (
                  <div>
                    <Form
                      form={form}
                      initialValues={{ name: enterpriseInfo?.enterpriseName }}
                    >
                      <Form.Item
                        name={'name'}
                        rules={[{ required: true, message: '请输入企业名称' }]}
                      >
                        <Input
                          placeholder={'请输入企业名称'}
                          height={36}
                          width={256}
                          maxLength={enterpriseNameMaxLength}
                        />
                      </Form.Item>
                    </Form>
                    <div
                      style={{
                        width: '256px',
                        color: '#AAAFBB',
                        lineHeight: '22px',
                        marginTop: '16px',
                      }}
                    >
                      注：如需要更换企业，请移交或解散企业后重新进行合作认证，仅修改名称无法修改跟帆软的合作关系。
                    </div>
                  </div>
                ),
                closeOnAction: true,
                closeOnMaskClick: true,
                actions: [
                  [
                    {
                      key: 'cancel',
                      text: '取消',
                    },
                    {
                      key: 'confirm',
                      text: '确定',
                      bold: true,
                      onClick: saveEnterpriseName,
                    },
                  ],
                ],
              });
              postBuriedRun('修改企业名称');
            },
          },
          {
            key: '1',
            text: <NotPCButton op={'invite'} />,
          },
          {
            key: '2',
            text: <NotPCButton op={'import'} />,
          },
          {
            key: '3',
            text: <NotPCButton op={'transfer'} />,
          },
          {
            key: '4',
            text: <NotPCButton op={'dissolve'} isDanger={true} />,
          },
        ],
    [EEnterpriseUserIdentify.ADMIN]: enterpriseRealNameAuth
      ? [
          {
            key: '1',
            text: <NotPCButton op={'invite'} />,
          },
          {
            key: '2',
            text: <NotPCButton op={'import'} />,
          },
        ]
      : [
          {
            key: '0',
            text: (
              <Button style={{ height: '40px', width: '132px' }} type="link">
                修改企业名称
              </Button>
            ),
            onClick: () => {
              Dialog.show({
                title: '修改企业名称',
                content: (
                  <div>
                    <Form
                      form={form}
                      initialValues={{ name: enterpriseInfo?.enterpriseName }}
                    >
                      <Form.Item
                        name={'name'}
                        rules={[{ required: true, message: '请输入企业名称' }]}
                      >
                        <Input
                          placeholder={'请输入企业名称'}
                          height={36}
                          width={256}
                          maxLength={enterpriseNameMaxLength}
                        />
                      </Form.Item>
                    </Form>
                    <div
                      style={{
                        width: '256px',
                        color: '#AAAFBB',
                        lineHeight: '22px',
                        marginTop: '16px',
                      }}
                    >
                      注：如需要更换企业，请移交或解散企业后重新进行合作认证，仅修改名称无法修改跟帆软的合作关系。
                    </div>
                  </div>
                ),
                closeOnAction: true,
                closeOnMaskClick: true,
                actions: [
                  [
                    {
                      key: 'cancel',
                      text: '取消',
                    },
                    {
                      key: 'confirm',
                      text: '确定',
                      bold: true,
                      onClick: saveEnterpriseName,
                    },
                  ],
                ],
              });
            },
          },
          {
            key: '1',
            text: <NotPCButton op={'invite'} />,
          },
          {
            key: '2',
            text: <NotPCButton op={'import'} />,
          },
        ],
    [EEnterpriseUserIdentify.MEMBER]: [
      {
        key: '0',
        text: <NotPCButton op={'enterprise'} />,
      },
      {
        key: '1',
        text: <NotPCButton op={'exit'} />,
      },
    ],
  };

  const submitFileALiRequest = useRequest(
    () => {
      return submitFileALi();
    },
    {
      manual: true,
    },
  );

  const updateAvatarRequest = useRequest(
    (logo: string) => {
      return updateAvatar({ logo });
    },
    {
      manual: true,
    },
  );

  const ref = useRef<any>();
  const refNoAuth = useRef<any>();
  const refMobile = useRef<any>();
  const refNoAuthMobile = useRef<any>();

  return (
    <>
      <ComponentWrapper>
        <div className={styles.enterprise_head}>
          {hasManagePermission(role) ? (
            <Upload
              className={styles.update_avatar}
              name="avatar"
              listType="picture-card"
              accept="image/png, image/jpeg, image/jpg"
              fileList={fileList}
              showUploadList={false}
              customRequest={(file) => {
                submitFileALiRequest.run().then(async (data) => {
                  let avatarInfo: {
                    name: string | null;
                    path: string | null;
                  } = await putObject(
                    data,
                    file.file,
                    `companyLogo/${dayjs().format('YYYYMMDD')}/`,
                  );
                  updateAvatarRequest
                    .run(avatarInfo.path as string)
                    .then(() => {
                      setCoverImage(
                        `https://finepassport.oss-cn-hangzhou.aliyuncs.com/${avatarInfo.path}`,
                      );
                    });
                });
              }}
              beforeUpload={(file: RcFile) => {
                const isJpgOrPng =
                  file.type === 'image/png' ||
                  file.type === 'image/jpeg' ||
                  file.type === 'image/jpg';
                if (!isJpgOrPng) {
                  message.error('请选择.png.jpeg.jpg格式的文件');
                }
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                  message.error('文件大小不超过10MB');
                }
                return isJpgOrPng && isLt2M;
              }}
            >
              <div
                className={styles.avatar}
                onClick={() => {
                  postBuriedRun('上传头像');
                }}
              >
                <div className={styles.avatar_mask}>点击上传</div>
                <img
                  className={styles.avatar_pic}
                  src={coverImage}
                  ref={ref}
                  onError={() => {
                    ref.current.src =
                      'https://hwobs.fanruan.com/ui/home/icons/enterprise_avatar.png';
                  }}
                />
              </div>
            </Upload>
          ) : (
            <img
              className={styles.avatar_pic}
              src={coverImage}
              ref={refNoAuth}
              onError={() => {
                refNoAuth.current.src =
                  'https://hwobs.fanruan.com/ui/home/icons/enterprise_avatar.png';
              }}
            />
          )}
          <div
            style={{ height: '80px', display: 'flex', alignItems: 'center' }}
          >
            <div className={styles.enterprise_head_detail}>
              <div className={styles.enterprise_head_title}>
                {enterpriseInfo?.enterpriseName}
                {hasManagePermission(role) && !enterpriseRealNameAuth ? (
                  <Button
                    type={'text'}
                    onClick={() => {
                      postBuriedRun('修改企业名称');
                      setIsEditNameModalOpen(true);
                    }}
                    style={{ marginLeft: 20 }}
                    icon={
                      <EditOutlined
                        style={{
                          color: '#8097ad',
                          fontSize: '20px',
                        }}
                      />
                    }
                  />
                ) : null}
                <Modal
                  title="修改企业名称"
                  open={isEditNameModalOpen}
                  okText="保存"
                  onOk={saveEnterpriseName}
                  cancelText="取消"
                  onCancel={() => setIsEditNameModalOpen(false)}
                  width={540}
                >
                  <Form
                    form={form}
                    initialValues={{ name: enterpriseInfo?.enterpriseName }}
                  >
                    <Form.Item
                      name={'name'}
                      rules={[{ required: true, message: '请输入企业名称' }]}
                    >
                      <Input
                        placeholder={'请输入企业名称'}
                        height={32}
                        maxLength={enterpriseNameMaxLength}
                      />
                    </Form.Item>
                  </Form>
                  <div
                    style={{
                      color: '#AAAFBB',
                      lineHeight: '22px',
                      marginTop: '16px',
                    }}
                  >
                    注：如需要更换企业，请移交或解散企业后重新进行合作认证，仅修改名称无法修改跟帆软的合作关系。
                  </div>
                </Modal>
              </div>
              <div className={styles.enterprise_head_subtitle}>
                {enterpriseStatus !== undefined &&
                notActivatedStatus.includes(enterpriseStatus) ? (
                  <span style={{ marginRight: 24 }}>
                    {hasManagePermission(role)
                      ? tips[enterpriseStatus]
                      : '您加入的企业未完成合作认证，需企业管理员进行认证后解锁全部企业服务'}
                  </span>
                ) : null}
                <Space size={20}>
                  {hasManagePermission(role) ? (
                    <a
                      href={opProps['invite'].link}
                      target={'_blank'}
                      onClick={() => {
                        postBuriedRun(opProps['invite'].text);
                      }}
                    >
                      {opProps['invite'].text}
                    </a>
                  ) : null}
                  {hasManagePermission(role) ? (
                    <a
                      href={opProps['import'].link}
                      target={'_blank'}
                      onClick={() => {
                        postBuriedRun(opProps['import'].text);
                      }}
                    >
                      {opProps['import'].text}
                    </a>
                  ) : null}
                  {hasSuperManagePermission(role) ? (
                    <>
                      <a
                        href={opProps['transfer'].link}
                        target={'_blank'}
                        onClick={() => {
                          postBuriedRun(opProps['transfer'].text);
                        }}
                      >
                        {opProps['transfer'].text}
                      </a>
                      <a
                        href={opProps['dissolve'].link}
                        target={'_blank'}
                        onClick={() => {
                          postBuriedRun(opProps['dissolve'].text);
                        }}
                      >
                        {opProps['dissolve'].text}
                      </a>
                    </>
                  ) : null}
                  {isOrdinaryMember(role) ? (
                    <>
                      <a
                        href={opProps['enterprise'].link}
                        target={'_blank'}
                        onClick={() => {
                          postBuriedRun(opProps['enterprise'].text);
                        }}
                      >
                        {opProps['enterprise'].text}
                      </a>
                      <a
                        href={opProps['exit'].link}
                        target={'_blank'}
                        onClick={() => {
                          postBuriedRun(opProps['exit'].text);
                        }}
                      >
                        {opProps['exit'].text}
                      </a>
                    </>
                  ) : null}
                </Space>
              </div>
            </div>
          </div>
        </div>
      </ComponentWrapper>
      <ComponentWrapper mode="notPC">
        <div className={styles.enterprise_head_mobile}>
          {hasManagePermission(role) ? (
            <Upload
              className={styles.update_avatar_mobile}
              name="avatar"
              listType="picture-card"
              accept="image/png, image/jpeg, image/jpg"
              fileList={fileList}
              showUploadList={false}
              customRequest={(info) => {
                let fileInfo = getFileNameAndType((info.file as RcFile).name);
                let uploadParams = calFileUploadParams(fileInfo.type, 'myfr');
                submitFileRequest
                  .run(uploadParams.fileName, (info.file as RcFile).type)
                  .then((res) => {
                    axios.request({
                      method: 'PUT',
                      url: res.signedUrl,
                      withCredentials: false,
                      headers:
                        Object.assign(res.actualSignedRequestHeaders, {
                          'Content-Disposition': `attachment; filename=${encodeURIComponent(
                            (info.file as RcFile).name,
                          )}`,
                        }) || {},
                      data: info.file,
                    });
                  })
                  .then(() => {
                    getCoverBase64(info.file, (imageUrl: any) => {
                      setCoverImage(imageUrl);
                    });
                    setFileList([
                      {
                        uid: randomStr(7),
                        name: (info.file as RcFile)?.name,
                        url: `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`,
                        status: 'done',
                      },
                    ]);
                    postBuriedRun('上传头像');
                  });
              }}
              beforeUpload={(file: RcFile) => {
                const isJpgOrPng =
                  file.type === 'image/png' ||
                  file.type === 'image/jpeg' ||
                  file.type === 'image/jpg';
                if (!isJpgOrPng) {
                  message.error('请选择.png.jpeg.jpg格式的文件');
                }
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                  message.error('文件大小不超过10MB');
                }
                return isJpgOrPng && isLt2M;
              }}
            >
              <div
                className={styles.avatar_mobile}
                onClick={() => {
                  postBuriedRun('上传头像');
                }}
              >
                <div className={styles.avatar_mask_mobile}>点击上传</div>
                <img
                  className={styles.avatar_pic_mobile}
                  src={coverImage}
                  ref={refMobile}
                  onError={() => {
                    refMobile.current.src =
                      'https://hwobs.fanruan.com/ui/home/icons/enterprise_avatar.png';
                  }}
                />
              </div>
            </Upload>
          ) : (
            <img
              className={styles.avatar_pic_mobile}
              src={coverImage}
              ref={refNoAuthMobile}
              onError={() => {
                refNoAuthMobile.current.src =
                  'https://hwobs.fanruan.com/ui/home/icons/enterprise_avatar.png';
              }}
            />
          )}

          <div className={styles.enterprise_head_title_mobile}>
            {enterpriseInfo?.enterpriseName}
          </div>
          <Popover.Menu
            actions={opActions[role]}
            placement="bottom-start"
            trigger="click"
          >
            <UnorderedListOutlined
              className={styles.enterprise_head_operator_mobile}
            />
          </Popover.Menu>
        </div>
      </ComponentWrapper>
    </>
  );
};

export default EnterpriseHead;
