import React from 'react';
import styles from './index.less';
import { ComponentWrapper } from '@digit-fe/digit-component';
import BootAuthButton from '@/pages/enterpriseService/components/bootAuthModule/bootAuthButton';
import { EEnterpriseStatus } from '@/types/enterpriseService';
import {
  EBootAuthStatus,
  IBootAuthStep,
} from '@/types/enterpriseService/bootAuth';
import { Space } from 'antd';
import { useModel } from '@@/plugin-model/useModel';
import { hasManagePermission } from '@/utils/enterprise';

type TBACProps = {
  item: IBootAuthStep;
  identify?: 'developer' | 'leader';
};

const BootAuthCard: React.FC<TBACProps> = ({ item, identify }) => {
  const { enterpriseStatus, role } = useModel(
    'useEnterpriseModel',
    (model) => ({
      enterpriseStatus: model.enterpriseStatus,
      role: model.role,
    }),
  );

  // 检查当前步骤状态
  const checkStepStatusRes = checkFinishedSteps(
    enterpriseStatus,
    item.stepStatus,
  );

  const statusText: { [key in EBootAuthStatus]: () => void } = {
    [EBootAuthStatus.UNFINISHED]: () => null,
    [EBootAuthStatus.IN_PROGRESS]: () =>
      enterpriseStatus === EEnterpriseStatus.UNDER_REVIEW &&
      hasManagePermission(role) ? (
        <span className={styles.fw400} style={{ color: '#ffa800' }}>
          审核中
        </span>
      ) : null,
    [EBootAuthStatus.FINISHED]: () => (
      <span className={styles.fw400} style={{ color: '#00c147' }}>
        {hasManagePermission(role) ? '已提交' : '已完成'}
      </span>
    ),
    [EBootAuthStatus.NOT_PASS]: () =>
      hasManagePermission(role) ? (
        <span className={styles.fw400} style={{ color: '#f53f3f' }}>
          不通过
        </span>
      ) : null,
  };

  return (
    <div
      className={styles.auth_card_item}
      style={{
        opacity: checkStepStatusRes === EBootAuthStatus.UNFINISHED ? 0.5 : 1,
      }}
    >
      <div className={styles.img_wrapper}>
        <img src={item.bootImage} />
      </div>
      <Space size={8} className={styles.item_title}>
        <span>{item.title}</span>
        {statusText[checkStepStatusRes]!()}
      </Space>
      <div className={styles.item_subtitle}>{item.subTitle}</div>
      {item.extraButton ? (
        <ComponentWrapper mode={'pc'} className={styles.mgt8}>
          <BootAuthButton identify={identify} />
        </ComponentWrapper>
      ) : null}
    </div>
  );
};

export default BootAuthCard;

// 判断当前状态已完成哪些步骤
const checkFinishedSteps = (
  currentStatus: EEnterpriseStatus | undefined,
  stepStatus: EEnterpriseStatus,
) => {
  if (currentStatus !== undefined) {
    switch (currentStatus) {
      case EEnterpriseStatus.NOT_JOIN:
        switch (stepStatus) {
          case EEnterpriseStatus.NOT_JOIN:
            return EBootAuthStatus.IN_PROGRESS;
          case EEnterpriseStatus.NOT_CERT: {
            return EBootAuthStatus.IN_PROGRESS;
          }
          default:
            return EBootAuthStatus.UNFINISHED;
        }
        return EBootAuthStatus.UNFINISHED;
      case EEnterpriseStatus.NOT_CERT: {
        switch (stepStatus) {
          case EEnterpriseStatus.NOT_JOIN:
            return EBootAuthStatus.FINISHED;
          case EEnterpriseStatus.NOT_CERT:
            return EBootAuthStatus.IN_PROGRESS;
          default:
            return EBootAuthStatus.UNFINISHED;
        }
      }
      case EEnterpriseStatus.UNDER_REVIEW: {
        switch (stepStatus) {
          case EEnterpriseStatus.NOT_JOIN:
            return EBootAuthStatus.FINISHED;
          case EEnterpriseStatus.NOT_CERT:
            return EBootAuthStatus.FINISHED;
          case EEnterpriseStatus.UNDER_REVIEW:
            return EBootAuthStatus.IN_PROGRESS;
          default:
            return EBootAuthStatus.UNFINISHED;
        }
      }
      case EEnterpriseStatus.NOT_PASS: {
        switch (stepStatus) {
          case EEnterpriseStatus.NOT_JOIN:
            return EBootAuthStatus.FINISHED;
          case EEnterpriseStatus.NOT_CERT:
            return EBootAuthStatus.FINISHED;
          case EEnterpriseStatus.UNDER_REVIEW:
            return EBootAuthStatus.NOT_PASS;
          case EEnterpriseStatus.NOT_PASS:
            return EBootAuthStatus.NOT_PASS;
          default:
            return EBootAuthStatus.UNFINISHED;
        }
      }
      default:
        return EBootAuthStatus.UNFINISHED;
    }
  } else {
    return EBootAuthStatus.UNFINISHED;
  }
};
