import React, { useState } from 'react';
import { message, Upload } from 'antd';
import { RcFile } from 'antd/es/upload/index';
import {
  calFileUploadParams,
  getCoverBase64,
  getFileNameAndType,
} from '@/utils/index';
import axios from 'axios/index';
import styles from './index.less';
import { useRequest } from '@@/plugin-request/request';
import { submitFile } from '@/utils/requests/requests_utils';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

type TUploadIcon = {
  onChange?: (data: string) => void;
  value?: string;
};

const UploadIcon: React.FC<TUploadIcon> = (props) => {
  const { onChange, value } = props;
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>(value);
  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('只能上传png格式的图片');
    }
    const isLt2M = file.size / 1024 < 500;
    if (!isLt2M) {
      message.error('图片大小不能超过500KB');
    }
    return isJpgOrPng && isLt2M;
  };
  const submitFileRequest = useRequest(
    (fileName, contentType) => {
      return submitFile({ path: 'myfr', fileName, contentType });
    },
    {
      manual: true,
    },
  );

  return (
    <div>
      <Upload
        name="avatar"
        listType="picture-card"
        className={styles.upload_icon_wrapper}
        accept={'.png'}
        showUploadList={false}
        customRequest={(info) => {
          let fileInfo = getFileNameAndType((info.file as RcFile).name);
          let uploadParams = calFileUploadParams(fileInfo.type, 'myfr');
          setLoading(true);
          submitFileRequest
            .run(uploadParams.fileName, (info.file as RcFile).type)
            .then((res) => {
              console.log('res是');
              console.log(res);
              axios.request({
                method: 'PUT',
                url: res.signedUrl,
                withCredentials: false,
                headers:
                  Object.assign(res.actualSignedRequestHeaders, {
                    'Content-Disposition': `attachment; filename=${encodeURIComponent(
                      (info.file as RcFile).name,
                    )}`,
                  }) || {},
                data: info.file,
              });
            })
            .then(() => {
              onChange?.(
                `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`,
              );
              //setImageUrl(
              //  `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`,
              //);
              getCoverBase64(info.file, (imageUrl: any) => {
                setLoading(false);
                setImageUrl(imageUrl);
              });
            });
        }}
        beforeUpload={beforeUpload}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{ width: '100%', height: '118px' }}
          />
        ) : (
          <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>
              <div className={styles.tips}>上传卡片logo</div>
              <div className={styles.tips}>尺寸96*96px</div>
            </div>
          </div>
        )}
      </Upload>
    </div>
  );
};
export default UploadIcon;
