import React, { useEffect, useState } from 'react';
import styles from './bbsCard.less';
import { ArrowRightOutlined, TeamOutlined } from '@ant-design/icons';
import { useRequest } from 'umi';
import {
  getAllAnswerNum,
  getBiAnswerNum,
  getCertNum,
  getCorpNum,
  getEduVideoNum,
  getForumPostAnswerNum,
  getFrAnswerNum,
  getJdyAnswerNum,
  getJobNum,
} from '@/utils/requests/requests_home';
import { cul } from '@/utils';
import { postBuried } from '@/utils/requests/requests_utils';
import classNames from 'classnames';
import { IPersonalData } from '@/types/personalService';
import CountUp from 'react-countup';
import { ReactComponent as BankOutlined } from '@/static/images/bank-outlined.svg';
import { ReactComponent as CrownOutlined } from '@/static/images/crown-outlined.svg';

type props = {};

const BBSCard: React.FC<props> = () => {
  const [personalData, setPersonalData] = useState<IPersonalData[]>([
    {
      title: '注册用户数',
      value: 0,
      icon: <TeamOutlined style={{ fontSize: 16, color: '#0082fc' }} />,
    },
    {
      title: '认证企业数',
      value: 0,
      icon: <BankOutlined style={{ fontSize: 16 }} />,
      href: `${process.env.SERVICE_URL}`,
    },
    {
      title: '职业认证数',
      value: 0,
      icon: <CrownOutlined style={{ fontSize: 16 }} />,
      href: `${process.env.CERT}`,
    },
  ]);
  const [allAnswerNum, setAllAnswerNum] = useState(0);
  const [forumPostAnswerNum, setForumPostAnswerNum] = useState<number>(0);
  const [eduVideoNum, setEduVideoNum] = useState<number>(0);
  const [jobNum, setJobNum] = useState<number>(0);

  const [professionalNum, setProfessionalNum] = useState(0);
  const [registeredUserNum, setRegisteredUserNum] = useState(0);
  const [cooperateCompanyNum, setCooperateCompanyNum] = useState(0);

  const getCertNumRun = useRequest(
    () => {
      return getCertNum();
    },
    {
      onSuccess: (res) => {
        setProfessionalNum(res.basic_num + res.professional_num);
      },
    },
  );

  useEffect(() => {
    getCertNumRun.run();
  }, []);

  const getCorpNumRun = useRequest(
    () => {
      return getCorpNum();
    },
    {
      onSuccess: (res) => {
        setRegisteredUserNum(res.registeredUserNumber);
        setCooperateCompanyNum(res.cooperateCompanyNumber);
      },
    },
  );

  useEffect(() => {
    getCorpNumRun.run();
  }, []);

  useEffect(() => {
    setPersonalData([
      {
        title: '注册用户数',
        value: registeredUserNum,
        icon: <TeamOutlined style={{ fontSize: 16, color: '#0082fc' }} />,
      },
      {
        title: '认证企业数',
        value: cooperateCompanyNum,
        icon: <BankOutlined style={{ fontSize: 16 }} />,
        href: `${process.env.SERVICE_URL}`,
      },
      {
        title: '职业认证数',
        value: professionalNum,
        icon: <CrownOutlined style={{ fontSize: 16 }} />,
        href: `${process.env.CERT}`,
      },
    ]);
  }, [professionalNum, registeredUserNum, cooperateCompanyNum]);

  useRequest(
    () => {
      return getAllAnswerNum();
    },
    {
      onSuccess: (res) => {
        setAllAnswerNum(res);
      },
      onError: (e) => {},
    },
  );

  useRequest(
    () => {
      return getForumPostAnswerNum();
    },
    {
      onSuccess: (res) => {
        setForumPostAnswerNum(res);
      },
      onError: (e) => {},
    },
  );

  useRequest(
    () => {
      return getEduVideoNum();
    },
    {
      onSuccess: (res) => {
        setEduVideoNum(res);
      },
      onError: (e) => {},
    },
  );

  useRequest(
    () => {
      return getJobNum();
    },
    {
      onSuccess: (res) => {
        setJobNum(res);
      },
      onError: (e) => {},
    },
  );

  // 埋点
  const { run: postBuriedRun } = useRequest(
    (menu: string) => {
      return postBuried({
        portal: '最新资讯',
        classification: '活跃看板',
        menu,
      });
    },
    {
      manual: true,
    },
  );

  return (
    <div className={styles.bbs_card}>
      <div className={classNames(styles.data_information)}>
        {personalData.map((item, index) => (
          <DataPanel item={item} key={index} />
        ))}
      </div>
      <div
        className={styles.bbs_item}
        style={{ borderBottom: '1px solid #D6DFE7' }}
        onClick={() => {
          postBuriedRun('互助问答').then(() => {
            window.open(`${process.env.BBS_URL}/wenda`);
          });
        }}
      >
        <img
          className={styles.bbs_icon}
          src={require('@/static/images/fanruan-ask.png')}
        />
        <div className={styles.bbs_title}>互助问答</div>
        <div className={styles.bbs_description}>
          {cul(`${allAnswerNum}`)} 个问题被解决
          <ArrowRightOutlined style={{ marginLeft: '8px' }} />
        </div>
      </div>
      <div
        className={styles.bbs_item}
        style={{ borderBottom: '1px solid #D6DFE7' }}
        onClick={() => {
          postBuriedRun('论坛交流').then(() => {
            window.open(`${process.env.BBS_URL}/topic`);
          });
        }}
      >
        <img
          className={styles.bbs_icon}
          src={require('@/static/images/fanruan-topic.png')}
        />
        <div className={styles.bbs_title}>论坛交流</div>
        <div className={styles.bbs_description}>
          {cul(`${forumPostAnswerNum}`)} 个帖子
          <ArrowRightOutlined style={{ marginLeft: '8px' }} />
        </div>
      </div>
      <div
        className={styles.bbs_item}
        style={{ borderBottom: '1px solid #D6DFE7' }}
        onClick={() => {
          postBuriedRun('帆软学院').then(() => {
            window.open(`${process.env.EDU}`);
          });
        }}
      >
        <img
          className={styles.bbs_icon}
          src={require('@/static/images/fanruan-edu.png')}
        />
        <div className={styles.bbs_title}>帆软学院</div>
        <div className={styles.bbs_description}>
          {cul(`${eduVideoNum}`)} 个课程
          <ArrowRightOutlined style={{ marginLeft: '8px' }} />
        </div>
      </div>
      <div
        className={styles.bbs_item}
        onClick={() => {
          postBuriedRun('求职招聘').then(() => {
            window.open(`${process.env.BBS_URL}/jobs`);
          });
        }}
      >
        <img
          className={styles.bbs_icon}
          src={require('@/static/images/fanruan-job.png')}
        />
        <div className={styles.bbs_title}>求职招聘</div>
        <div className={styles.bbs_description}>
          {cul(`${jobNum}`)} 个职位
          <ArrowRightOutlined style={{ marginLeft: '8px' }} />
        </div>
      </div>
    </div>
  );
};

export default BBSCard;

const DataPanel: React.FC<{ item: IPersonalData }> = ({ item }) => {
  return (
    <div
      className={
        item.href ? styles.data_panel_item_href : styles.data_panel_item
      }
      onClick={() => {
        item.href && window.open(item.href);
      }}
    >
      <div>{item.icon}</div>
      <div
        className={classNames(styles.layout_vertical, styles.mgl4)}
        style={{ width: '80px' }}
      >
        <div
          className={styles.data_panel_item_title}
          style={{ textAlign: 'center' }}
        >
          {item.title}
        </div>
        <div
          className={styles.data_panel_item_num}
          style={{ textAlign: 'center' }}
        >
          <CountUp
            start={0}
            end={Number(item?.value ? item?.value : 0)}
            enableScrollSpy={true}
          />
        </div>
      </div>
    </div>
  );
};
