import {
  IRouteComponentProps,
  Link,
  useLocation,
  useRequest,
  useModel,
} from 'umi';
import styles from '@/global.less';
import { UserOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { useEffect, useState } from 'react';
import useGetUserInfo from '@/hooks/useGetUserInfo';
import { getCurrentUserInfo } from '@/utils/requests/requests_utils';
import { NoAccessPage } from '@digit-fe/digit-component';

export default function ProductInformationPage(props: IRouteComponentProps) {
  const breadcrumbNameMap: Record<string, string> = {
    '/news/add': '最新资讯发布',
    '/news/manage_access': '投放权限管理',
    '/news/manage_product': '产品标签管理',
    '/news/manage_type': '内容类型管理',
  };
  const location = useLocation();
  const { children } = props;
  const pathSnippets = location.pathname.split('/news/').filter((i) => i);
  const { initialState } = useModel('@@initialState');
  const { isFrAccount } = initialState;
  const [isHasAccess, setIsHasAccess] = useState<boolean>(true);

  const extraBreadcrumbItems =
    pathSnippets[0] === 'add'
      ? []
      : pathSnippets.map((_, index) => {
          const url = `/news/${pathSnippets.slice(0, index + 1).join('/')}`;
          return {
            key: url,
            title: <Link to={url}>{breadcrumbNameMap[url]}</Link>,
          };
        });
  const breadcrumbItems = [
    {
      title: (
        <Link to="/">
          <UserOutlined />
          <span>社区导航</span>
        </Link>
      ),
      key: '/',
    },
    {
      title: <Link to="/news/add">最新资讯发布</Link>,
      key: '/news/add',
    },
  ].concat(extraBreadcrumbItems);

  const { nameEn } = useGetUserInfo();
  const [currentUserInfo, setCurrentUserInfo] = useState<any>();

  const getCurrentUserInfoRequest = useRequest(
    () => {
      return getCurrentUserInfo({ type: 'MY_FANRUAN' });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setCurrentUserInfo(res);
        setIsHasAccess(res.admin || res.authorized);
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    nameEn && getCurrentUserInfoRequest.run();
  }, [nameEn]);

  return (
    <div className={styles.content}>
      <div
        style={{
          width: '1200px',
        }}
      >
        {isFrAccount && isHasAccess ? (
          <>
            <Breadcrumb>
              {breadcrumbItems.map((item) => (
                <Breadcrumb.Item>{item.title}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
            <div>{children}</div>
          </>
        ) : (
          <NoAccessPage
            switchAccountHref={`${process.env.ID_URL}/login/token/logout?referrer=${window.location.href}`}
            isFrAccount={isFrAccount}
          />
        )}
      </div>
    </div>
  );
}
