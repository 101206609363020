/**
 * 广告调用SDK
 * 2023-04-11 by Wangwei
 */

import axios from 'axios';
const fineAd = (window.fineAd = window.fineAd || {});
fineAd.options = fineAd.options || {};

/**
 * 初始化SDK配置
 * @param debug
 * @param dev
 * @param adApiUrl
 */
const config = ({ debug = false, dev = false, adApiUrl }) => {
  fineAd.options = {
    ...fineAd.options,
    debug,
    dev,
    adApiUrl: dev
      ? 'https://testjdy.fanruan.com/fcoin'
      : 'https://home.fanruan.com/fcoin',
  };
  if (debug) {
    console.log('adApiUrl', fineAd.options.adApiUrl);
  }
};

/**
 * 获取并展示指定广告id的广告位
 * @param adId 广告位id
 * @param adId2 如果adId没有匹配到则显示第二个广告位
 * @param top 顶边距
 * @param bottom 底边距
 */
const getAdvertisement = async ({ adId, adId2, top, bottom }) => {
  const { debug, dev } = fineAd.options;
  const dataPath = dev ? 'test' : 'prod';
  await axios
    .get(
      'https://shequ-oss.obs.cn-east-2.myhuaweicloud.com/digit/ad/data/' +
        dataPath +
        '/advertisement.json?v=' +
        Date.now(),
    )
    .then(function (res) {
      var isAdId = false;
      res.data.forEach((item) => {
        if (item.adId === adId) {
          isAdId = true;
          let getSize = splitPicSize(item.picSize);
          if (debug) {
            console.group('advertisement ' + adId);
            console.log('item', item);
            console.log('picSize', getSize);
            console.log('top', top);
            console.log('bottom', bottom);
            console.groupEnd();
          }
          let adDomId = document.getElementById('AD-' + adId);
          adDomId.innerHTML = '';
          window.fineAd.adMargin({ adId: adId, top: top });
          // 无链接图片广告
          if (!item.adLink && item.type === 1) {
            adDomId.innerHTML += `<img width="${getSize.w}" height="${getSize.h}" src="${item.picUrl}">`;
          }
          // 有链接图片广告
          if (item.adLink && item.type === 1) {
            adDomId.innerHTML += `<a onClick="window.fineAd.advertisementHits('${adId}');" href="${item.adLink}" target="_blank"><img width="${getSize.w}" height="${getSize.h}" src="${item.picUrl}"></a>`;
          }
          // 有链接文本广告
          if (item.adLink && item.type === 2) {
            adDomId.innerHTML += `<a onClick="window.fineAd.advertisementHits('${adId}');" href="${item.adLink}" target="_blank">${item.adText}</a>`;
          }
          window.fineAd.adMargin({ adId: adId, bottom: bottom });
        }
      });

      // 找第二个广告位
      if (adId2 && !isAdId) {
        res.data.forEach((item) => {
          if (item.adId === adId2) {
            console.log('isAdId', isAdId);
            let getSize = splitPicSize(item.picSize);
            if (debug) {
              console.group('advertisement ' + adId2);
              console.log('item', item);
              console.log('picSize', getSize);
              console.log('top', top);
              console.log('bottom', bottom);
              console.groupEnd();
            }
            let adDomId = document.getElementById('AD-' + adId2);
            adDomId.innerHTML = '';
            window.fineAd.adMargin({ adId: adId2, top: top });
            // 有链接图片广告
            if (item.adLink && item.type === 1) {
              adDomId.innerHTML = `<a onClick="window.fineAd.advertisementHits('${adId2}');" href="${item.adLink}" target="_blank"><img width="${getSize.w}" height="${getSize.h}" src="${item.picUrl}"></a>`;
            }
            // 有链接文本广告
            if (item.adLink && item.type === 2) {
              adDomId.innerHTML = `<a onClick="window.fineAd.advertisementHits('${adId2}');" href="${item.adLink}" target="_blank">${item.adText}</a>`;
            }
            window.fineAd.adMargin({ adId: adId2, bottom: bottom });
          }
        });
      }
    })
    .catch(function (error) {
      console.log('ad error', error);
    });
};

/**
 * 广告边距
 * @param top 顶边距
 * @param bottom 底边距
 */
const adMargin = ({ adId, top = 0, bottom = 0 }) => {
  let adDomId = document.getElementById('AD-' + adId);
  if (top > 0) {
    adDomId.innerHTML += `<div style="height:${top}px;"></div>`;
  } else if (bottom > 0) {
    adDomId.innerHTML += `<div style="height:${bottom}px;"></div>`;
  }
};

/**
 * 拆分广告位尺寸
 * @param adSize 广告位尺寸
 * @return Array[w,h] 宽和高
 */
const splitPicSize = (adSize) => {
  let { w, h } = { w: '', h: '' };
  if (adSize) {
    let { [0]: w, [1]: h } = adSize.replace(/\s/g, '').split('*');
    return { w, h };
  } else {
    return { w, h };
  }
};

/**
 * 广告位埋点
 * @param adId 广告位id
 */
const advertisementHits = (adId) => {
  const { adApiUrl } = fineAd.options;
  axios.get(adApiUrl + '/api/advertisement/hits/' + adId);
};

config({ debug: false, dev: false });
fineAd.config = config;
fineAd.adMargin = adMargin;
fineAd.getAdvertisement = getAdvertisement;
fineAd.advertisementHits = advertisementHits;
