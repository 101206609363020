import React, { useEffect, useState } from 'react';
import styles from './selectIcon.less';

type Props = { iconSrc: string; setIconSrc: Function };

const SelectIcon: React.FC<Props> = ({ iconSrc, setIconSrc }) => {
  return (
    <div>
      <div>
        <img
          className={styles.pic_icon}
          style={{ marginLeft: '0px' }}
          src="https://hwobs.fanruan.com/ui/home/icons/logo1.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo1.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo2.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo2.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo3.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo3.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo4.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo4.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo5.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo5.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo6.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo6.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo7.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo7.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo8.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo8.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo9.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo9.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo10.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo10.png')
          }
        />
      </div>
      <div style={{ marginTop: '8px' }}>
        <img
          className={styles.pic_icon}
          style={{ marginLeft: '0px' }}
          src="https://hwobs.fanruan.com/ui/home/icons/logo11.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo11.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo12.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo12.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo13.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo13.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo14.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo14.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo15.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo15.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo16.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo16.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo17.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo17.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo18.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo18.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo19.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo19.png')
          }
        />
        <img
          className={styles.pic_icon}
          src="https://hwobs.fanruan.com/ui/home/icons/logo20.png"
          onClick={() =>
            setIconSrc('https://hwobs.fanruan.com/ui/home/icons/logo20.png')
          }
        />
      </div>
    </div>
  );
};

export default SelectIcon;
