import React, { useState } from 'react';
import styles from './index.less';
import {
  IContactItem,
  IPersons,
} from '@/pages/enterpriseService/components/contactFR/contactItem';
import { Person } from '@/pages/enterpriseService/components/contactFR/index';
import { Popup } from 'antd-mobile';
import { Col, Row } from 'antd';

type TPCIProps = {
  contactItem: IContactItem;
};

const MobileContactItem: React.FC<TPCIProps> = ({ contactItem }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const AlignPhoneItems: React.FC<{ persons: IPersons[] }> = ({ persons }) => (
    <div className={styles.contact_fr_mobile_popup_body}>
      {persons.length > 0
        ? persons.map((item, index) => (
            <div
              className={styles.contact_fr_mobile_popup_body_item}
              key={index}
            >
              <img
                className={styles.contact_fr_mobile_popup_icon}
                src={item.icon}
              />
              {item.name && (
                <div className={styles.contact_fr_mobile_popup_body_title}>
                  {item.name}
                </div>
              )}
              <div className={styles.contact_fr_mobile_popup_phone}>
                <a
                  href={`tel:${item.callPhone}`}
                  style={{ marginLeft: '16px' }}
                >
                  {item.phone}
                </a>
              </div>
            </div>
          ))
        : null}
    </div>
  );

  const HorizontalPhoneItems: React.FC<{ persons: Person[]; icon: string }> = ({
    persons,
    icon,
  }) => (
    <div className={styles.contact_fr_mobile_popup_pre_sales_advice}>
      <img className={styles.contact_fr_mobile_popup_icon} src={icon} />
      {persons.map((item, index) => (
        <div className={styles.contact_fr_mobile_popup_phone} key={index}>
          <Row gutter={[16, 0]}>
            <Col span={11} className={styles.layout_main_end}>
              <span>{item.name}</span>
            </Col>
            <Col span={13} className={styles.layout_main_start}>
              <a href={`tel:${item.phone}`}>{item.phone}</a>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );

  return (
    <div
      className={styles.contact_fr_item_mobile}
      onClick={() => setVisible(true)}
    >
      <img className={styles.contact_fr_icon_mobile} src={contactItem.icon} />
      <div className={styles.contact_fr_name_mobile}>{contactItem.title}</div>
      <Popup
        visible={visible}
        showCloseButton
        onMaskClick={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          height: '268px',
        }}
      >
        <div className={styles.contact_fr_mobile_popup_title}>
          {contactItem.title}
        </div>
        <div className={styles.contact_fr_mobile_popup_body_item}>
          {contactItem.personInfo &&
          contactItem.personInfo.length > 0 &&
          contactItem.title !== '技术支持' ? (
            <HorizontalPhoneItems
              persons={contactItem.personInfo}
              icon={contactItem.icon}
            />
          ) : contactItem.onlyPhone ? (
            <AlignPhoneItems persons={contactItem.onlyPhone} />
          ) : null}
        </div>
      </Popup>
    </div>
  );
};

export default MobileContactItem;
