import React, { useEffect, useState } from 'react';
import { Form, Input, message, Modal, Select, Upload, Tooltip } from 'antd';
import { ProductInformationItem } from './table';
import './pushModal.less';
import { useRequest } from 'umi';
import {
  addOrEditPush,
  getAllContentTypes,
  getAllLabels,
  getAuthLabels,
} from '@/utils/requests/requests_push';
import { LabelItem } from './productLabelManage/table';
import { ContentTypeItem } from './contentTypeManage/table';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import {
  calFileUploadParams,
  getCoverBase64,
  getFileNameAndType,
  randomStr,
} from '@/utils';
import axios from 'axios';
import { submitFile } from '@/utils/requests/requests_utils';

type Props = {
  recommendedContentModalOpen: boolean;
  setRecommendedContentModalOpen: Function;
  modalTitle?: string;
  okText?: string;
  submitMessage?: string;
  record?: ProductInformationItem;
  setRefresh: Function;
  refresh: number;
};

const RecommendedContentModal: React.FC<Props> = ({
  recommendedContentModalOpen,
  setRecommendedContentModalOpen,
  modalTitle = '新增推荐内容',
  okText = '确认',
  submitMessage = '新增成功',
  record = undefined,
  setRefresh,
  refresh,
}) => {
  const [form] = Form.useForm();
  const [id, setId] = useState(record?.id);
  const [labelId, setLabelId] = useState(record?.label?.id);
  const [contentTypeId, setContentTypeId] = useState(record?.contentType?.id);
  const [title, setTitle] = useState(record?.title);
  const [url, setUrl] = useState(record?.url);
  const [pic, setPic] = useState(record?.pic);
  const [allLabelOptions, setAllLabelOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [allContentTypesOptions, setAllContentTypesOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [coverImage, setCoverImage] = useState<any>();

  const getAuthLabelsRequest = useRequest(
    () => {
      return getAuthLabels();
    },
    {
      manual: true,
      onSuccess: (res) => {
        setAllLabelOptions(
          res.map((item: LabelItem) => ({ label: item.name, value: item.id })),
        );
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    getAuthLabelsRequest.run();
  }, []);

  const getAllContentTypesRequest = useRequest(
    () => {
      return getAllContentTypes();
    },
    {
      manual: true,
      onSuccess: (res) => {
        setAllContentTypesOptions(
          res.map((item: ContentTypeItem) => ({
            label: item.name,
            value: item.id,
          })),
        );
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    getAllContentTypesRequest.run();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      labelId: record?.label?.id,
      contentTypeId: record?.contentType?.id,
      title: record?.title,
      url: record?.url,
      pic: record?.pic,
    });
    setId(record?.id);
    setLabelId(record?.label?.id);
    setContentTypeId(record?.contentType?.id);
    setTitle(record?.title);
    setUrl(record?.url);
    setPic(record?.pic);
    setCoverImage(record?.pic);
    setFileList(
      record?.pic
        ? [
            {
              uid: randomStr(7),
              name: record?.pic,
              url: record?.pic,
              status: 'done',
            },
          ]
        : [],
    );
  }, [record]);

  const addOrEditPushRequest = useRequest(
    () => {
      return addOrEditPush(
        JSON.stringify({
          title,
          url,
          labelId,
          contentTypeId,
          id,
          pic,
        }),
      );
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  const submitFileRequest = useRequest(
    (fileName: string, contentType: string) => {
      return submitFile({ path: 'myfr', fileName, contentType });
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  return (
    <Modal
      open={recommendedContentModalOpen}
      title={modalTitle}
      width={664}
      destroyOnClose={true}
      okText={okText}
      onOk={() => {
        form.submit();
      }}
      cancelText="取消"
      maskClosable={false}
      onCancel={() => {
        setRecommendedContentModalOpen(false);
        form.setFieldsValue({
          labelId: undefined,
          contentTypeId: undefined,
          title: undefined,
          url: undefined,
          pic: undefined,
        });
      }}
    >
      <Form
        name="recommendedContent"
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        initialValues={{
          labelId: labelId,
          contentTypeId: contentTypeId,
          title: title,
          url: url,
        }}
        onFinish={() => {
          addOrEditPushRequest.run().then(() => {
            setRecommendedContentModalOpen(false);
            setRefresh(refresh + 1);
            message.success(submitMessage);
            form.setFieldsValue({
              labelId: undefined,
              contentTypeId: undefined,
              title: undefined,
              url: undefined,
              pic: undefined,
            });
          });
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <Form.Item
          label="产品标签"
          name="labelId"
          rules={[{ required: true, message: '请选择产品标签' }]}
        >
          <Select
            placeholder="请选择..."
            options={allLabelOptions}
            onChange={(value) => setLabelId(value)}
          />
        </Form.Item>
        <Form.Item
          label="内容类型"
          name="contentTypeId"
          rules={[{ required: true, message: '请选择内容类型' }]}
        >
          <Select
            placeholder="请选择..."
            options={allContentTypesOptions}
            onChange={(value) => setContentTypeId(value)}
          />
        </Form.Item>
        <Form.Item
          label="标题"
          name="title"
          rules={[{ required: true, message: '请输入标题' }]}
        >
          <Input
            placeholder="请输入..."
            showCount
            maxLength={50}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="URL"
          name="url"
          rules={[{ required: true, message: '请输入URL' }]}
        >
          <Input
            placeholder="请输入..."
            onChange={(event) => setUrl(event.target.value)}
          />
        </Form.Item>
        <Form.Item label="图片" name="pic" valuePropName="fileList">
          <div style={{ color: '#C5C7CE', lineHeight: '27px' }}>
            默认根据内容类型提供配图，也可自行上传图片进行替换。图片格式为.png，尺寸为352*218px，大小不超过1MB
          </div>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            accept={'.png'}
            fileList={fileList}
            showUploadList={false}
            customRequest={(info) => {
              let fileInfo = getFileNameAndType((info.file as RcFile).name);
              let uploadParams = calFileUploadParams(fileInfo.type, 'myfr');
              submitFileRequest
                .run(uploadParams.fileName, (info.file as RcFile).type)
                .then((res) => {
                  axios.request({
                    method: 'PUT',
                    url: res.signedUrl,
                    withCredentials: false,
                    headers:
                      Object.assign(res.actualSignedRequestHeaders, {
                        'Content-Disposition': `attachment; filename=${encodeURIComponent(
                          (info.file as RcFile).name,
                        )}`,
                      }) || {},
                    data: info.file,
                  });
                })
                .then(() => {
                  setPic(
                    `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`,
                  );
                  getCoverBase64(info.file, (imageUrl: any) => {
                    setCoverImage(imageUrl);
                  });
                  setFileList([
                    {
                      uid: randomStr(7),
                      name: (info.file as RcFile)?.name,
                      url: `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`,
                      status: 'done',
                    },
                  ]);
                });
            }}
            beforeUpload={(file: RcFile) => {
              const isJpgOrPng = file.type === 'image/png';
              if (!isJpgOrPng) {
                message.error('只支持.png格式!');
              }
              const isLt2M = file.size / 1024 / 1024 < 1;
              if (!isLt2M) {
                message.error('大小不超过1MB');
              }
              return isJpgOrPng && isLt2M;
            }}
            onRemove={() => {
              setFileList([]);
            }}
          >
            {coverImage ? (
              <Tooltip title="重新上传">
                <img src={coverImage} alt="avatar" style={{ width: '100%' }} />
              </Tooltip>
            ) : (
              <div>
                <div style={{ marginTop: 8 }}>PIC</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RecommendedContentModal;
