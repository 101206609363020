import React, { useEffect, useState } from 'react';
import { ConfigProvider, Table, message } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import type { ColumnsType } from 'antd/es/table';
import LabelInput from '../components/labelInput';
import LabelSwitch from '../components/labelSwitch';
import { getLabelRecords, sortLabels } from '@/utils/requests/requests_push';
import { useRequest } from 'umi';
import dayjs from 'dayjs';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MenuOutlined } from '@ant-design/icons';

type Props = { keyword?: string; refresh: number };

export interface LabelItem {
  enabled: boolean;
  id: string;
  name: string;
  lastModifiedTime: string;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && { ...transform, scaleY: 1 },
    )?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const ProductLabelManageTable: React.FC<Props> = ({ keyword, refresh }) => {
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(10);
  const [pages, setPages] = useState(0);
  const [data, setData] = useState<LabelItem[]>([]);

  const getLabelRecordsRequest = useRequest(
    (keyword, current, size) => {
      return getLabelRecords({
        keyword,
        current,
        size,
      });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setData(res.records);
        setTotal(res.total);
        setPages(res.pages);
        setLoading(false);
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    getLabelRecordsRequest.run(keyword, current, size);
  }, [keyword, current, size, refresh]);

  const sortProductDetailsListRequest = useRequest(
    (value) => {
      return sortLabels(JSON.stringify(value));
    },
    {
      manual: true,
      onSuccess: (res) => {
        message.success('修改产品内容列表排序成功');
      },
      onError: (e) => {},
    },
  );

  const columns: ColumnsType<LabelItem> = [
    {
      title: '',
      dataIndex: 'sort',
      width: 48,
    },
    {
      title: '序号',
      dataIndex: '序号',
      key: 'id',
      align: 'center',
      width: 65,
      render: (_value, _record, index) => `${index + 1}`,
    },
    {
      title: '产品标签',
      dataIndex: 'name',
      key: 'name ',
      width: 725,
      render: (value, record) => (
        <LabelInput
          type="label"
          name={value}
          id={record.id}
          enabled={record.enabled}
        />
      ),
    },
    {
      title: '状态',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 160,
      render: (value, record) => (
        <LabelSwitch
          type="label"
          name={record.name}
          id={record.id}
          enabled={value}
        />
      ),
    },
    {
      title: '更新时间',
      key: 'lastModifiedTime',
      dataIndex: 'lastModifiedTime',
      width: 200,
      render: (value) =>
        value ? `${dayjs(value).format('YYYY-MM-DD HH:mm:ss')}` : '--',
    },
  ];

  return (
    <>
      <ConfigProvider locale={zhCN}>
        <DndContext
          onDragEnd={({ active, over }: DragEndEvent) => {
            let newData: LabelItem[] = [];
            setData((previous) => {
              const activeIndex = previous.findIndex((i) => i.id === active.id);
              const overIndex = previous.findIndex((i) => i.id === over?.id);
              newData = arrayMove(previous, activeIndex, overIndex);
              return newData;
            });
            sortProductDetailsListRequest.run(
              newData.map((item: LabelItem, index: number) => ({
                id: item.id,
                index: index,
              })),
            );
          }}
        >
          <SortableContext
            items={data.map((i) => i.id)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              components={{
                body: {
                  row: Row,
                },
              }}
              rowKey="id"
              columns={columns}
              dataSource={data}
              pagination={{
                total: total,
                pageSize: size,
                current: current,
                hideOnSinglePage: true,
                showQuickJumper: true,
              }}
              onChange={(pagination) => {
                pagination.current && setCurrent(pagination.current);
                pagination.pageSize && setSize(pagination.pageSize);
              }}
              style={{
                width: '1152px',
                marginTop: '16px',
                color: '#333333',
              }}
            />
          </SortableContext>
        </DndContext>
      </ConfigProvider>
    </>
  );
};

export default ProductLabelManageTable;
