// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from 'E:/fr/fine-digit-home-webui/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "exact": false,
    "path": "/",
    "component": require('@/layouts/index').default,
    "routes": [
      {
        "exact": false,
        "path": "/news",
        "component": require('@/layouts/backstage/productInformationPage').default,
        "nav": true,
        "navTitle": "最新资讯发布",
        "routes": [
          {
            "path": "/news/add",
            "title": "最新资讯发布-我的帆软",
            "metas": [
              {
                "name": "description",
                "content": "用于向我的帆软-帆软社区-最新资讯中增加推送信息"
              }
            ],
            "component": require('@/pages/productInformation/index.tsx').default,
            "exact": true
          },
          {
            "path": "/news/manage_access",
            "title": "最新资讯发布-我的帆软",
            "component": require('@/pages/productInformation/permissionManege/index.tsx').default,
            "exact": true
          },
          {
            "path": "/news/manage_product",
            "title": "最新资讯发布-我的帆软",
            "component": require('@/pages/productInformation/productLabelManage/index.tsx').default,
            "exact": true
          },
          {
            "path": "/news/manage_type",
            "title": "最新资讯发布-我的帆软",
            "component": require('@/pages/productInformation/contentTypeManage/index.tsx').default,
            "exact": true
          }
        ]
      },
      {
        "exact": false,
        "path": "/addMembers",
        "title": "向企业添加成员",
        "component": require('@/pages/addMembers/index.tsx').default,
        "nav": true,
        "navTitle": "向企业添加成员"
      },
      {
        "exact": false,
        "path": "/resource/manage",
        "title": "产品资料配置-我的帆软",
        "component": require('@/layouts/backstage/productResourceManagePage').default,
        "nav": true,
        "navTitle": "产品资料配置"
      },
      {
        "exact": false,
        "path": "/",
        "component": require('@/layouts/front/index').default,
        "routes": [
          {
            "exact": true,
            "path": "/",
            "title": "最新资讯-我的帆软",
            "component": require('@/pages/personalService/index.tsx').default,
            "nav": true,
            "navTitle": "最新资讯"
          },
          {
            "exact": true,
            "path": "/service",
            "title": "服务平台-我的帆软",
            "component": require('@/pages/enterpriseService/index.tsx').default,
            "navTitle": "服务平台"
          },
          {
            "exact": true,
            "path": "/module",
            "title": "社区导航-我的帆软",
            "nav": true,
            "navTitle": "社区导航",
            "component": require('@/pages/bbsModule/index').default
          },
          {
            "exact": true,
            "path": "/resource",
            "title": "产品资料-我的帆软",
            "component": require('@/pages/productResource/index.tsx').default,
            "nav": true,
            "navTitle": "产品资料"
          },
          {
            "exact": true,
            "path": "/internal",
            "title": "内部服务-我的帆软",
            "component": require('@/pages/internalService/internalService.tsx').default,
            "nav": true,
            "navTitle": "内部服务"
          }
        ]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
