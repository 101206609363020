export const phoneCode = [
  {
    text: '中国大陆',
    value: '+86',
  },
  {
    text: '中国香港',
    value: '+852',
  },
  {
    text: '中国澳门',
    value: '+853',
  },
  {
    text: '中国台湾',
    value: '+886',
  },
  {
    text: '新加坡',
    value: '+65',
  },
  {
    text: '阿富汗',
    value: '+93',
  },
  {
    text: '阿尔巴尼亚',
    value: '+355',
  },
  {
    text: '阿尔格拉',
    value: '+213',
  },
  {
    text: '安道尔',
    value: '+376',
  },
  {
    text: '安哥拉',
    value: '+244',
  },
  {
    text: '安圭拉',
    value: '+1264',
  },
  {
    text: '阿森松岛',
    value: '+247',
  },
  {
    text: '安提瓜和巴布达',
    value: '+1268',
  },
  {
    text: '阿根廷',
    value: '+54',
  },
  {
    text: '亚美尼亚',
    value: '+374',
  },
  {
    text: '阿鲁巴',
    value: '+297',
  },
  {
    text: '澳大利亚',
    value: '+61',
  },
  {
    text: '奥地利',
    value: '+43',
  },
  {
    text: '阿塞拜疆',
    value: '+994',
  },
  {
    text: '巴哈马',
    value: '+1242',
  },
  {
    text: '巴林',
    value: '+973',
  },
  {
    text: '孟加拉国',
    value: '+880',
  },
  {
    text: '巴巴多斯',
    value: '+1246',
  },
  {
    text: '白俄罗斯',
    value: '+375',
  },
  {
    text: '比利时',
    value: '+32',
  },
  {
    text: '伯利兹',
    value: '+501',
  },
  {
    text: '贝宁',
    value: '+229',
  },
  {
    text: '百慕大',
    value: '+1441',
  },
  {
    text: '不丹',
    value: '+975',
  },
  {
    text: '玻利维亚',
    value: '+591',
  },
  {
    text: '波斯尼亚和黑塞哥维那',
    value: '+387',
  },
  {
    text: '博茨瓦纳',
    value: '+267',
  },
  {
    text: '巴西',
    value: '+55',
  },
  {
    text: '文莱',
    value: '+673',
  },
  {
    text: '保加利亚',
    value: '+359',
  },
  {
    text: '布基纳法索',
    value: '+226',
  },
  {
    text: '布隆迪',
    value: '+257',
  },
  {
    text: '柬埔寨',
    value: '+855',
  },
  {
    text: '喀麦隆',
    value: '+237',
  },
  {
    text: '加拿大',
    value: '+1',
  },
  {
    text: '佛得角',
    value: '+238',
  },
  {
    text: '开曼群岛',
    value: '+1345',
  },
  {
    text: '中非共和国',
    value: '+236',
  },
  {
    text: '乍得',
    value: '+235',
  },
  {
    text: '智利',
    value: '+56',
  },
  {
    text: '哥伦比亚',
    value: '+57',
  },
  {
    text: '科摩罗',
    value: '+269',
  },
  {
    text: '刚果共和国',
    value: '+242',
  },
  {
    text: '刚果民主共和国',
    value: '+243',
  },
  {
    text: '库克群岛',
    value: '+682',
  },
  {
    text: '哥斯达黎加',
    value: '+506',
  },
  {
    text: '科特迪沃',
    value: '+225',
  },
  {
    text: '克罗地亚',
    value: '+385',
  },
  {
    text: '古巴',
    value: '+53',
  },
  {
    text: '塞浦路斯',
    value: '+357',
  },
  {
    text: '+捷克共和国',
    value: '+420',
  },
  {
    text: '丹麦',
    value: '+45',
  },
  {
    text: '吉布提',
    value: '+253',
  },
  {
    text: '多米尼加',
    value: '+1767',
  },
  {
    text: '多米尼加共和国',
    value: '+1809',
  },
  {
    text: '厄瓜多尔',
    value: '+593',
  },
  {
    text: '埃及',
    value: '+20',
  },
  {
    text: '艾萨尔瓦多',
    value: '+503',
  },
  {
    text: '爱沙尼亚',
    value: '+372',
  },
  {
    text: '埃塞俄比亚',
    value: '+251',
  },
  {
    text: '法罗群岛',
    value: '+298',
  },
  {
    text: '斐济',
    value: '+679',
  },
  {
    text: '芬兰',
    value: '+358',
  },
  {
    text: '法国',
    value: '+33',
  },
  {
    text: '法属圭亚那',
    value: '+594',
  },
  {
    text: '法属波利尼西亚',
    value: '+689',
  },
  {
    text: '加蓬',
    value: '+241',
  },
  {
    text: '冈比亚',
    value: '+220',
  },
  {
    text: '格鲁吉亚',
    value: '+995',
  },
  {
    text: '德国',
    value: '+49',
  },
  {
    text: '加纳',
    value: '+233',
  },
  {
    text: '直布罗陀',
    value: '+350',
  },
  {
    text: '希腊',
    value: '+30',
  },
  {
    text: '格陵兰',
    value: '+299',
  },
  {
    text: '格林纳达',
    value: '+1473',
  },
  {
    text: '瓜德罗普',
    value: '+590',
  },
  {
    text: '关岛',
    value: '+1671',
  },
  {
    text: '危地马拉',
    value: '+502',
  },
  {
    text: '几内亚',
    value: '+240',
  },
  {
    text: '根西',
    value: '+44',
  },
  {
    text: '几内亚',
    value: '+224',
  },
  {
    text: '圭亚那',
    value: '+592',
  },
  {
    text: '海地',
    value: '+509',
  },
  {
    text: '洪都拉斯',
    value: '+504',
  },
  {
    text: '缅甸',
    value: '+95',
  },
  {
    text: '匈牙利',
    value: '+36',
  },
  {
    text: '冰岛',
    value: '+354',
  },
  {
    text: '印度',
    value: '+91',
  },
  {
    text: '印度尼西亚',
    value: '+62',
  },
  {
    text: '伊朗',
    value: '+98',
  },
  {
    text: '伊拉克',
    value: '+964',
  },
  {
    text: '爱尔兰',
    value: '+353',
  },
  {
    text: '马恩岛',
    value: '+44',
  },
  {
    text: '以色列',
    value: '+972',
  },
  {
    text: '意大利',
    value: '+39',
  },
  {
    text: '牙买加',
    value: '+1876',
  },
  {
    text: '日本',
    value: '+81',
  },
  {
    text: '泽西岛',
    value: '+44',
  },
  {
    text: '约旦',
    value: '+962',
  },
  {
    text: '哈萨克斯坦',
    value: '+7',
  },
  {
    text: '肯尼亚',
    value: '+254',
  },
  {
    text: '科索沃',
    value: '+383',
  },
  {
    text: '科威特',
    value: '+965',
  },
  {
    text: '吉尔吉斯斯坦',
    value: '+996',
  },
  {
    text: '老挝',
    value: '+856',
  },
  {
    text: '拉脱维亚',
    value: '+371',
  },
  {
    text: '黎巴嫩',
    value: '+961',
  },
  {
    text: '莱索托',
    value: '+266',
  },
  {
    text: '利比里亚',
    value: '+231',
  },
  {
    text: '利比亚',
    value: '+218',
  },
  {
    text: '列支敦士登',
    value: '+423',
  },
  {
    text: '立陶宛',
    value: '+370',
  },
  {
    text: '卢森堡',
    value: '+352',
  },
  {
    text: '马其顿',
    value: '+389',
  },
  {
    text: '马达加斯加',
    value: '+261',
  },
  {
    text: '马拉维',
    value: '+265',
  },
  {
    text: '马来西亚',
    value: '+60',
  },
  {
    text: '马尔代夫',
    value: '+960',
  },
  {
    text: '马里',
    value: '+223',
  },
  {
    text: '马耳他',
    value: '+356',
  },
  {
    text: '马提尼克',
    value: '+596',
  },
  {
    text: '毛里塔尼亚',
    value: '+222',
  },
  {
    text: '毛里求斯',
    value: '+230',
  },
  {
    text: '马约特',
    value: '+262',
  },
  {
    text: '墨西哥',
    value: '+52',
  },
  {
    text: '摩尔多瓦',
    value: '+373',
  },
  {
    text: '摩纳哥',
    value: '+377',
  },
  {
    text: '蒙古',
    value: '+976',
  },
  {
    text: '黑山',
    value: '+382',
  },
  {
    text: '蒙特塞拉特',
    value: '+1664',
  },
  {
    text: '摩洛哥',
    value: '+212',
  },
  {
    text: '莫桑比克',
    value: '+258',
  },
  {
    text: '纳米比亚',
    value: '+264',
  },
  {
    text: '尼泊尔',
    value: '+977',
  },
  {
    text: '荷兰',
    value: '+31',
  },
  {
    text: '荷属安的列斯',
    value: '+599',
  },
  {
    text: '新喀里多尼亚',
    value: '+687',
  },
  {
    text: '新西兰',
    value: '+64',
  },
  {
    text: '尼加拉瓜',
    value: '+505',
  },
  {
    text: '尼日尔',
    value: '+227',
  },
  {
    text: '尼日利亚',
    value: '+234',
  },
  {
    text: '挪威',
    value: '+47',
  },
  {
    text: '阿曼',
    value: '+968',
  },
  {
    text: '巴基斯坦',
    value: '+92',
  },
  {
    text: '巴勒斯坦',
    value: '+970',
  },
  {
    text: '巴拿马',
    value: '+507',
  },
  {
    text: '巴布亚新几内亚',
    value: '+675',
  },
  {
    text: '巴拉圭',
    value: '+595',
  },
  {
    text: '秘鲁',
    value: '+51',
  },
  {
    text: '菲律宾',
    value: '+63',
  },
  {
    text: '波兰',
    value: '+48',
  },
  {
    text: '葡萄牙',
    value: '+351',
  },
  {
    text: '波多黎各',
    value: '+1',
  },
  {
    text: '库塔',
    value: '+974',
  },
  {
    text: '留尼汪',
    value: '+262',
  },
  {
    text: '罗马尼亚',
    value: '+40',
  },
  {
    text: '俄罗斯',
    value: '+7',
  },
  {
    text: '卢旺达',
    value: '+250',
  },
  {
    text: '萨摩亚东部',
    value: '+684',
  },
  {
    text: '萨摩亚西部',
    value: '+685',
  },
  {
    text: '圣马力诺',
    value: '+378',
  },
  {
    text: '圣多美和普林西比',
    value: '+239',
  },
  {
    text: '沙特阿拉伯',
    value: '+966',
  },
  {
    text: '塞内加尔',
    value: '+221',
  },
  {
    text: '塞尔维亚',
    value: '+381',
  },
  {
    text: '塞舌尔',
    value: '+248',
  },
  {
    text: '塞拉利昂',
    value: '+232',
  },
  {
    text: '斯洛伐克',
    value: '+421',
  },
  {
    text: '斯洛文尼亚',
    value: '+386',
  },
  {
    text: '南非',
    value: '+27',
  },
  {
    text: '韩国',
    value: '+82',
  },
  {
    text: '西班牙',
    value: '+34',
  },
  {
    text: '斯里兰卡',
    value: '+94',
  },
  {
    text: '圣基茨和尼维斯',
    value: '+1869',
  },
  {
    text: '圣卢西亚',
    value: '+1758',
  },
  {
    text: '圣文森特',
    value: '+1784',
  },
  {
    text: '苏丹',
    value: '+249',
  },
  {
    text: '苏里南',
    value: '+597',
  },
  {
    text: '斯威士兰',
    value: '+268',
  },
  {
    text: '瑞典',
    value: '+46',
  },
  {
    text: '瑞士',
    value: '+41',
  },
  {
    text: '叙利亚',
    value: '+963',
  },
  {
    text: '塔吉克斯坦',
    value: '+992',
  },
  {
    text: '坦桑尼亚',
    value: '+255',
  },
  {
    text: '泰国',
    value: '+66',
  },
  {
    text: '东帝汶',
    value: '+670',
  },
  {
    text: '多哥',
    value: '+228',
  },
  {
    text: '汤加',
    value: '+676',
  },
  {
    text: '特立尼达和多巴哥',
    value: '+1868',
  },
  {
    text: '突尼斯',
    value: '+216',
  },
  {
    text: '土耳其',
    value: '+90',
  },
  {
    text: '土库曼斯坦',
    value: '+993',
  },
  {
    text: '特克斯和凯科斯群岛',
    value: '+1649',
  },
  {
    text: '乌干达',
    value: '+256',
  },
  {
    text: '乌克兰',
    value: '+380',
  },
  {
    text: '阿拉伯联合酋长国',
    value: '+971',
  },
  {
    text: '英国',
    value: '+44',
  },
  {
    text: '美国',
    value: '+1',
  },
  {
    text: '乌拉圭',
    value: '+598',
  },
  {
    text: '乌兹别克斯坦',
    value: '+998',
  },
  {
    text: '瓦努阿图',
    value: '+678',
  },
  {
    text: '委内瑞拉',
    value: '+58',
  },
  {
    text: '越南',
    value: '+84',
  },
  {
    text: '维尔京群岛',
    value: '+1340',
  },
  {
    text: '也门',
    value: '+967',
  },
  {
    text: '赞比亚',
    value: '+260',
  },
  {
    text: '津巴布韦',
    value: '+263',
  },
];

export const phoneCodeEn = [
  {
    short: 'US',
    name: '美国',
    text: 'USA',
    value: '1',
  },
  {
    short: 'AD',
    name: '安道尔共和国',
    text: 'Andorra',
    value: '376',
  },
  {
    short: 'AE',
    name: '阿拉伯联合酋长国',
    text: 'UnitedArabEmirates',
    value: '971',
  },
  {
    short: 'AF',
    name: '阿富汗',
    text: 'Afghanistan',
    value: '93',
  },
  {
    short: 'AG',
    name: '安提瓜和巴布达',
    text: 'AntiguaandBarbuda',
    value: '1268',
  },
  {
    short: 'AI',
    name: '安圭拉岛',
    text: 'Anguilla',
    value: '1264',
  },
  {
    short: 'AL',
    name: '阿尔巴尼亚',
    text: 'Albania',
    value: '355',
  },
  {
    short: 'AM',
    name: '亚美尼亚',
    text: 'Armenia',
    value: '374',
  },
  {
    short: '',
    name: '阿森松',
    text: 'Ascension',
    value: '247',
  },
  {
    short: 'AO',
    name: '安哥拉',
    text: 'Angola',
    value: '244',
  },
  {
    short: 'AR',
    name: '阿根廷',
    text: 'Argentina',
    value: '54',
  },
  {
    short: 'AT',
    name: '奥地利',
    text: 'Austria',
    value: '43',
  },
  {
    short: 'AU',
    name: '澳大利亚',
    text: 'Australia',
    value: '61',
  },
  {
    short: 'AZ',
    name: '阿塞拜疆',
    text: 'Azerbaijan',
    value: '994',
  },
  {
    short: 'BB',
    name: '巴巴多斯',
    text: 'Barbados',
    value: '1246',
  },
  {
    short: 'BD',
    name: '孟加拉国',
    text: 'Bangladesh',
    value: '880',
  },
  {
    short: 'BE',
    name: '比利时',
    text: 'Belgium',
    value: '32',
  },
  {
    short: 'BF',
    name: '布基纳法索',
    text: 'Burkina-faso',
    value: '226',
  },
  {
    short: 'BG',
    name: '保加利亚',
    text: 'Bulgaria',
    value: '359',
  },
  {
    short: 'BH',
    name: '巴林',
    text: 'Bahrain',
    value: '973',
  },
  {
    short: 'BI',
    name: '布隆迪',
    text: 'Burundi',
    value: '257',
  },
  {
    short: 'BJ',
    name: '贝宁',
    text: 'Benin',
    value: '229',
  },
  {
    short: 'BL',
    name: '巴勒斯坦',
    text: 'Palestine',
    value: '970',
  },
  {
    short: 'BM',
    name: '百慕大群岛',
    text: 'BermudaIs.',
    value: '1441',
  },
  {
    short: 'BN',
    name: '文莱',
    text: 'Brunei',
    value: '673',
  },
  {
    short: 'BO',
    name: '玻利维亚',
    text: 'Bolivia',
    value: '591',
  },
  {
    short: 'BR',
    name: '巴西',
    text: 'Brazil',
    value: '55',
  },
  {
    short: 'BS',
    name: '巴哈马',
    text: 'Bahamas',
    value: '1242',
  },
  {
    short: 'BW',
    name: '博茨瓦纳',
    text: 'Botswana',
    value: '267',
  },
  {
    short: 'BY',
    name: '白俄罗斯',
    text: 'Belarus',
    value: '375',
  },
  {
    short: 'BZ',
    name: '伯利兹',
    text: 'Belize',
    value: '501',
  },
  {
    short: 'CA',
    name: '加拿大',
    text: 'Canada',
    value: '1',
  },
  {
    short: '',
    name: '开曼群岛',
    text: 'CaymanIs.',
    value: '1345',
  },
  {
    short: 'CF',
    name: '中非共和国',
    text: 'CentralAfricanRepublic',
    value: '236',
  },
  {
    short: 'CG',
    name: '刚果',
    text: 'Congo',
    value: '242',
  },
  {
    short: 'CH',
    name: '瑞士',
    text: 'Switzerland',
    value: '41',
  },
  {
    short: 'CK',
    name: '库克群岛',
    text: 'CookIs.',
    value: '682',
  },
  {
    short: 'CL',
    name: '智利',
    text: 'Chile',
    value: '56',
  },
  {
    short: 'CM',
    name: '喀麦隆',
    text: 'Cameroon',
    value: '237',
  },
  {
    short: 'CN',
    name: '中国',
    text: 'China',
    value: '86',
  },
  {
    short: 'CO',
    name: '哥伦比亚',
    text: 'Colombia',
    value: '57',
  },
  {
    short: 'CR',
    name: '哥斯达黎加',
    text: 'CostaRica',
    value: '506',
  },
  {
    short: 'CS',
    name: '捷克',
    text: 'Czech',
    value: '420',
  },
  {
    short: 'CU',
    name: '古巴',
    text: 'Cuba',
    value: '53',
  },
  {
    short: 'CY',
    name: '塞浦路斯',
    text: 'Cyprus',
    value: '357',
  },
  {
    short: 'CZ',
    name: '捷克',
    text: 'CzechRepublic',
    value: '420',
  },
  {
    short: 'DE',
    name: '德国',
    text: 'Germany',
    value: '49',
  },
  {
    short: 'DJ',
    name: '吉布提',
    text: 'Djibouti',
    value: '253',
  },
  {
    short: 'DK',
    name: '丹麦',
    text: 'Denmark',
    value: '45',
  },
  {
    short: 'DO',
    name: '多米尼加共和国',
    text: 'DominicaRep.',
    value: '1890',
  },
  {
    short: 'DZ',
    name: '阿尔及利亚',
    text: 'Algeria',
    value: '213',
  },
  {
    short: 'EC',
    name: '厄瓜多尔',
    text: 'Ecuador',
    value: '593',
  },
  {
    short: 'EE',
    name: '爱沙尼亚',
    text: 'Estonia',
    value: '372',
  },
  {
    short: 'EG',
    name: '埃及',
    text: 'Egypt',
    value: '20',
  },
  {
    short: 'ES',
    name: '西班牙',
    text: 'Spain',
    value: '34',
  },
  {
    short: 'ET',
    name: '埃塞俄比亚',
    text: 'Ethiopia',
    value: '251',
  },
  {
    short: 'FI',
    name: '芬兰',
    text: 'Finland',
    value: '358',
  },
  {
    short: 'FJ',
    name: '斐济',
    text: 'Fiji',
    value: '679',
  },
  {
    short: 'FR',
    name: '法国',
    text: 'France',
    value: '33',
  },
  {
    short: 'GA',
    name: '加蓬',
    text: 'Gabon',
    value: '241',
  },
  {
    short: 'GB',
    name: '英国',
    text: 'UnitedKiongdom',
    value: '44',
  },
  {
    short: 'GD',
    name: '格林纳达',
    text: 'Grenada',
    value: '1809',
  },
  {
    short: 'GE',
    name: '格鲁吉亚',
    text: 'Georgia',
    value: '995',
  },
  {
    short: 'GF',
    name: '法属圭亚那',
    text: 'FrenchGuiana',
    value: '594',
  },
  {
    short: 'GH',
    name: '加纳',
    text: 'Ghana',
    value: '233',
  },
  {
    short: 'GI',
    name: '直布罗陀',
    text: 'Gibraltar',
    value: '350',
  },
  {
    short: 'GM',
    name: '冈比亚',
    text: 'Gambia',
    value: '220',
  },
  {
    short: 'GN',
    name: '几内亚',
    text: 'Guinea',
    value: '224',
  },
  {
    short: 'GR',
    name: '希腊',
    text: 'Greece',
    value: '30',
  },
  {
    short: 'GT',
    name: '危地马拉',
    text: 'Guatemala',
    value: '502',
  },
  {
    short: 'GU',
    name: '关岛',
    text: 'Guam',
    value: '1671',
  },
  {
    short: 'GY',
    name: '圭亚那',
    text: 'Guyana',
    value: '592',
  },
  {
    short: 'HK',
    name: '香港特别行政区',
    text: 'Hongkong',
    value: '852',
  },
  {
    short: 'HN',
    name: '洪都拉斯',
    text: 'Honduras',
    value: '504',
  },
  {
    short: 'HT',
    name: '海地',
    text: 'Haiti',
    value: '509',
  },
  {
    short: 'HU',
    name: '匈牙利',
    text: 'Hungary',
    value: '36',
  },
  {
    short: 'ID',
    name: '印度尼西亚',
    text: 'Indonesia',
    value: '62',
  },
  {
    short: 'IE',
    name: '爱尔兰',
    text: 'Ireland',
    value: '353',
  },
  {
    short: 'IL',
    name: '以色列',
    text: 'Israel',
    value: '972',
  },
  {
    short: 'IN',
    name: '印度',
    text: 'India',
    value: '91',
  },
  {
    short: 'IQ',
    name: '伊拉克',
    text: 'Iraq',
    value: '964',
  },
  {
    short: 'IR',
    name: '伊朗',
    text: 'Iran',
    value: '98',
  },
  {
    short: 'IS',
    name: '冰岛',
    text: 'Iceland',
    value: '354',
  },
  {
    short: 'IT',
    name: '意大利',
    text: 'Italy',
    value: '39',
  },
  {
    short: '',
    name: '科特迪瓦',
    text: 'IvoryCoast',
    value: '225',
  },
  {
    short: 'JM',
    name: '牙买加',
    text: 'Jamaica',
    value: '1876',
  },
  {
    short: 'JO',
    name: '约旦',
    text: 'Jordan',
    value: '962',
  },
  {
    short: 'JP',
    name: '日本',
    text: 'Japan',
    value: '81',
  },
  {
    short: 'KE',
    name: '肯尼亚',
    text: 'Kenya',
    value: '254',
  },
  {
    short: 'KG',
    name: '吉尔吉斯坦',
    text: 'Kyrgyzstan',
    value: '331',
  },
  {
    short: 'KH',
    name: '柬埔寨',
    text: 'Kampuchea(Cambodia)',
    value: '855',
  },
  {
    short: 'KP',
    name: '朝鲜',
    text: 'NorthKorea',
    value: '850',
  },
  {
    short: 'KR',
    name: '韩国',
    text: 'Korea',
    value: '82',
  },
  {
    short: 'KT',
    name: '科特迪瓦共和国',
    text: 'RepublicofIvoryCoast',
    value: '225',
  },
  {
    short: 'KW',
    name: '科威特',
    text: 'Kuwait',
    value: '965',
  },
  {
    short: 'KZ',
    name: '哈萨克斯坦',
    text: 'Kazakstan',
    value: '327',
  },
  {
    short: 'LA',
    name: '老挝',
    text: 'Laos',
    value: '856',
  },
  {
    short: 'LB',
    name: '黎巴嫩',
    text: 'Lebanon',
    value: '961',
  },
  {
    short: 'LC',
    name: '圣卢西亚',
    text: 'St.Lucia',
    value: '1758',
  },
  {
    short: 'LI',
    name: '列支敦士登',
    text: 'Liechtenstein',
    value: '423',
  },
  {
    short: 'LK',
    name: '斯里兰卡',
    text: 'SriLanka',
    value: '94',
  },
  {
    short: 'LR',
    name: '利比里亚',
    text: 'Liberia',
    value: '231',
  },
  {
    short: 'LS',
    name: '莱索托',
    text: 'Lesotho',
    value: '266',
  },
  {
    short: 'LT',
    name: '立陶宛',
    text: 'Lithuania',
    value: '370',
  },
  {
    short: 'LU',
    name: '卢森堡',
    text: 'Luxembourg',
    value: '352',
  },
  {
    short: 'LV',
    name: '拉脱维亚',
    text: 'Latvia',
    value: '371',
  },
  {
    short: 'LY',
    name: '利比亚',
    text: 'Libya',
    value: '218',
  },
  {
    short: 'MA',
    name: '摩洛哥',
    text: 'Morocco',
    value: '212',
  },
  {
    short: 'MC',
    name: '摩纳哥',
    text: 'Monaco',
    value: '377',
  },
  {
    short: 'MD',
    name: '摩尔多瓦',
    text: 'Moldova,Republicof',
    value: '373',
  },
  {
    short: 'MG',
    name: '马达加斯加',
    text: 'Madagascar',
    value: '261',
  },
  {
    short: 'ML',
    name: '马里',
    text: 'Mali',
    value: '223',
  },
  {
    short: 'MM',
    name: '缅甸',
    text: 'Burma',
    value: '95',
  },
  {
    short: 'MN',
    name: '蒙古',
    text: 'Mongolia',
    value: '976',
  },
  {
    short: 'MO',
    name: '澳门',
    text: 'Macao',
    value: '853',
  },
  {
    short: 'MS',
    name: '蒙特塞拉特岛',
    text: 'MontserratIs',
    value: '1664',
  },
  {
    short: 'MT',
    name: '马耳他',
    text: 'Malta',
    value: '356',
  },
  {
    short: '',
    name: '马里亚那群岛',
    text: 'MarianaIs',
    value: '1670',
  },
  {
    short: '',
    name: '马提尼克',
    text: 'Martinique',
    value: '596',
  },
  {
    short: 'MU',
    name: '毛里求斯',
    text: 'Mauritius',
    value: '230',
  },
  {
    short: 'MV',
    name: '马尔代夫',
    text: 'Maldives',
    value: '960',
  },
  {
    short: 'MW',
    name: '马拉维',
    text: 'Malawi',
    value: '265',
  },
  {
    short: 'MX',
    name: '墨西哥',
    text: 'Mexico',
    value: '52',
  },
  {
    short: 'MY',
    name: '马来西亚',
    text: 'Malaysia',
    value: '60',
  },
  {
    short: 'MZ',
    name: '莫桑比克',
    text: 'Mozambique',
    value: '258',
  },
  {
    short: 'NA',
    name: '纳米比亚',
    text: 'Namibia',
    value: '264',
  },
  {
    short: 'NE',
    name: '尼日尔',
    text: 'Niger',
    value: '977',
  },
  {
    short: 'NG',
    name: '尼日利亚',
    text: 'Nigeria',
    value: '234',
  },
  {
    short: 'NI',
    name: '尼加拉瓜',
    text: 'Nicaragua',
    value: '505',
  },
  {
    short: 'NL',
    name: '荷兰',
    text: 'Netherlands',
    value: '31',
  },
  {
    short: 'NO',
    name: '挪威',
    text: 'Norway',
    value: '47',
  },
  {
    short: 'NP',
    name: '尼泊尔',
    text: 'Nepal',
    value: '977',
  },
  {
    short: '',
    name: '荷属安的列斯',
    text: 'NetheriandsAntilles',
    value: '599',
  },
  {
    short: 'NR',
    name: '瑙鲁',
    text: 'Nauru',
    value: '674',
  },
  {
    short: 'NZ',
    name: '新西兰',
    text: 'NewZealand',
    value: '64',
  },
  {
    short: 'OM',
    name: '阿曼',
    text: 'Oman',
    value: '968',
  },
  {
    short: 'PA',
    name: '巴拿马',
    text: 'Panama',
    value: '507',
  },
  {
    short: 'PE',
    name: '秘鲁',
    text: 'Peru',
    value: '51',
  },
  {
    short: 'PF',
    name: '法属玻利尼西亚',
    text: 'FrenchPolynesia',
    value: '689',
  },
  {
    short: 'PG',
    name: '巴布亚新几内亚',
    text: 'PapuaNewCuinea',
    value: '675',
  },
  {
    short: 'PH',
    name: '菲律宾',
    text: 'Philippines',
    value: '63',
  },
  {
    short: 'PK',
    name: '巴基斯坦',
    text: 'Pakistan',
    value: '92',
  },
  {
    short: 'PL',
    name: '波兰',
    text: 'Poland',
    value: '48',
  },
  {
    short: 'PR',
    name: '波多黎各',
    text: 'PuertoRico',
    value: '1787',
  },
  {
    short: 'PT',
    name: '葡萄牙',
    text: 'Portugal',
    value: '351',
  },
  {
    short: 'PY',
    name: '巴拉圭',
    text: 'Paraguay',
    value: '595',
  },
  {
    short: 'QA',
    name: '卡塔尔',
    text: 'Qatar',
    value: '974',
  },
  {
    short: '',
    name: '留尼旺',
    text: 'Reunion',
    value: '262',
  },
  {
    short: 'RO',
    name: '罗马尼亚',
    text: 'Romania',
    value: '40',
  },
  {
    short: 'RU',
    name: '俄罗斯',
    text: 'Russia',
    value: '7',
  },
  {
    short: 'SA',
    name: '沙特阿拉伯',
    text: 'SaudiArabia',
    value: '966',
  },
  {
    short: 'SB',
    name: '所罗门群岛',
    text: 'SolomonIs',
    value: '677',
  },
  {
    short: 'SC',
    name: '塞舌尔',
    text: 'Seychelles',
    value: '248',
  },
  {
    short: 'SD',
    name: '苏丹',
    text: 'Sudan',
    value: '249',
  },
  {
    short: 'SE',
    name: '瑞典',
    text: 'Sweden',
    value: '46',
  },
  {
    short: 'SG',
    name: '新加坡',
    text: 'Singapore',
    value: '65',
  },
  {
    short: 'SI',
    name: '斯洛文尼亚',
    text: 'Slovenia',
    value: '386',
  },
  {
    short: 'SK',
    name: '斯洛伐克',
    text: 'Slovakia',
    value: '421',
  },
  {
    short: 'SL',
    name: '塞拉利昂',
    text: 'SierraLeone',
    value: '232',
  },
  {
    short: 'SM',
    name: '圣马力诺',
    text: 'SanMarino',
    value: '378',
  },
  {
    short: '',
    name: '东萨摩亚(美)',
    text: 'SamoaEastern',
    value: '684',
  },
  {
    short: '',
    name: '西萨摩亚',
    text: 'SanMarino',
    value: '685',
  },
  {
    short: 'SN',
    name: '塞内加尔',
    text: 'Senegal',
    value: '221',
  },
  {
    short: 'SO',
    name: '索马里',
    text: 'Somali',
    value: '252',
  },
  {
    short: 'SR',
    name: '苏里南',
    text: 'Suriname',
    value: '597',
  },
  {
    short: 'ST',
    name: '圣多美和普林西比',
    text: 'SaoTomeandPrincipe',
    value: '239',
  },
  {
    short: 'SV',
    name: '萨尔瓦多',
    text: 'EISalvador',
    value: '503',
  },
  {
    short: 'SY',
    name: '叙利亚',
    text: 'Syria',
    value: '963',
  },
  {
    short: 'SZ',
    name: '斯威士兰',
    text: 'Swaziland',
    value: '268',
  },
  {
    short: 'TD',
    name: '乍得',
    text: 'Chad',
    value: '235',
  },
  {
    short: 'TG',
    name: '多哥',
    text: 'Togo',
    value: '228',
  },
  {
    short: 'TH',
    name: '泰国',
    text: 'Thailand',
    value: '66',
  },
  {
    short: 'TJ',
    name: '塔吉克斯坦',
    text: 'Tajikstan',
    value: '992',
  },
  {
    short: 'TM',
    name: '土库曼斯坦',
    text: 'Turkmenistan',
    value: '993',
  },
  {
    short: 'TN',
    name: '突尼斯',
    text: 'Tunisia',
    value: '216',
  },
  {
    short: 'TO',
    name: '汤加',
    text: 'Tonga',
    value: '676',
  },
  {
    short: 'TR',
    name: '土耳其',
    text: 'Turkey',
    value: '90',
  },
  {
    short: 'TT',
    name: '特立尼达和多巴哥',
    text: 'TrinidadandTobago',
    value: '1809',
  },
  {
    short: 'TW',
    name: '台湾省',
    text: 'Taiwan',
    value: '886',
  },
  {
    short: 'TZ',
    name: '坦桑尼亚',
    text: 'Tanzania',
    value: '255',
  },
  {
    short: 'UA',
    name: '乌克兰',
    text: 'Ukraine',
    value: '380',
  },
  {
    short: 'UG',
    name: '乌干达',
    text: 'Uganda',
    value: '256',
  },
  {
    short: 'UY',
    name: '乌拉圭',
    text: 'Uruguay',
    value: '598',
  },
  {
    short: 'UZ',
    name: '乌兹别克斯坦',
    text: 'Uzbekistan',
    value: '233',
  },
  {
    short: 'VC',
    name: '圣文森特岛',
    text: 'SaintVincent',
    value: '1784',
  },
  {
    short: 'VE',
    name: '委内瑞拉',
    text: 'Venezuela',
    value: '58',
  },
  {
    short: 'VN',
    name: '越南',
    text: 'Vietnam',
    value: '84',
  },
  {
    short: 'YE',
    name: '也门',
    text: 'Yemen',
    value: '967',
  },
  {
    short: 'YU',
    name: '南斯拉夫',
    text: 'Yugoslavia',
    value: '381',
  },
  {
    short: 'ZA',
    name: '南非',
    text: 'SouthAfrica',
    value: '27',
  },
  {
    short: 'ZM',
    name: '赞比亚',
    text: 'Zambia',
    value: '260',
  },
  {
    short: 'ZR',
    name: '扎伊尔',
    text: 'Zaire',
    value: '243',
  },
  {
    short: 'ZW',
    name: '津巴布韦',
    text: 'Zimbabwe',
    value: '263',
  },
];
