import React, { useState } from 'react';
import Welcome from '@/components/welcome/index';
import { ComponentWrapper } from '@digit-fe/digit-component';
import DragCard from '@/components/dragCard/index';
import MobileCard from '@/components/mobileCard/index';
import { useRequest } from '@@/plugin-request/request';
import {
  apiChangeIndexData,
  apiGetIndexCard,
  apiGetIndexData,
} from '@/services/personalService/index';
import { TDragCardItem } from '@/types/common/index';
import { Layout } from 'react-grid-layout';
import { ECardType } from '@/enum/card';
import { useModel } from 'umi';

type TWelcomeCardProps = {
  type?: 'PERSONAL' | 'INTERNAL';
  setIsWelComeFinished?: Function;
};

const WelcomeCard: React.FC<TWelcomeCardProps> = ({
  type = 'PERSONAL',
  setIsWelComeFinished,
}) => {
  const { initialState } = useModel('@@initialState');
  const [cardData, setCardData] = useState<{
    layout?: Layout[];
    data?: TDragCardItem[];
  }>({
    layout: undefined,
    data: undefined,
  });
  const [indexData, setIndexData] = useState<{
    welcomeContent: string;
    layout: string;
    id: string;
  }>({
    id: '',
    welcomeContent: '',
    layout: '',
  });
  const createDefaultLayout = (data: TDragCardItem[]): Layout[] => {
    const layout: Layout[] = [];
    for (let i = 0; i < data.length; i++) {
      const card = data[i];
      layout.push({
        i: card.id,
        x: 0,
        y: 0,
        w: 1,
        h: card.type === ECardType.big ? 4 : 2,
        static: true,
        isResizable: false,
      });
    }
    return layout;
  };
  const { loading, run: getIndexCard } = useRequest(
    () => {
      return apiGetIndexCard(type);
    },
    {
      manual: true,
      onSuccess: (res: TDragCardItem[]) => {
        if (Array.isArray(res)) {
          // 如果已经有保存过layout
          if (indexData.layout) {
            const layoutArray = JSON.parse(indexData.layout);
            const newLayoutArray = layoutArray.map((item: any) => ({
              ...item,
              static: true,
            }));
            setCardData({
              layout: newLayoutArray,
              data: res,
            });
          } else {
            setCardData({
              layout: createDefaultLayout(res),
              data: res,
            });
          }
        }
      },
    },
  );
  // 获取欢迎语等数据
  useRequest(
    () => {
      return apiGetIndexData(type);
    },
    {
      onSuccess: (res) => {
        setIndexData(res);
        getIndexCard().then(() => {
          setIsWelComeFinished && setIsWelComeFinished(true);
        });
      },
    },
  );
  const { run: changeIndexData } = useRequest(
    (text, layout) => {
      return apiChangeIndexData(type, {
        id: indexData.id,
        welcomeContent: text,
        layout,
      });
    },
    {
      manual: true,
    },
  );
  return (
    <div>
      <Welcome
        type={type}
        welcomeContent={indexData.welcomeContent}
        onSave={(text) => {
          changeIndexData(text, indexData.layout);
          setIndexData((prevState) => {
            return { ...prevState, welcomeContent: text };
          });
        }}
      />
      {cardData.data && cardData.layout && (
        <>
          <ComponentWrapper>
            <DragCard
              type={type}
              onRefreshData={async () => {
                // 点取消时，从后端再次获取数据
                await getIndexCard();
              }}
              onSaveLayout={async (tempLayout) => {
                await changeIndexData(
                  indexData.welcomeContent,
                  JSON.stringify(tempLayout),
                );
                setIndexData((prevState) => {
                  return { ...prevState, layout: JSON.stringify(tempLayout) };
                });
              }}
              outLayout={cardData.layout}
              outData={cardData.data}
            />
          </ComponentWrapper>
          <ComponentWrapper mode="notPC">
            <MobileCard
              outData={cardData.data}
              type={type}
              layout={cardData.layout}
            />
          </ComponentWrapper>
        </>
      )}
    </div>
  );
};
export default WelcomeCard;
