import type { Reducer } from 'umi';
import type { TUserInfo } from '@/types/common/index';

export interface UserModelType {
  namespace: 'userInfo';
  state: TUserInfo;
  reducers: {
    save: Reducer<TUserInfo>;
  };
}

const userInfoModal: UserModelType = {
  namespace: 'userInfo',
  state: {
    uid: undefined,
    username: undefined,
    realname: undefined,
    nameEn: undefined,
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
export default userInfoModal;
