import React, { useEffect, useState } from 'react';
import styles from './detailedRecommendation.less';
import { IAdCard } from '@/types/personalService';
import { Divider, Space, Typography } from 'antd';
import classNames from 'classnames';
import { useCountDown } from 'ahooks';
import dayjs from 'dayjs';

type props = { details: IAdCard };

const { Paragraph } = Typography;

const LessonCard: React.FC<props> = ({ details }) => {
  const [countdown, formattedRes] = useCountDown({
    targetDate: dayjs(details?.end_time * 1000).format('YYYY-MM-DD HH:mm:ss'),
  });
  const { days, hours, minutes, seconds, milliseconds } = formattedRes;

  return (
    <>
      <div className={styles.details_wrapper}>
        <div
          className={classNames(
            styles.color_333,
            styles.f18,
            styles.lh26,
            styles.mgb10,
            styles.fw600,
          )}
        >
          {details.title}
        </div>
        <div
          className={classNames(
            styles.color_8697ad,
            styles.layout_vertical_center,
            styles.mgb10,
          )}
        >
          {/* <span>{adCardsList[selectedKey]}</span> */}
          <Divider type={'vertical'} />
          <span>{details.num_people}人学习</span>
        </div>
        <Paragraph
          ellipsis={{ rows: 3 }}
          style={{ color: '#5e7288', marginBottom: 0, flex: 1 }}
        >
          {details.description}
        </Paragraph>
        <Divider />
        <Space direction={'vertical'} size={10}>
          <div>
            <span className={styles.mgr10}>课程价格：</span>
            <span
              className={classNames(
                styles.color_price,
                styles.f16,
                styles.mgr12,
              )}
            >
              ￥{details.price}
            </span>
            <s className={classNames(styles.color_original_price, styles.f12)}>
              ￥{details.oprice}
            </s>
          </div>
          <div>
            <span className={styles.mgr10}>报名截止：</span>
            <span style={{ color: '#333333', fontWeight: 'bold' }}>{days}</span>
            &nbsp;
            <span style={{ color: '#5E7288' }}>天</span> &nbsp;
            <span style={{ color: '#333333', fontWeight: 'bold' }}>
              {hours}
            </span>
            &nbsp;
            <span style={{ color: '#5E7288' }}>时</span> &nbsp;
            <span style={{ color: '#333333', fontWeight: 'bold' }}>
              {minutes}
            </span>
            &nbsp;
            <span style={{ color: '#5E7288' }}>分</span> &nbsp;
            <span style={{ color: '#F77234', fontWeight: 'bold' }}>
              {seconds}
            </span>
            &nbsp;
            <span style={{ color: '#5E7288' }}>秒</span> &nbsp;
          </div>
          <div>
            <span className={styles.mgr10}>剩余名额：</span>
            <span>{details.remaining} 人</span>
          </div>
        </Space>
        <div
          className={classNames(
            styles.layout_items_end,
            styles.layout_center_fill,
          )}
        >
          <a href={details.course_url} target={'_blank'}>
            <div className={styles.details_button}>
              <span className={styles.big_text}>了解详情</span>
              <span className={styles.small_text}>
                （{details.start_day}开课）
              </span>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default LessonCard;
