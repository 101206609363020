// 卡片类型
export enum ECardType {
  small = 1,
  big,
}

// 卡片里具体项目的类型
export enum ECardItemType {
  file = 1,
  url,
}
