import { ReactNode } from 'react';
import { EEnterpriseStatus } from '@/types/enterpriseService/index';

export interface IBootAuthStep {
  bootImage: string;
  title: string;
  subTitle: string | ReactNode;
  stepStatus: EEnterpriseStatus;
  extraButton?: boolean;
}

export enum EBootAuthStatus {
  /**
   * 未完成
   */
  UNFINISHED,

  /**
   * 进行中
   */
  IN_PROGRESS,

  /**
   * 已完成
   */
  FINISHED,

  /**
   * 未通过
   */
  NOT_PASS,
}
