import React, { useEffect, useState } from 'react';
import { Modal, message, Form, Input, Select, Upload, Switch } from 'antd';
import SelectIcon from '@/components/selectIcon/selectIcon';
import { ProductItem } from './detailsTable';
import { useRequest } from 'umi';
import { addOrEditProductContent } from '@/utils/requests/requests_resource';

type Props = {
  urlModalOpen: boolean;
  setUrlModalOpen: Function;
  modeTitle?: string;
  okText?: string;
  messageTip?: string;
  details?: ProductItem;
  id: string;
  num: number;
  setRefresh: Function;
  refresh: number;
};

const AddOrEditUrlModal: React.FC<Props> = ({
  urlModalOpen,
  setUrlModalOpen,
  modeTitle = '添加链接',
  okText = '确认',
  messageTip = '添加成功',
  details = undefined,
  id,
  num,
  setRefresh,
  refresh,
}) => {
  const [form] = Form.useForm();
  const [icon, setIcon] = useState(
    'https://hwobs.fanruan.com/ui/home/icons/logo1.png',
  );
  const [contentId, setContentId] = useState<string | undefined>();
  const [title, setTitle] = useState<string | undefined>();
  const [url, setUrl] = useState<string | undefined>();
  const [index, setIndex] = useState<number | undefined>();

  useEffect(() => {
    form.setFieldsValue({
      url: details?.url,
      title: details?.title,
      icon: details?.icon
        ? details?.icon
        : 'https://hwobs.fanruan.com/ui/home/icons/logo1.png',
    });
    setContentId(details?.id);
    setTitle(details?.title);
    setUrl(details?.url);
    setIcon(
      details?.icon
        ? details?.icon
        : 'https://hwobs.fanruan.com/ui/home/icons/logo1.png',
    );
    setIndex(details?.index ? details?.index : num);
  }, [details]);

  const addOrEditProductContentRequest = useRequest(
    () => {
      return addOrEditProductContent(
        id,
        JSON.stringify({ id: contentId, title, url, icon, type: 2, index }),
      );
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  return (
    <Modal
      open={urlModalOpen}
      title={modeTitle}
      width={664}
      destroyOnClose={true}
      okText={okText}
      onOk={() => {
        form.submit();
      }}
      cancelText="取消"
      onCancel={() => {
        setUrlModalOpen(false);
      }}
    >
      <Form
        name="ulr"
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        initialValues={{}}
        onFinish={() => {
          addOrEditProductContentRequest.run().then(() => {
            setUrlModalOpen(false);
            setRefresh(refresh + 1);
            message.success(messageTip);
          });
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <Form.Item
          label="标题"
          name="title"
          rules={[{ required: true, message: '请输入标题' }]}
        >
          <Input
            placeholder="请输入..."
            showCount
            maxLength={20}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="URL"
          name="url"
          rules={[{ required: true, message: '请输入URL' }]}
        >
          <Input
            placeholder="请输入..."
            onChange={(event) => setUrl(event.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="icon"
          label="设置图标"
          rules={[{ required: true, message: '请选择图标' }]}
        >
          <img src={icon} />
          <div style={{ color: '#C5C7CE', marginBottom: '5px' }}>
            预制图标：
          </div>
          <SelectIcon iconSrc={icon} setIconSrc={setIcon} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOrEditUrlModal;
