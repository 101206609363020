import { requestGet } from '@/utils/request';

export function addClickNum(params: { id?: string }) {
  return requestGet(`/fcoin/api/myfr/information/click/${params.id}`, params);
}

export function getCertNum() {
  return requestGet(`/v1/cert/index/stats`, {});
}

export function getCorpNum() {
  return requestGet(`/company/stats`, {});
}

export function getAllAnswerNum() {
  return requestGet(`/jdy/api/ask/count/visible`, {});
}

export function getForumPostAnswerNum() {
  return requestGet('/jdy/api/forum/count/all', {});
}

export function getEduVideoNum() {
  return requestGet('/jdy/api/video/count', {});
}

export function getJobNum() {
  return requestGet('/jdy/api/job/count', {});
}

export function getBiAnswerNum() {
  return requestGet(`/jdy/api/forum/count?doc=2`, {});
}

export function getFrAnswerNum() {
  return requestGet(`/jdy/api/forum/count?doc=1`, {});
}

export function getJdyAnswerNum(doc?: number) {
  return requestGet(`/jdy/api/forum/count?`, {});
}
